@import "../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css"; 
@import '@angular/material/prebuilt-themes/indigo-pink.css';
/* To style ngx-quill */
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';

/* .ag-theme-alpine{
  font-family: Calibri, sans-serif !important;
  font-size: 0.65rem !important;
} */
/*For toaster success message*/
.success.mat-snack-bar-container{
  color: #4F8A10;
  background-color: #DFF2BF;
  margin-top: 70px;
}
/*For toaster error message*/
.error.mat-snack-bar-container{
  color: #D8000C;
  background-color: #FFD2D2;
  margin-top: 70px;
}

/* For calendar date color*/
.mat-calendar-body-cell-content.mat-calendar-body-today {
  background-color: white !important;
  border-color: #ed802d !important;
  border-width: 2px;
}

.mat-calendar-body-cell-content.mat-focus-indicator.mat-calendar-body-selected {
  background-color: #ed802d !important;
  color: white !important;
}

.mat-calendar-body-cell-content:hover.mat-focus-indicator:hover {
  background-color: #ed802d !important;
  color: white !important;
}

.mat-button-wrapper svg {
  color: #ed802d !important;
}

/* You can add global styles to this file, and also import other style files */
/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 6px; 
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

[tabindex="-1"]:focus {
  outline: none !important;
}

a,
area,
button,
[role="button"],
input,
label,
select,
summary,
textarea {
  -ms-touch-action: manipulation;
      touch-action: manipulation;
}

html {
  -webkit-text-size-adjust: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 18px;
}

body {
  background-color: #ffffff;
  /* font-family: "Siemens Sans", "SH-Bree-Text", "Open Sans", "Roboto", "Droid Sans", "Segoe UI", -apple-system, BlinkMacSystemFont, sans-serif; */
  font-family: Calibri, sans-serif !important;
  font-weight: 400;
  line-height: 1.66667rem;
  color: #1a1a1a;
  font-style: normal;
  padding: 0;
  margin: 0;
  position: relative;
  cursor: auto;
  height: 100%;
  font-size: 100%;
}

body:after {
  content: 'xs';
  display: none;
  /* comment this line for debugging purposes */
}

@media only screen and (min-width: 36em) {
  body:after {
    content: 'sm';
  }
}

@media only screen and (min-width: 48em) {
  body:after {
    content: 'md';
  }
}

@media only screen and (min-width: 62em) {
  body:after {
    content: 'lg';
  }
}

@media only screen and (min-width: 75em) {
  body:after {
    content: 'xl';
  }
}

/* abbr[title] {
  cursor: help;
} */

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

a {
  color: #666666;
  text-decoration: none;
  /*
  Provide basic, default focus styles.
  */
  /*
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
  */
  /*
  Define a strong focus indicator for keyboard focus.
  */
  /*
  Remove default focus styles for mouse users ONLY if
  :focus-visible is supported on this platform.
  */
  /*
  If :focus-visible is supported on this
  platform, provide enhanced focus styles for keyboard
  focus.
  */
  line-height: inherit;
  -webkit-user-drag: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-transition: color 0.16s linear;
  transition: color 0.16s linear;
}

a:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
          box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
  outline: none;
  color: inherit;
}

.js-focus-visible a:focus:not(.focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}

.js-focus-visible a:focus.focus-visible {
  -webkit-box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
          box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
  outline: none;
  color: inherit;
}

a:focus:not(:focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}

a:focus-visible {
  -webkit-box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
          box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
  outline: none;
  color: inherit;
}

a:hover, a:active {
  color: #1a1a1a;
  text-decoration: none;
  /*
    Override focus styles for mouse users ONLY if
    :focus-visible is supported on this platform.
    */
}

a:hover:not(:focus-visible), a:active:not(:focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
}

a[class*="icon"]::before {
  color: inherit;
  margin-right: 0.3em;
}

[class*="bg-color-gray-shade"] a,
.bg-color-black a {
  color: #999999;
  /*
  Provide basic, default focus styles.
  */
  /*
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
  */
  /*
  Define a strong focus indicator for keyboard focus.
  */
  /*
  Remove default focus styles for mouse users ONLY if
  :focus-visible is supported on this platform.
  */
  /*
  If :focus-visible is supported on this
  platform, provide enhanced focus styles for keyboard
  focus.
  */
}

[class*="bg-color-gray-shade"] a:focus,
.bg-color-black a:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
          box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
  outline: none;
  color: #999999;
}

.js-focus-visible [class*="bg-color-gray-shade"] a:focus:not(.focus-visible), .js-focus-visible
.bg-color-black a:focus:not(.focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}

.js-focus-visible [class*="bg-color-gray-shade"] a:focus.focus-visible, .js-focus-visible
.bg-color-black a:focus.focus-visible {
  -webkit-box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
          box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
  outline: none;
  color: #999999;
}

[class*="bg-color-gray-shade"] a:focus:not(:focus-visible),
.bg-color-black a:focus:not(:focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}

[class*="bg-color-gray-shade"] a:focus-visible,
.bg-color-black a:focus-visible {
  -webkit-box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
          box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
  outline: none;
  color: #999999;
}

[class*="bg-color-gray-shade"] a:hover, [class*="bg-color-gray-shade"] a:active,
.bg-color-black a:hover,
.bg-color-black a:active {
  color: #e6e6e6;
}

.wysiwyg a {
  border-bottom: 1px solid currentColor;
}

.wysiwyg a[class*="icon"] {
  margin-left: 1.3em;
  position: relative;
}

.wysiwyg a[class*="icon"]::before {
  position: absolute;
  left: -1.3em;
  line-height: 1;
  margin-top: 0.44em;
}

blockquote {
  margin: 0 0 1.66667rem;
  padding: 0;
  border: none;
}

blockquote,
blockquote p {
  font-family: Calibri, sans-serif !important;
  font-size: 1.33333rem;
  font-weight: 100;
  line-height: 1.94444rem;
  font-style: italic;
  text-align: center;
}

@media only screen and (min-width: 48em) {
  blockquote,
  blockquote p {
    text-align: left;
  }
}

cite {
  font-family: Calibri, sans-serif !important;
  font-size: 0.77778rem;
  line-height: 1.11111rem;
  color: #b3b3b3;
  display: block;
  font-style: normal;
  margin-top: 0.83333rem;
  text-align: center;
}

@media only screen and (min-width: 48em) {
  cite {
    text-align: left;
  }
}

button {
  padding: 0;
  margin: 0;
  border: 0;
  min-width: 0;
  border-radius: 0;
  background: transparent;
  color: inherit;
  line-height: inherit;
  -webkit-transition: none;
  transition: none;
  cursor: pointer;
}

button:focus {
  outline: none;
}

button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
}

figure {
  margin: 0 0 1rem;
  position: relative;
  /* margin: 0 0 1.666rem; */
  background-color: transparent;
  border: 0 solid #e6e6e6;
}

figcaption {
  font-size: 0.77778rem;
  line-height: 1.11111rem;
  color: #666666;
  padding: 0.27778rem 0;
  text-align: left;
  /* line-height: 1.3; */
}

figcaption > *:first-child {
  margin-top: 0;
}

figcaption > *:last-child {
  margin-bottom: 0;
}

@media only screen and (min-width: 36em) {
  figcaption {
    padding: 0.55556rem 0;
  }
}

h1, .h1 {
  font-family: Calibri, sans-serif !important;
  font-weight: 800;
  font-size: 1.66667rem;
  line-height: 1.94444rem;
  margin-top: 4.16667rem;
  margin-bottom: 2.22222rem;
}

@media only screen and (min-width: 48em) {
  h1, .h1 {
    font-size: 1.88889rem;
    line-height: 2.22222rem;
    margin-top: 3.88889rem;
    margin-bottom: 2.22222rem;
  }
}

@media only screen and (min-width: 75em) {
  h1, .h1 {
    font-size: 2.22222rem;
    line-height: 2.5rem;
    margin-top: 5.27778rem;
    margin-bottom: 2.22222rem;
  }
}

h2, .h2 {
  font-family: Calibri, sans-serif !important;
  font-weight: 800;
  font-size: 1.55556rem;
  line-height: 1.94444rem;
  margin-top: 3.11111rem;
  margin-bottom: 1.61111rem;
}

@media only screen and (min-width: 48em) {
  h2, .h2 {
    font-size: 1.72222rem;
    line-height: 1.94444rem;
    margin-top: 3.05556rem;
    margin-bottom: 1.66667rem;
  }
}

@media only screen and (min-width: 75em) {
  h2, .h2 {
    font-size: 1.94444rem;
    line-height: 2.22222rem;
    margin-top: 2.88889rem;
    margin-bottom: 1.55556rem;
  }
}

h3, .h3 {
  font-family: Calibri, sans-serif !important;
  font-weight: 800;
  font-size: 1.44444rem;
  line-height: 1.66667rem;
  margin-top: 3.27778rem;
  margin-bottom: 1.72222rem;
}

@media only screen and (min-width: 48em) {
  h3, .h3 {
    font-size: 1.55556rem;
    line-height: 1.66667rem;
    margin-top: 3.27778rem;
    margin-bottom: 1.72222rem;
  }
}

@media only screen and (min-width: 75em) {
  h3, .h3 {
    font-size: 1.72222rem;
    line-height: 1.94444rem;
    margin-top: 3.05556rem;
    margin-bottom: 1.66667rem;
  }
}

h4, .h4 {
  font-family: Calibri, sans-serif !important;
  font-weight: 800;
  font-size: 1.33333rem;
  line-height: 1.66667rem;
  margin-top: 2.33333rem;
  margin-bottom: 1rem;
}

@media only screen and (min-width: 48em) {
  h4, .h4 {
    font-size: 1.38889rem;
    line-height: 1.66667rem;
    margin-top: 2.33333rem;
    margin-bottom: 1rem;
  }
}

@media only screen and (min-width: 75em) {
  h4, .h4 {
    font-size: 1.5rem;
    line-height: 1.66667rem;
    margin-top: 2.33333rem;
    margin-bottom: 1rem;
  }
}

h5, .h5 {
  font-family: Calibri, sans-serif !important;
  font-weight: 800;
  font-size: 1.11111rem;
  line-height: 1.38889rem;
  margin-top: 1.66667rem;
  margin-bottom: 0.27778rem;
}

@media only screen and (min-width: 48em) {
  h5, .h5 {
    font-size: 1.11111rem;
    line-height: 1.38889rem;
    margin-top: 1.66667rem;
    margin-bottom: 0.27778rem;
  }
}

@media only screen and (min-width: 75em) {
  h5, .h5 {
    font-size: 1.16667rem;
    line-height: 1.38889rem;
    margin-top: 1.66667rem;
    margin-bottom: 0.27778rem;
  }
}

h6, .h6 {
  font-family: Calibri, sans-serif !important;
  font-weight: 800;
  font-size: 1rem;
  line-height: 1.38889rem;
  margin-top: 1.77778rem;
  margin-bottom: 0.16667rem;
}

@media only screen and (min-width: 48em) {
  h6, .h6 {
    font-size: 1rem;
    line-height: 1.38889rem;
    margin-top: 1.77778rem;
    margin-bottom: 0.27778rem;
  }
}

@media only screen and (min-width: 75em) {
  h6, .h6 {
    font-size: 1rem;
    line-height: 1.38889rem;
    margin-top: 1.77778rem;
    margin-bottom: 0.16667rem;
  }
}

hgroup {
  margin-bottom: 1.2rem;
}

hgroup > *:nth-child(-n+1) {
  margin-bottom: 0;
}

hgroup > *:nth-child(n+2) {
  margin-top: 0;
}

hr {
  border: solid #e6e6e6;
  border-width: 0.05556rem 0 0;
  clear: both;
  margin: 1.66667rem 0 1.61111rem;
  height: 0;
}

img {
  vertical-align: none !important;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
  display: inline-block;
  -webkit-user-drag: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  opacity: 1;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

img[data-src] {
  opacity: 0;
}

big,
sub,
em,
small,
strong {
  line-height: inherit;
}

b,
strong {
  font-weight: 800;
}

big {
  font-size: 1.22222rem;
}

sub {
  color: #666666;
}

sup {
  color: inherit;
}

em {
  font-style: italic;
}

small {
  font-size: 0.8889rem;
}

abbr,
acronym {
  text-transform: uppercase;
  font-size: 0.8889rem;
  color: #666666;
  cursor: help;
}

abbr {
  text-transform: none;
}

abbr[title] {
  border-bottom: 1px dotted #666666;
  cursor: help;
}

mark {
  background-color: #ffd200;
}

input {
  line-height: inherit;
}

input[type="radio"]:disabled,
input[type="checkbox"]:disabled {
  cursor: not-allowed;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

input[type="search"] {
  -webkit-appearance: none;
}

/* label {
  display: inline-block;
  margin-bottom: 0.5rem;
} */

ul, ol {
  margin-top: 0;
  margin-bottom: 1.66667rem;
  font-family: Calibri, sans-serif !important;
  list-style-position: outside;
}

li {
  margin-bottom: 0.83333rem;
  margin-top: 0.83333rem;
  padding: 0;
  position: relative;
}

dl {
  margin-top: 0;
  margin-bottom: 1.66667rem;
}

dt {
  font-weight: normal;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

p {
  font-family: Calibri, sans-serif !important;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  margin-top: 0;
  margin-bottom: 1.66667rem;
  text-rendering: optimizeLegibility;
}

p.lead {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.66667rem;
  margin-top: 1.66667rem;
  margin-bottom: 1.66667rem;
}

@media only screen and (min-width: 48em) {
  p.lead {
    font-size: 1.11111rem;
    font-weight: 600;
    line-height: 1.66667rem;
    margin-top: 1.61111rem;
    margin-bottom: 1.72222rem;
  }
}

p.small {
  font-size: 0.8889rem;
  line-height: 1.38889rem;
  margin-bottom: 1.38889rem;
}

p.xsmall {
  font-size: 0.77778rem;
  line-height: 1.11111rem;
  margin-bottom: 1.11111rem;
}

/* pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
} */

select {
  line-height: inherit;
}

table {
  border-collapse: collapse;
  background-color: transparent;
  margin-bottom: 1.75rem;
  border: 0 solid #ffffff;
  width: auto;
  min-width: 100%;
  table-layout: auto;
}

table tr {
  position: inherit;
  border-bottom: 1px solid #b3b3b3;
}

table tr th,
table tr td {
  padding: 0.55556rem 0.83333rem;
  text-align: left;
  vertical-align: top;
}

table thead tr th,
table thead tr td {
  font-family: Calibri, sans-serif !important;
  font-weight: 800;
  font-size: 1rem;
}

caption {
  color: #b3b3b3;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  text-align: left;
  caption-side: bottom;
}

textarea {
  resize: vertical;
  line-height: 1.1;
  width: 623px;
  height: 96px;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.dot-pulse {
  width: 400px;
  position: relative;
  height: 0.77778rem;
}

.dot-pulse__item {
  position: absolute;
  width: 0.77778rem;
  height: 0.77778rem;
  border-radius: 50%;
}

.dot-pulse__item:nth-child(1) {
  background-color: #ea7500;
  left: 0%;
}

.dot-pulse__item:nth-child(2) {
  background-color: #d68832;
  left: 6%;
}

.dot-pulse__item:nth-child(3) {
  background-color: #bf9552;
  left: 13%;
}

.dot-pulse__item:nth-child(4) {
  background-color: #9e9e6e;
  left: 22%;
}

.dot-pulse__item:nth-child(5) {
  background-color: #71a287;
  left: 36%;
}

.dot-pulse__item:nth-child(6) {
  background-color: #2ba39c;
  left: 57%;
}

.dot-pulse__item:nth-child(7) {
  background-color: #2ba3af;
  left: 95%;
}

[class*="aspect-ratio"] {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-bottom: 100%;
  background-color: #ededed;
}

[class*="aspect-ratio"] > img {
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@supports ((-o-object-fit: cover) or (object-fit: cover)) {
  .aspect-ratio--cover > img {
    -o-object-fit: cover;
       object-fit: cover;
    height: 100%;
    width: 100%;
  }
}

@supports not ((-o-object-fit: cover) or (object-fit: cover)) {
  .aspect-ratio--cover {
    background-size: cover;
    background-position: center center;
  }
  .aspect-ratio--cover > img {
    opacity: 0;
  }
}

@supports ((-o-object-fit: contain) or (object-fit: contain)) {
  .aspect-ratio--contain > img {
    -o-object-fit: contain;
       object-fit: contain;
    height: 100%;
    width: 100%;
  }
}

@supports not ((-o-object-fit: contain) or (object-fit: contain)) {
  .aspect-ratio--contain {
    background-size: contain;
    background-position: center center;
  }
  .aspect-ratio--contain > img {
    opacity: 0;
  }
}

.aspect-ratio-1x1 {
  padding-bottom: 100%;
}

.aspect-ratio-4x3 {
  padding-bottom: 75%;
}

.aspect-ratio-3x1 {
  padding-bottom: 33.33333%;
}

.aspect-ratio-3x4 {
  padding-bottom: 133.33333%;
}

.aspect-ratio-9x16 {
  padding-bottom: 177.77778%;
}

.aspect-ratio-16x9 {
  padding-bottom: 56.25%;
}

.aspect-ratio-21x9 {
  padding-bottom: 42.85714%;
}

@media only screen and (min-width: 36em) {
  .aspect-ratio-sm-1x1 {
    padding-bottom: 100%;
  }
  .aspect-ratio-sm-4x3 {
    padding-bottom: 75%;
  }
  .aspect-ratio-sm-3x1 {
    padding-bottom: 33.33333%;
  }
  .aspect-ratio-sm-3x4 {
    padding-bottom: 133.33333%;
  }
  .aspect-ratio-sm-9x16 {
    padding-bottom: 177.77778%;
  }
  .aspect-ratio-sm-16x9 {
    padding-bottom: 56.25%;
  }
  .aspect-ratio-sm-21x9 {
    padding-bottom: 42.85714%;
  }
}

@media only screen and (min-width: 48em) {
  .aspect-ratio-md-1x1 {
    padding-bottom: 100%;
  }
  .aspect-ratio-md-4x3 {
    padding-bottom: 75%;
  }
  .aspect-ratio-md-3x1 {
    padding-bottom: 33.33333%;
  }
  .aspect-ratio-md-3x4 {
    padding-bottom: 133.33333%;
  }
  .aspect-ratio-md-9x16 {
    padding-bottom: 177.77778%;
  }
  .aspect-ratio-md-16x9 {
    padding-bottom: 56.25%;
  }
  .aspect-ratio-md-21x9 {
    padding-bottom: 42.85714%;
  }
}

@media only screen and (min-width: 62em) {
  .aspect-ratio-lg-1x1 {
    padding-bottom: 100%;
  }
  .aspect-ratio-lg-4x3 {
    padding-bottom: 75%;
  }
  .aspect-ratio-lg-3x1 {
    padding-bottom: 33.33333%;
  }
  .aspect-ratio-lg-3x4 {
    padding-bottom: 133.33333%;
  }
  .aspect-ratio-lg-9x16 {
    padding-bottom: 177.77778%;
  }
  .aspect-ratio-lg-16x9 {
    padding-bottom: 56.25%;
  }
  .aspect-ratio-lg-21x9 {
    padding-bottom: 42.85714%;
  }
}

@media only screen and (min-width: 75em) {
  .aspect-ratio-xl-1x1 {
    padding-bottom: 100%;
  }
  .aspect-ratio-xl-4x3 {
    padding-bottom: 75%;
  }
  .aspect-ratio-xl-3x1 {
    padding-bottom: 33.33333%;
  }
  .aspect-ratio-xl-3x4 {
    padding-bottom: 133.33333%;
  }
  .aspect-ratio-xl-9x16 {
    padding-bottom: 177.77778%;
  }
  .aspect-ratio-xl-16x9 {
    padding-bottom: 56.25%;
  }
  .aspect-ratio-xl-21x9 {
    padding-bottom: 42.85714%;
  }
}

.bare-list {
  list-style: none;
  margin-left: 0;
  margin-bottom: 0;
  padding-left: 0;
}

.bare-list__item {
  margin-left: 0;
}

.block-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-left: -0.83333rem;
  margin-right: -0.83333rem;
}

.block-grid .block-grid__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-clip: padding-box;
  margin: 0;
  width: 100%;
  padding: 0 0.83333rem;
  margin-bottom: 1.66667rem;
}

.block-grid .block-grid__item > * {
  margin: 0;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
}

.block-grid-1 .block-grid__item {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%;
}

.block-grid-2 .block-grid__item {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%;
}

.block-grid-3 .block-grid__item {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 33.33333%;
          flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.block-grid-4 .block-grid__item {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%;
}

.block-grid-5 .block-grid__item {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 20%;
          flex: 0 0 20%;
  max-width: 20%;
}

.block-grid-6 .block-grid__item {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 16.66667%;
          flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.block-grid-7 .block-grid__item {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 14.28571%;
          flex: 0 0 14.28571%;
  max-width: 14.28571%;
}

.block-grid-8 .block-grid__item {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 12.5%;
          flex: 0 0 12.5%;
  max-width: 12.5%;
}

.block-grid-9 .block-grid__item {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 11.11111%;
          flex: 0 0 11.11111%;
  max-width: 11.11111%;
}

.block-grid-10 .block-grid__item {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 10%;
          flex: 0 0 10%;
  max-width: 10%;
}

.block-grid-11 .block-grid__item {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 9.09091%;
          flex: 0 0 9.09091%;
  max-width: 9.09091%;
}

.block-grid-12 .block-grid__item {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 8.33333%;
          flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

@media only screen and (min-width: 0em) {
  .block-grid-xs-1 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .block-grid-xs-2 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .block-grid-xs-3 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .block-grid-xs-4 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .block-grid-xs-5 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%;
  }
  .block-grid-xs-6 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .block-grid-xs-7 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 14.28571%;
            flex: 0 0 14.28571%;
    max-width: 14.28571%;
  }
  .block-grid-xs-8 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 12.5%;
            flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .block-grid-xs-9 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 11.11111%;
            flex: 0 0 11.11111%;
    max-width: 11.11111%;
  }
  .block-grid-xs-10 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 10%;
            flex: 0 0 10%;
    max-width: 10%;
  }
  .block-grid-xs-11 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 9.09091%;
            flex: 0 0 9.09091%;
    max-width: 9.09091%;
  }
  .block-grid-xs-12 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
}

@media only screen and (min-width: 36em) {
  .block-grid-sm-1 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .block-grid-sm-2 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .block-grid-sm-3 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .block-grid-sm-4 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .block-grid-sm-5 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%;
  }
  .block-grid-sm-6 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .block-grid-sm-7 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 14.28571%;
            flex: 0 0 14.28571%;
    max-width: 14.28571%;
  }
  .block-grid-sm-8 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 12.5%;
            flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .block-grid-sm-9 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 11.11111%;
            flex: 0 0 11.11111%;
    max-width: 11.11111%;
  }
  .block-grid-sm-10 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 10%;
            flex: 0 0 10%;
    max-width: 10%;
  }
  .block-grid-sm-11 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 9.09091%;
            flex: 0 0 9.09091%;
    max-width: 9.09091%;
  }
  .block-grid-sm-12 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
}

@media only screen and (min-width: 48em) {
  .block-grid-md-1 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .block-grid-md-2 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .block-grid-md-3 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .block-grid-md-4 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .block-grid-md-5 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%;
  }
  .block-grid-md-6 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .block-grid-md-7 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 14.28571%;
            flex: 0 0 14.28571%;
    max-width: 14.28571%;
  }
  .block-grid-md-8 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 12.5%;
            flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .block-grid-md-9 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 11.11111%;
            flex: 0 0 11.11111%;
    max-width: 11.11111%;
  }
  .block-grid-md-10 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 10%;
            flex: 0 0 10%;
    max-width: 10%;
  }
  .block-grid-md-11 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 9.09091%;
            flex: 0 0 9.09091%;
    max-width: 9.09091%;
  }
  .block-grid-md-12 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
}

@media only screen and (min-width: 62em) {
  .block-grid-lg-1 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .block-grid-lg-2 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .block-grid-lg-3 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .block-grid-lg-4 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .block-grid-lg-5 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%;
  }
  .block-grid-lg-6 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .block-grid-lg-7 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 14.28571%;
            flex: 0 0 14.28571%;
    max-width: 14.28571%;
  }
  .block-grid-lg-8 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 12.5%;
            flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .block-grid-lg-9 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 11.11111%;
            flex: 0 0 11.11111%;
    max-width: 11.11111%;
  }
  .block-grid-lg-10 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 10%;
            flex: 0 0 10%;
    max-width: 10%;
  }
  .block-grid-lg-11 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 9.09091%;
            flex: 0 0 9.09091%;
    max-width: 9.09091%;
  }
  .block-grid-lg-12 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
}

@media only screen and (min-width: 75em) {
  .block-grid-xl-1 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .block-grid-xl-2 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .block-grid-xl-3 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .block-grid-xl-4 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .block-grid-xl-5 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%;
  }
  .block-grid-xl-6 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .block-grid-xl-7 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 14.28571%;
            flex: 0 0 14.28571%;
    max-width: 14.28571%;
  }
  .block-grid-xl-8 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 12.5%;
            flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .block-grid-xl-9 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 11.11111%;
            flex: 0 0 11.11111%;
    max-width: 11.11111%;
  }
  .block-grid-xl-10 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 10%;
            flex: 0 0 10%;
    max-width: 10%;
  }
  .block-grid-xl-11 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 9.09091%;
            flex: 0 0 9.09091%;
    max-width: 9.09091%;
  }
  .block-grid-xl-12 .block-grid__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
}

.card {
  background-color: #ffffff;
  color: #1a1a1a;
  border-radius: 1px;
  overflow: hidden;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);
          box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-bottom: 1.66667rem;
  border: 1px solid #e6e6e6;
  border: none;
  -webkit-transition: all 0.16s linear;
  transition: all 0.16s linear;
}

.card__body {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  margin: 1.66667rem 0;
  padding: 0 1.66667rem;
}

.card__body > *:first-child {
  margin-top: 0;
}

.card__body > *:last-child {
  margin-bottom: 0;
}

.card__title {
  margin-top: 0;
  margin-bottom: 0.55556rem;
}

.card__subtitle {
  margin-top: 0;
  margin-bottom: 0.55556rem;
}

.card__footer {
  margin: 1.66667rem 0;
  margin-top: auto;
  padding: 0 1.66667rem;
}

.card--linked {
  /*
  Override focus styles for mouse users ONLY if
  :focus-visible is supported on this platform.
  */
}

.card--linked .card__image img {
  -webkit-transition: -webkit-filter 0.16s linear;
  transition: -webkit-filter 0.16s linear;
  transition: filter 0.16s linear;
  transition: filter 0.16s linear, -webkit-filter 0.16s linear;
}

.card--linked:hover {
  background-color: #f2f2f2;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, .2), 0 -1px 2px rgba(0, 0, 0, .1);
          box-shadow: 0 2px 4px rgba(0, 0, 0, .2), 0 -1px 2px rgba(0, 0, 0, .1);
  color: #1a1a1a;
  color: inherit;
}

.card--linked:hover .card__image img {
  -webkit-filter: opacity(1) contrast(1) brightness(0.95);
          filter: opacity(1) contrast(1) brightness(0.95);
}

.card--linked:hover:not(:focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.card--linked:focus {
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, .1), 0 0 0 3px rgba(236, 102, 2, .5);
          box-shadow: 0 0 0 1px rgba(0, 0, 0, .1), 0 0 0 3px rgba(236, 102, 2, .5);
  color: inherit;
}

.card--linked:active {
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);
          box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);
}

.card--rimless {
  border-width: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  background: transparent;
}

.card--rimless .card__body {
  margin: 0.83333rem 0;
  padding: 0;
}

.card--rimless:hover, .card--rimless:active {
  -webkit-box-shadow: none;
          box-shadow: none;
}

@media only screen and (min-width: 0em) {
  .card--horizontal {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0;
    width: 100%;
  }
  .card--horizontal .card__image {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 0px;
            flex: 1 0 0;
  }
  .card--horizontal.card--image-right .card__image {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .card--horizontal .card__body {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 0px;
            flex: 1 1 0;
  }
  .card--horizontal.card--small .card__body {
    margin: 0.55556rem 0;
    padding: 0 1.11111rem;
  }
  .card--horizontal.card--2x1 .card__image {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
  }
  .card--horizontal.card--2x1 .card__body {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 33.33333%;
            flex: 1 1 33.33333%;
  }
  .card--horizontal.card--1x2 .card__image {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
  }
  .card--horizontal.card--1x2 .card__body {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 66.66667%;
            flex: 1 1 66.66667%;
  }
  .card--horizontal.card--1x3 .card__image {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
  }
  .card--horizontal.card--1x3 .card__body {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 75%;
            flex: 1 1 75%;
  }
}

@media only screen and (min-width: 36em) {
  .card--horizontal-sm-up {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0;
    width: 100%;
  }
  .card--horizontal-sm-up .card__image {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 0px;
            flex: 1 0 0;
  }
  .card--horizontal-sm-up.card--image-right .card__image {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .card--horizontal-sm-up .card__body {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 0px;
            flex: 1 1 0;
  }
  .card--horizontal-sm-up.card--small .card__body {
    margin: 0.55556rem 0;
    padding: 0 1.11111rem;
  }
  .card--horizontal-sm-up.card--2x1 .card__image {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
  }
  .card--horizontal-sm-up.card--2x1 .card__body {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 33.33333%;
            flex: 1 1 33.33333%;
  }
  .card--horizontal-sm-up.card--1x2 .card__image {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
  }
  .card--horizontal-sm-up.card--1x2 .card__body {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 66.66667%;
            flex: 1 1 66.66667%;
  }
  .card--horizontal-sm-up.card--1x3 .card__image {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
  }
  .card--horizontal-sm-up.card--1x3 .card__body {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 75%;
            flex: 1 1 75%;
  }
}

@media only screen and (min-width: 48em) {
  .card--horizontal-md-up {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0;
    width: 100%;
  }
  .card--horizontal-md-up .card__image {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 0px;
            flex: 1 0 0;
  }
  .card--horizontal-md-up.card--image-right .card__image {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .card--horizontal-md-up .card__body {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 0px;
            flex: 1 1 0;
  }
  .card--horizontal-md-up.card--small .card__body {
    margin: 0.55556rem 0;
    padding: 0 1.11111rem;
  }
  .card--horizontal-md-up.card--2x1 .card__image {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
  }
  .card--horizontal-md-up.card--2x1 .card__body {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 33.33333%;
            flex: 1 1 33.33333%;
  }
  .card--horizontal-md-up.card--1x2 .card__image {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
  }
  .card--horizontal-md-up.card--1x2 .card__body {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 66.66667%;
            flex: 1 1 66.66667%;
  }
  .card--horizontal-md-up.card--1x3 .card__image {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
  }
  .card--horizontal-md-up.card--1x3 .card__body {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 75%;
            flex: 1 1 75%;
  }
}

@media only screen and (min-width: 62em) {
  .card--horizontal-lg-up {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0;
    width: 100%;
  }
  .card--horizontal-lg-up .card__image {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 0px;
            flex: 1 0 0;
  }
  .card--horizontal-lg-up.card--image-right .card__image {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .card--horizontal-lg-up .card__body {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 0px;
            flex: 1 1 0;
  }
  .card--horizontal-lg-up.card--small .card__body {
    margin: 0.55556rem 0;
    padding: 0 1.11111rem;
  }
  .card--horizontal-lg-up.card--2x1 .card__image {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
  }
  .card--horizontal-lg-up.card--2x1 .card__body {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 33.33333%;
            flex: 1 1 33.33333%;
  }
  .card--horizontal-lg-up.card--1x2 .card__image {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
  }
  .card--horizontal-lg-up.card--1x2 .card__body {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 66.66667%;
            flex: 1 1 66.66667%;
  }
  .card--horizontal-lg-up.card--1x3 .card__image {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
  }
  .card--horizontal-lg-up.card--1x3 .card__body {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 75%;
            flex: 1 1 75%;
  }
}

@media only screen and (min-width: 75em) {
  .card--horizontal-xl-up {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0;
    width: 100%;
  }
  .card--horizontal-xl-up .card__image {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 0px;
            flex: 1 0 0;
  }
  .card--horizontal-xl-up.card--image-right .card__image {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .card--horizontal-xl-up .card__body {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 0px;
            flex: 1 1 0;
  }
  .card--horizontal-xl-up.card--small .card__body {
    margin: 0.55556rem 0;
    padding: 0 1.11111rem;
  }
  .card--horizontal-xl-up.card--2x1 .card__image {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
  }
  .card--horizontal-xl-up.card--2x1 .card__body {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 33.33333%;
            flex: 1 1 33.33333%;
  }
  .card--horizontal-xl-up.card--1x2 .card__image {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
  }
  .card--horizontal-xl-up.card--1x2 .card__body {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 66.66667%;
            flex: 1 1 66.66667%;
  }
  .card--horizontal-xl-up.card--1x3 .card__image {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
  }
  .card--horizontal-xl-up.card--1x3 .card__body {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 75%;
            flex: 1 1 75%;
  }
}

.collection {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  padding: 0;
  margin: 0;
  list-style: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
}

.collection__item {
  border-bottom: 1px solid #e6e6e6;
  color: inherit;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 0 0 -1px;
  padding: 1.66rem 0;
  text-decoration: none;
}

.collection__item > *:first-child {
  margin-top: 0;
}

.collection__item > *:last-child {
  margin-bottom: 0;
}

.collection__item[href] {
  /*
  Override focus styles for mouse users ONLY if
  :focus-visible is supported on this platform.
  */
  padding-left: 1.11111rem;
  padding-right: 1.11111rem;
}

.collection__item[href]:hover {
  background-color: #f2f2f2;
}

.collection__item[href]:hover:not(:focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.collection__item:last-child {
  border-bottom: none;
}

.collection__content {
  -webkit-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
}

.collection__content > *:first-child {
  margin-top: 0;
}

.collection__content > *:last-child {
  margin-bottom: 0;
}

.collection__additional-content {
  -webkit-box-flex: 0;
      -ms-flex: none;
          flex: none;
  padding-left: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.collection--full-bordered {
  border: 1px solid #e6e6e6;
}

.collection--full-bordered .collection__item {
  padding: 1.66rem 1rem;
}

.collection--compact .collection__item {
  padding: 0.83rem 0;
}

.collection--compact.collection--full-bordered .collection__item {
  padding: 0.83rem 0.5rem;
}

.container {
  width: 100%;
  padding-right: 0.83333rem;
  padding-left: 0.83333rem;
  margin-right: auto;
  margin-left: auto;
}

@media only screen and (min-width: 36em) {
  .container {
    max-width: 30em;
  }
}

@media only screen and (min-width: 48em) {
  .container {
    max-width: 40em;
  }
}

@media only screen and (min-width: 62em) {
  .container {
    max-width: 53.333em;
  }
}

@media only screen and (min-width: 75em) {
  .container {
    max-width: 100vw;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 0.83333rem;
  padding-left: 0.83333rem;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-right: -0.83333rem;
  margin-left: -0.83333rem;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 0.83333rem;
  padding-left: 0.83333rem;
}

.col {
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 8.33333%;
          flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 16.66667%;
          flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 33.33333%;
          flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 41.66667%;
          flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 58.33333%;
          flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 66.66667%;
          flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 75%;
          flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 83.33333%;
          flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 91.66667%;
          flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
          order: -1;
}

.order-last {
  -webkit-box-ordinal-group: 14;
      -ms-flex-order: 13;
          order: 13;
}

.order-0 {
  -webkit-box-ordinal-group: 1;
      -ms-flex-order: 0;
          order: 0;
}

.order-1 {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
}

.order-2 {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
}

.order-3 {
  -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
          order: 3;
}

.order-4 {
  -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
          order: 4;
}

.order-5 {
  -webkit-box-ordinal-group: 6;
      -ms-flex-order: 5;
          order: 5;
}

.order-6 {
  -webkit-box-ordinal-group: 7;
      -ms-flex-order: 6;
          order: 6;
}

.order-7 {
  -webkit-box-ordinal-group: 8;
      -ms-flex-order: 7;
          order: 7;
}

.order-8 {
  -webkit-box-ordinal-group: 9;
      -ms-flex-order: 8;
          order: 8;
}

.order-9 {
  -webkit-box-ordinal-group: 10;
      -ms-flex-order: 9;
          order: 9;
}

.order-10 {
  -webkit-box-ordinal-group: 11;
      -ms-flex-order: 10;
          order: 10;
}

.order-11 {
  -webkit-box-ordinal-group: 12;
      -ms-flex-order: 11;
          order: 11;
}

.order-12 {
  -webkit-box-ordinal-group: 13;
      -ms-flex-order: 12;
          order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 36em) {
  .col-sm {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
  }
  .order-sm-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13;
  }
  .order-sm-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0;
  }
  .order-sm-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  .order-sm-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .order-sm-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
  .order-sm-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
  }
  .order-sm-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5;
  }
  .order-sm-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6;
  }
  .order-sm-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7;
  }
  .order-sm-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8;
  }
  .order-sm-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9;
  }
  .order-sm-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10;
  }
  .order-sm-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11;
  }
  .order-sm-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 48em) {
  .col-md {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
  }
  .order-md-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13;
  }
  .order-md-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0;
  }
  .order-md-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  .order-md-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .order-md-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
  .order-md-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
  }
  .order-md-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5;
  }
  .order-md-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6;
  }
  .order-md-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7;
  }
  .order-md-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8;
  }
  .order-md-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9;
  }
  .order-md-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10;
  }
  .order-md-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11;
  }
  .order-md-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 62em) {
  .col-lg {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
  }
  .order-lg-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13;
  }
  .order-lg-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0;
  }
  .order-lg-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  .order-lg-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .order-lg-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
  .order-lg-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
  }
  .order-lg-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5;
  }
  .order-lg-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6;
  }
  .order-lg-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7;
  }
  .order-lg-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8;
  }
  .order-lg-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9;
  }
  .order-lg-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10;
  }
  .order-lg-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11;
  }
  .order-lg-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 75em) {
  .col-xl {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
  }
  .order-xl-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13;
  }
  .order-xl-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0;
  }
  .order-xl-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  .order-xl-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .order-xl-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
  .order-xl-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
  }
  .order-xl-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5;
  }
  .order-xl-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6;
  }
  .order-xl-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7;
  }
  .order-xl-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8;
  }
  .order-xl-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9;
  }
  .order-xl-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10;
  }
  .order-xl-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11;
  }
  .order-xl-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

/* [class*=icon]::before {
  font-family: "SH Icon";
  font-weight: normal !important;
  font-style: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
   speak: none; 
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  vertical-align: baseline;
  font-size: 90%;
} */

.icon--circle::before {
  display: inline-block;
  padding: 0;
  width: 1.85185em;
  height: 1.85185em;
  line-height: 1.85185 !important;
  text-align: center;
  border-radius: 50%;
  border: 1px solid #b3b3b3;
}

.media-object {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  width: 100%;
}

.media-object__image {
  margin-right: 1.11111rem;
  -webkit-box-flex: 0;
      -ms-flex: 0 9 auto;
          flex: 0 9 auto;
}

.media-object__body {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 6em;
          flex: 1 1 6em;
  margin-top: -0.27778rem;
}

.media-object__body > *:first-child {
  margin-top: 0;
}

.media-object__body > *:last-child {
  margin-bottom: 0;
}

.media-object--right .media-object__image {
  margin-right: 0;
  margin-left: 1.11111rem;
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
}

@media only screen and (max-width: 35.94444em) {
  .media-object--stacked-xs {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .media-object--stacked-xs .media-object__image {
    margin-right: auto;
    margin-bottom: 0.8125rem;
    margin-left: auto;
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0;
  }
  .media-object--stacked-xs .media-object__body {
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
  }
}

@media only screen and (max-width: 47.94444em) {
  .media-object--stacked-sm {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .media-object--stacked-sm .media-object__image {
    margin-right: auto;
    margin-bottom: 0.8125rem;
    margin-left: auto;
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0;
  }
  .media-object--stacked-sm .media-object__body {
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
  }
}

@media only screen and (max-width: 61.94444em) {
  .media-object--stacked-md {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .media-object--stacked-md .media-object__image {
    margin-right: auto;
    margin-bottom: 0.8125rem;
    margin-left: auto;
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0;
  }
  .media-object--stacked-md .media-object__body {
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
  }
}

.media-object--small-spacing .media-object__image {
  margin-right: 0.55556rem;
}

.media-object--small-spacing.media-object--right .media-object__image {
  margin-left: 0.55556rem;
}

.multi-column {
  -webkit-column-gap: 1.66667rem;
     -moz-column-gap: 1.66667rem;
          column-gap: 1.66667rem;
  -webkit-column-count: 1;
     -moz-column-count: 1;
          column-count: 1;
  margin: 0;
}

.multi-column__item {
  display: inline-block;
  width: 100%;
}

.multi-column__block {
  display: table;
  margin: 0;
  -webkit-column-break-inside: avoid;
     -moz-column-break-inside: avoid;
          break-inside: avoid;
}

.multi-column-1 {
  -webkit-column-count: 1;
     -moz-column-count: 1;
          column-count: 1;
}

.multi-column-2 {
  -webkit-column-count: 2;
     -moz-column-count: 2;
          column-count: 2;
}

.multi-column-3 {
  -webkit-column-count: 3;
     -moz-column-count: 3;
          column-count: 3;
}

.multi-column-4 {
  -webkit-column-count: 4;
     -moz-column-count: 4;
          column-count: 4;
}

.multi-column-5 {
  -webkit-column-count: 5;
     -moz-column-count: 5;
          column-count: 5;
}

.multi-column-6 {
  -webkit-column-count: 6;
     -moz-column-count: 6;
          column-count: 6;
}

.multi-column-7 {
  -webkit-column-count: 7;
     -moz-column-count: 7;
          column-count: 7;
}

.multi-column-8 {
  -webkit-column-count: 8;
     -moz-column-count: 8;
          column-count: 8;
}

.multi-column-9 {
  -webkit-column-count: 9;
     -moz-column-count: 9;
          column-count: 9;
}

.multi-column-10 {
  -webkit-column-count: 10;
     -moz-column-count: 10;
          column-count: 10;
}

.multi-column-11 {
  -webkit-column-count: 11;
     -moz-column-count: 11;
          column-count: 11;
}

.multi-column-12 {
  -webkit-column-count: 12;
     -moz-column-count: 12;
          column-count: 12;
}

@media only screen and (min-width: 0em) {
  .multi-column-xs-1 {
    -webkit-column-count: 1;
       -moz-column-count: 1;
            column-count: 1;
  }
  .multi-column-xs-2 {
    -webkit-column-count: 2;
       -moz-column-count: 2;
            column-count: 2;
  }
  .multi-column-xs-3 {
    -webkit-column-count: 3;
       -moz-column-count: 3;
            column-count: 3;
  }
  .multi-column-xs-4 {
    -webkit-column-count: 4;
       -moz-column-count: 4;
            column-count: 4;
  }
  .multi-column-xs-5 {
    -webkit-column-count: 5;
       -moz-column-count: 5;
            column-count: 5;
  }
  .multi-column-xs-6 {
    -webkit-column-count: 6;
       -moz-column-count: 6;
            column-count: 6;
  }
  .multi-column-xs-7 {
    -webkit-column-count: 7;
       -moz-column-count: 7;
            column-count: 7;
  }
  .multi-column-xs-8 {
    -webkit-column-count: 8;
       -moz-column-count: 8;
            column-count: 8;
  }
  .multi-column-xs-9 {
    -webkit-column-count: 9;
       -moz-column-count: 9;
            column-count: 9;
  }
  .multi-column-xs-10 {
    -webkit-column-count: 10;
       -moz-column-count: 10;
            column-count: 10;
  }
  .multi-column-xs-11 {
    -webkit-column-count: 11;
       -moz-column-count: 11;
            column-count: 11;
  }
  .multi-column-xs-12 {
    -webkit-column-count: 12;
       -moz-column-count: 12;
            column-count: 12;
  }
}

@media only screen and (min-width: 36em) {
  .multi-column-sm-1 {
    -webkit-column-count: 1;
       -moz-column-count: 1;
            column-count: 1;
  }
  .multi-column-sm-2 {
    -webkit-column-count: 2;
       -moz-column-count: 2;
            column-count: 2;
  }
  .multi-column-sm-3 {
    -webkit-column-count: 3;
       -moz-column-count: 3;
            column-count: 3;
  }
  .multi-column-sm-4 {
    -webkit-column-count: 4;
       -moz-column-count: 4;
            column-count: 4;
  }
  .multi-column-sm-5 {
    -webkit-column-count: 5;
       -moz-column-count: 5;
            column-count: 5;
  }
  .multi-column-sm-6 {
    -webkit-column-count: 6;
       -moz-column-count: 6;
            column-count: 6;
  }
  .multi-column-sm-7 {
    -webkit-column-count: 7;
       -moz-column-count: 7;
            column-count: 7;
  }
  .multi-column-sm-8 {
    -webkit-column-count: 8;
       -moz-column-count: 8;
            column-count: 8;
  }
  .multi-column-sm-9 {
    -webkit-column-count: 9;
       -moz-column-count: 9;
            column-count: 9;
  }
  .multi-column-sm-10 {
    -webkit-column-count: 10;
       -moz-column-count: 10;
            column-count: 10;
  }
  .multi-column-sm-11 {
    -webkit-column-count: 11;
       -moz-column-count: 11;
            column-count: 11;
  }
  .multi-column-sm-12 {
    -webkit-column-count: 12;
       -moz-column-count: 12;
            column-count: 12;
  }
}

@media only screen and (min-width: 48em) {
  .multi-column-md-1 {
    -webkit-column-count: 1;
       -moz-column-count: 1;
            column-count: 1;
  }
  .multi-column-md-2 {
    -webkit-column-count: 2;
       -moz-column-count: 2;
            column-count: 2;
  }
  .multi-column-md-3 {
    -webkit-column-count: 3;
       -moz-column-count: 3;
            column-count: 3;
  }
  .multi-column-md-4 {
    -webkit-column-count: 4;
       -moz-column-count: 4;
            column-count: 4;
  }
  .multi-column-md-5 {
    -webkit-column-count: 5;
       -moz-column-count: 5;
            column-count: 5;
  }
  .multi-column-md-6 {
    -webkit-column-count: 6;
       -moz-column-count: 6;
            column-count: 6;
  }
  .multi-column-md-7 {
    -webkit-column-count: 7;
       -moz-column-count: 7;
            column-count: 7;
  }
  .multi-column-md-8 {
    -webkit-column-count: 8;
       -moz-column-count: 8;
            column-count: 8;
  }
  .multi-column-md-9 {
    -webkit-column-count: 9;
       -moz-column-count: 9;
            column-count: 9;
  }
  .multi-column-md-10 {
    -webkit-column-count: 10;
       -moz-column-count: 10;
            column-count: 10;
  }
  .multi-column-md-11 {
    -webkit-column-count: 11;
       -moz-column-count: 11;
            column-count: 11;
  }
  .multi-column-md-12 {
    -webkit-column-count: 12;
       -moz-column-count: 12;
            column-count: 12;
  }
}

@media only screen and (min-width: 62em) {
  .multi-column-lg-1 {
    -webkit-column-count: 1;
       -moz-column-count: 1;
            column-count: 1;
  }
  .multi-column-lg-2 {
    -webkit-column-count: 2;
       -moz-column-count: 2;
            column-count: 2;
  }
  .multi-column-lg-3 {
    -webkit-column-count: 3;
       -moz-column-count: 3;
            column-count: 3;
  }
  .multi-column-lg-4 {
    -webkit-column-count: 4;
       -moz-column-count: 4;
            column-count: 4;
  }
  .multi-column-lg-5 {
    -webkit-column-count: 5;
       -moz-column-count: 5;
            column-count: 5;
  }
  .multi-column-lg-6 {
    -webkit-column-count: 6;
       -moz-column-count: 6;
            column-count: 6;
  }
  .multi-column-lg-7 {
    -webkit-column-count: 7;
       -moz-column-count: 7;
            column-count: 7;
  }
  .multi-column-lg-8 {
    -webkit-column-count: 8;
       -moz-column-count: 8;
            column-count: 8;
  }
  .multi-column-lg-9 {
    -webkit-column-count: 9;
       -moz-column-count: 9;
            column-count: 9;
  }
  .multi-column-lg-10 {
    -webkit-column-count: 10;
       -moz-column-count: 10;
            column-count: 10;
  }
  .multi-column-lg-11 {
    -webkit-column-count: 11;
       -moz-column-count: 11;
            column-count: 11;
  }
  .multi-column-lg-12 {
    -webkit-column-count: 12;
       -moz-column-count: 12;
            column-count: 12;
  }
}

@media only screen and (min-width: 75em) {
  .multi-column-xl-1 {
    -webkit-column-count: 1;
       -moz-column-count: 1;
            column-count: 1;
  }
  .multi-column-xl-2 {
    -webkit-column-count: 2;
       -moz-column-count: 2;
            column-count: 2;
  }
  .multi-column-xl-3 {
    -webkit-column-count: 3;
       -moz-column-count: 3;
            column-count: 3;
  }
  .multi-column-xl-4 {
    -webkit-column-count: 4;
       -moz-column-count: 4;
            column-count: 4;
  }
  .multi-column-xl-5 {
    -webkit-column-count: 5;
       -moz-column-count: 5;
            column-count: 5;
  }
  .multi-column-xl-6 {
    -webkit-column-count: 6;
       -moz-column-count: 6;
            column-count: 6;
  }
  .multi-column-xl-7 {
    -webkit-column-count: 7;
       -moz-column-count: 7;
            column-count: 7;
  }
  .multi-column-xl-8 {
    -webkit-column-count: 8;
       -moz-column-count: 8;
            column-count: 8;
  }
  .multi-column-xl-9 {
    -webkit-column-count: 9;
       -moz-column-count: 9;
            column-count: 9;
  }
  .multi-column-xl-10 {
    -webkit-column-count: 10;
       -moz-column-count: 10;
            column-count: 10;
  }
  .multi-column-xl-11 {
    -webkit-column-count: 11;
       -moz-column-count: 11;
            column-count: 11;
  }
  .multi-column-xl-12 {
    -webkit-column-count: 12;
       -moz-column-count: 12;
            column-count: 12;
  }
}

.accordion {
  border-top: 1px solid #b3b3b3;
  color: #1a1a1a;
}

.accordion__title {
  font-family: Calibri, sans-serif !important;
  font-weight: 800;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: none;
  border-bottom: 1px solid #b3b3b3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 1rem;
  line-height: 1.38889rem;
  margin: 0;
  padding: 0.83333rem 1.11111rem;
  position: relative;
  text-align: left;
  -webkit-transition: background-color 0.16s linear;
  transition: background-color 0.16s linear;
  width: 100%;
  /*
  Provide basic, default focus styles.
  */
  /*
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
  */
  /*
  Define a strong focus indicator for keyboard focus.
  */
  /*
  Remove default focus styles for mouse users ONLY if
  :focus-visible is supported on this platform.
  */
  /*
  If :focus-visible is supported on this
  platform, provide enhanced focus styles for keyboard
  focus.
  */
  /*
  Override focus styles for mouse users ONLY if
  :focus-visible is supported on this platform.
  */
}

.accordion__title::before {
  color: #666666;
  position: absolute;
  right: 1.11111rem;
}

.accordion__title[aria-expanded="true"] {
  border-bottom: none;
}

.accordion__title[aria-expanded="true"]::before {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}

.accordion__title:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
          box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
  outline: none;
}

.js-focus-visible .accordion__title:focus:not(.focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}

.js-focus-visible .accordion__title:focus.focus-visible {
  -webkit-box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
          box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
  outline: none;
}

.accordion__title:focus:not(:focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}

.accordion__title:focus-visible {
  -webkit-box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
          box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
  outline: none;
}

.accordion__title:hover {
  background-color: #f2f2f2;
}

.accordion__title:hover:not(:focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.accordion__title:active {
  background-color: #f2f2f2;
  /*
    Remove default focus styles for mouse users ONLY if
    :focus-visible is supported on this platform.
    */
  border-bottom-color: #1a1a1a;
}

.accordion__title:active:not(:focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.accordion__panel {
  display: none;
  padding: 0 1.11111rem;
  margin: 0;
  border-bottom: 1px solid #b3b3b3;
  overflow: hidden;
}

.accordion__panel[aria-hidden="false"] {
  display: block;
}

.alert {
  background-color: #ffffff;
  -webkit-box-shadow: 0 4px 8px rgba(0, 0, 0, .1), 0 -1px 4px rgba(0, 0, 0, .05);
          box-shadow: 0 4px 8px rgba(0, 0, 0, .1), 0 -1px 4px rgba(0, 0, 0, .05);
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  /* -webkit-transition: all 0.16s ease-out; */
  transition: all 0.16s ease-out;
  max-height: 100vh;
  overflow: visible;
  min-width: 1px;
}

.alert__container {
  padding: 1.11111rem;
}

.alert__close {
  font-size: 1.22222rem;
  float: right;
  margin-bottom: 1.11111rem;
  margin-left: 1.11111rem;
  min-width: auto;
  padding: 0;
  line-height: 1;
}

.alert__close i::before {
  color: #666666;
  margin: 0;
}

.alert__close:hover i::before {
  color: #1a1a1a;
}

.alert__close ::before {
  color: #4d4d4d;
}

.alert__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: calc(100% - 2.22222rem);
}

.alert__body > *:first-child {
  margin-top: 0;
}

.alert__body > *:last-child {
  margin-bottom: 0;
}

.alert__footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  margin-top: 1.11111rem;
}

.alert__footer > * {
  margin: 0 0 0 0.55556rem !important;
}

.alert__title {
  margin-top: 0;
  margin-bottom: 1.11111rem;
}

.alert__indicator {
  display: none;
  margin-right: 0.55556rem;
  margin-top: -.15rem;
}

.alert--success .alert__indicator {
  display: inline-block;
}

.alert--success .alert__indicator::after {
  font-family: "SH Icon",sans-serif;
  font-weight: normal !important;
  font-style: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  vertical-align: baseline;
  content: "";
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #009a38;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 1em;
  width: 1.66667rem;
  height: 1.66667rem;
  /* line-height: 1.7; */
  border-radius: 50%;
  color: #ffffff;
}

.alert--warning .alert__indicator {
  display: inline-block;
}

.alert--warning .alert__indicator::after {
  font-family: "SH Icon",sans-serif;
  font-weight: normal !important;
  font-style: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  vertical-align: baseline;
  content: "";
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #ffd200;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 1em;
  width: 1.66667rem;
  height: 1.66667rem;
  /* line-height: 1.7; */
  border-radius: 50%;
  color: #000000;
}

.alert--error .alert__indicator {
  display: inline-block;
}

.alert--error .alert__indicator::after {
  font-family: "SH Icon",sans-serif;
  font-weight: normal !important;
  font-style: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  vertical-align: baseline;
  content: "";
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #e7001d;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 1em;
  width: 1.66667rem;
  height: 1.66667rem;
  /* line-height: 1.7; */
  border-radius: 50%;
  color: #ffffff;
}

.alert[hidden] {
  -webkit-transition: opacity 0.16s ease-in, max-height 0.2s ease 0.05s, -webkit-transform 0.16s ease-in;
  transition: opacity 0.16s ease-in, max-height 0.2s ease 0.05s, -webkit-transform 0.16s ease-in;
  transition: transform 0.16s ease-in, opacity 0.16s ease-in, max-height 0.2s ease 0.05s;
  transition: transform 0.16s ease-in, opacity 0.16s ease-in, max-height 0.2s ease 0.05s, -webkit-transform 0.16s ease-in;
  opacity: 0;
  pointer-events: none;
  max-height: 0;
}

.application-bar {
  /* border-bottom: 1px solid #ededed; */
}

.application-bar .application-bar__logo, .application-bar .application-bar__app-identifier, .application-bar .application-bar__additional-functions, .application-bar .application-bar__user-identifier {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
}

.application-bar__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0 auto;
  max-width: 100%;
  padding-right: .833rem;
  padding-left: .833rem;
}

@media only screen and (min-width: 36em) {
  .application-bar__container {
    width: 30em;
  }
}

@media only screen and (min-width: 48em) {
  .application-bar__container {
    width: 40em;
  }
}

@media only screen and (min-width: 62em) {
  .application-bar__container {
    width: 53.333em;
  }
}

@media only screen and (min-width: 75em) {
  .application-bar__container {
    width: 80em;
  }
}

.application-bar__logo {
  padding: 0.83333rem 1.66667rem 0.83333rem 0;
  margin: 0;
}

@media only screen and (min-width: 48em) {
  .application-bar__logo {
    padding: 1.33333rem 1.66667rem 1.33333rem 0;
  }
}

@media only screen and (min-width: 62em) {
  .application-bar__logo {
    padding-right: 2.77778rem;
  }
}

.application-bar__app-identifier {
  font-family: Calibri, sans-serif !important;
  font-size: 0.8889rem;
  color: #1a1a1a;
  margin-right: auto;
  padding-right: 1.66667rem;
  display: none;
  -webkit-box-flex: 0;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media only screen and (min-width: 48em) {
  .application-bar__app-identifier {
    display: block;
  }
}

.application-bar__additional-functions {
  margin-left: auto;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
}

.application-bar__meta-menu {
  min-width: 0;
}

.application-bar__user-identifier {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  margin-left: 0.55555rem;
}

@media only screen and (min-width: 48em) {
  .application-bar--compact .application-bar__logo {
    padding: 1.08333rem 1.66667rem 1.08333rem 0;
  }
}

.breadcrumbs {
  font-size: 0.77778rem;
  line-height: 1.11111rem;
  margin-bottom: 1.66667rem;
  list-style: none;
}

.breadcrumbs__item {
  display: inline-block;
  color: #1a1a1a;
  font-family: Calibri, sans-serif !important;
  margin-top: 0;
  margin-bottom: 0;
}

.breadcrumbs__item::after {
  content: "";
  color: #666666;
  display: inline-block;
  padding-right: 0.27778rem;
  padding-left: 0.27778rem;
  font-family: "SH Icon",sans-serif !important;
  font-weight: normal !important;
  font-style: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  vertical-align: baseline;
  font-size: 75%;
}

.breadcrumbs__item:last-child::after {
  display: none;
}

.breadcrumbs__link {
  color: #666666;
  font-family: Calibri, sans-serif !important;
}

.breadcrumbs__link:focus {
  color: #666666;
}

.breadcrumbs__link:hover, .breadcrumbs__link:active {
  color: #1a1a1a;
}

/* .button {
  background-color: #ffffff;
  color: #333333;
  border-color: #b3b3b3;
  border-style: solid;
  border-radius: 100px;
  border-width: 1px;
  display: inline-block;
  font-family: Calibri, sans-serif !important;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1;
  margin: 0;
  min-width: 6.22222rem;
  padding: 0.55556rem 1.11111rem 0.55556rem;
  position: relative;
  text-decoration: none;
  text-align: center;
  -webkit-transition: background-color 0.16s linear;
  transition: background-color 0.16s linear;
  -webkit-appearance: none; */
  /*
  Override focus styles for mouse users ONLY if
  :focus-visible is supported on this platform.
  */
  /*
  Provide basic, default focus styles.
  */
  /*
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
  */
  /*
  Define a strong focus indicator for keyboard focus.
  */
  /*
  Remove default focus styles for mouse users ONLY if
  :focus-visible is supported on this platform.
  */
  /*
  If :focus-visible is supported on this
  platform, provide enhanced focus styles for keyboard
  focus.
  */
/* } */

.button:disabled {
  opacity: 0.4;
  pointer-events: none;
  cursor: not-allowed;
}

.button:hover {
  background-color: #f2f2f2;
  color: #333333;
}

.button:hover:not(:focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.button:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
          box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
  outline: none;
}

.js-focus-visible .button:focus:not(.focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}

.js-focus-visible .button:focus.focus-visible {
  -webkit-box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
          box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
  outline: none;
}

.button:focus:not(:focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}

.button:focus-visible {
  -webkit-box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
          box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
  outline: none;
}

.button:active {
  background-color: #f2f2f2;
  /*
    Remove default focus styles for mouse users ONLY if
    :focus-visible is supported on this platform.
    */
  -webkit-box-shadow: none;
          box-shadow: none;
}

.button:active:not(:focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.button::before,
.button i::before {
  color: #cf4b00;
  margin-right: 0.25rem;
  margin-left: -0.3em;
}

[class*=bg-color-gray-shade] .button,
.bg-color-black .button {
  color: #ffffff;
  border-color: #4d4d4d;
  background-color: #1a1a1a;
  /*
  Override focus styles for mouse users ONLY if
  :focus-visible is supported on this platform.
  */
  /*
  Provide basic, default focus styles.
  */
  /*
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
  */
  /*
  Define a strong focus indicator for keyboard focus.
  */
  /*
  Remove default focus styles for mouse users ONLY if
  :focus-visible is supported on this platform.
  */
  /*
  If :focus-visible is supported on this
  platform, provide enhanced focus styles for keyboard
  focus.
  */
}

[class*=bg-color-gray-shade] .button:disabled,
.bg-color-black .button:disabled {
  opacity: 0.4;
  pointer-events: none;
  cursor: not-allowed;
}

[class*=bg-color-gray-shade] .button:hover,
.bg-color-black .button:hover {
  background-color: #3c3c3c;
}

[class*=bg-color-gray-shade] .button:hover:not(:focus-visible),
.bg-color-black .button:hover:not(:focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
}

[class*=bg-color-gray-shade] .button:focus,
.bg-color-black .button:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
          box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
  outline: none;
}

.js-focus-visible [class*=bg-color-gray-shade] .button:focus:not(.focus-visible), .js-focus-visible
.bg-color-black .button:focus:not(.focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}

.js-focus-visible [class*=bg-color-gray-shade] .button:focus.focus-visible, .js-focus-visible
.bg-color-black .button:focus.focus-visible {
  -webkit-box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
          box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
  outline: none;
}

[class*=bg-color-gray-shade] .button:focus:not(:focus-visible),
.bg-color-black .button:focus:not(:focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}

[class*=bg-color-gray-shade] .button:focus-visible,
.bg-color-black .button:focus-visible {
  -webkit-box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
          box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
  outline: none;
}

[class*=bg-color-gray-shade] .button:active,
.bg-color-black .button:active {
  background-color: #3c3c3c;
  /*
    Remove default focus styles for mouse users ONLY if
    :focus-visible is supported on this platform.
    */
}

[class*=bg-color-gray-shade] .button:active:not(:focus-visible),
.bg-color-black .button:active:not(:focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
}

[class*=bg-color-gray-shade] .button::before,
[class*=bg-color-gray-shade] .button i::before,
.bg-color-black .button::before,
.bg-color-black .button i::before {
  color: #ffffff;
}

.button--link {
  border: 0;
  min-width: 0;
  background: transparent;
  cursor: pointer;
  /*
  Override focus styles for mouse users ONLY if
  :focus-visible is supported on this platform.
  */
  /*
  Provide basic, default focus styles.
  */
  /*
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
  */
  /*
  Define a strong focus indicator for keyboard focus.
  */
  /*
  Remove default focus styles for mouse users ONLY if
  :focus-visible is supported on this platform.
  */
  /*
  If :focus-visible is supported on this
  platform, provide enhanced focus styles for keyboard
  focus.
  */
}

.button--link:hover {
  background-color: #f2f2f2;
}

.button--link:hover:not(:focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.button--link:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
          box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
  outline: none;
}

.js-focus-visible .button--link:focus:not(.focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}

.js-focus-visible .button--link:focus.focus-visible {
  -webkit-box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
          box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
  outline: none;
}

.button--link:focus:not(:focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}

.button--link:focus-visible {
  -webkit-box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
          box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
  outline: none;
}

.button--link::before,
.button--link i::before {
  color: #cf4b00;
  margin-right: 0.25rem;
}

[class*=bg-color-gray-tint] .button--link {
  /*
  Override focus styles for mouse users ONLY if
  :focus-visible is supported on this platform.
  */
}

[class*=bg-color-gray-tint] .button--link:hover {
  background-color: #e1e1e1;
}

[class*=bg-color-gray-tint] .button--link:hover:not(:focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
}

[class*=bg-color-gray-shade] .button--link,
.bg-color-black .button--link {
  background: transparent;
  /*
  Override focus styles for mouse users ONLY if
  :focus-visible is supported on this platform.
  */
}

[class*=bg-color-gray-shade] .button--link:hover,
.bg-color-black .button--link:hover {
  background-color: #262626;
}

[class*=bg-color-gray-shade] .button--link:hover:not(:focus-visible),
.bg-color-black .button--link:hover:not(:focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
}

[class*=bg-color-gray-shade] .button--link:focus,
.bg-color-black .button--link:focus {
  color: #ffffff;
}

[class*=bg-color-gray-shade] .button--link:focus::before,
[class*=bg-color-gray-shade] .button--link:focus i::before,
.bg-color-black .button--link:focus::before,
.bg-color-black .button--link:focus i::before {
  color: #ffffff;
}

[class*=bg-color-gray-shade] .button--link:active,
.bg-color-black .button--link:active {
  background-color: #262626;
}

.button--primary {
  color: #ffffff;
  background-color: #cf4b00;
  border-color: #cf4b00;
  /*
  Override focus styles for mouse users ONLY if
  :focus-visible is supported on this platform.
  */
  /*
  Provide basic, default focus styles.
  */
  /*
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
  */
  /*
  Define a strong focus indicator for keyboard focus.
  */
  /*
  Remove default focus styles for mouse users ONLY if
  :focus-visible is supported on this platform.
  */
  /*
  If :focus-visible is supported on this
  platform, provide enhanced focus styles for keyboard
  focus.
  */
}

.button--primary:hover {
  background-color: #ba4400;
  border-color: #ba4400;
  color: #ffffff;
}

.button--primary:hover:not(:focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.button--primary:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
          box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
  outline: none;
  color: #ffffff;
}

.js-focus-visible .button--primary:focus:not(.focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}

.js-focus-visible .button--primary:focus.focus-visible {
  -webkit-box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
          box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
  outline: none;
  color: #ffffff;
}

.button--primary:focus:not(:focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}

.button--primary:focus-visible {
  -webkit-box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
          box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
  outline: none;
  color: #ffffff;
}

.button--primary:active {
  background-color: #ba4400;
  border-color: #ba4400;
  /*
    Remove default focus styles for mouse users ONLY if
    :focus-visible is supported on this platform.
    */
  color: #ffffff;
}

.button--primary:active:not(:focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
}

[class*=bg-color-gray-shade] .button--primary,
.bg-color-black .button--primary {
  color: #ffffff;
  background-color: #cf4b00;
  border-color: #cf4b00;
  /*
  Override focus styles for mouse users ONLY if
  :focus-visible is supported on this platform.
  */
  /*
  Provide basic, default focus styles.
  */
  /*
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
  */
  /*
  Define a strong focus indicator for keyboard focus.
  */
  /*
  Remove default focus styles for mouse users ONLY if
  :focus-visible is supported on this platform.
  */
  /*
  If :focus-visible is supported on this
  platform, provide enhanced focus styles for keyboard
  focus.
  */
}

[class*=bg-color-gray-shade] .button--primary:hover,
.bg-color-black .button--primary:hover {
  background-color: #ba4400;
  border-color: #ba4400;
  color: #ffffff;
}

[class*=bg-color-gray-shade] .button--primary:hover:not(:focus-visible),
.bg-color-black .button--primary:hover:not(:focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
}

[class*=bg-color-gray-shade] .button--primary:focus,
.bg-color-black .button--primary:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
          box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
  outline: none;
  color: #ffffff;
}

.js-focus-visible [class*=bg-color-gray-shade] .button--primary:focus:not(.focus-visible), .js-focus-visible
.bg-color-black .button--primary:focus:not(.focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}

.js-focus-visible [class*=bg-color-gray-shade] .button--primary:focus.focus-visible, .js-focus-visible
.bg-color-black .button--primary:focus.focus-visible {
  -webkit-box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
          box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
  outline: none;
  color: #ffffff;
}

[class*=bg-color-gray-shade] .button--primary:focus:not(:focus-visible),
.bg-color-black .button--primary:focus:not(:focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}

[class*=bg-color-gray-shade] .button--primary:focus-visible,
.bg-color-black .button--primary:focus-visible {
  -webkit-box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
          box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
  outline: none;
  color: #ffffff;
}

[class*=bg-color-gray-shade] .button--primary:active,
.bg-color-black .button--primary:active {
  background-color: #ba4400;
  border-color: #ba4400;
  /*
    Remove default focus styles for mouse users ONLY if
    :focus-visible is supported on this platform.
    */
  color: #ffffff;
}

[class*=bg-color-gray-shade] .button--primary:active:not(:focus-visible),
.bg-color-black .button--primary:active:not(:focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.button--primary::before,
.button--primary i::before {
  color: #ffffff;
}

.button--control {
  color: #ec6602;
  min-width: inherit;
  padding: 0;
  width: 2.11111rem;
  height: 2.11111rem;
  overflow: hidden;
  font-size: 0;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
}

.button--control i {
  width: 100%;
}

.button--control::before,
.button--control i::before {
  margin-right: 0;
  margin-left: 0;
  font-size: 0.9rem;
}

.button--control:hover, .button--control:focus {
  color: #ec6602;
}

.button--control.button--small {
  padding: 0;
  width: 1.77778rem;
  height: 1.77778rem;
  font-size: 0;
}

.button--control.button--small::before,
.button--control.button--small i::before {
  font-size: 0.77778rem;
}

.button--small {
  font-size: 0.77778rem;
  padding: 0.38889rem 0.66667rem;
  min-width: inherit;
}

.button-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  margin: 0 -0.55556rem -1.11111rem 0;
}

.button-group > .button {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  margin: 0 0.55556rem 1.11111rem 0;
}

.button-group--stacked {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.back-to-top {
  z-index: 2222;
  -webkit-box-shadow: 0 4px 8px rgba(0, 0, 0, .1), 0 -1px 4px rgba(0, 0, 0, .05);
          box-shadow: 0 4px 8px rgba(0, 0, 0, .1), 0 -1px 4px rgba(0, 0, 0, .05);
  opacity: 0;
  pointer-events: none;
  position: fixed;
  right: 1rem;
  bottom: 1rem;
}

.back-to-top.is-visible {
  opacity: 1;
  pointer-events: inherit;
}

.back-to-top:hover {
  -webkit-box-shadow: 0 4px 8px rgba(0, 0, 0, .1), 0 -1px 4px rgba(0, 0, 0, .05);
          box-shadow: 0 4px 8px rgba(0, 0, 0, .1), 0 -1px 4px rgba(0, 0, 0, .05);
}

.back-to-top:hover i:before {
  border-color: #ec6602;
}

.back-to-top:active {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.card-slider .slick-track {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 0;
}

.card-slider .slick-slide {
  height: auto;
}

.card-slider .slick-slide:focus {
  outline: none;
}

.card-slider {
  margin-left: -0.83333rem;
  /* margin-left: -0.83333rem; */
}

.card-slider__item {
  margin: 0 0.83333rem 0.1rem;
}

.card-slider__item.card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.card-slider .slick-arrow {
  display: none !important;
}

@media only screen and (min-width: 62em) {
  .card-slider .slick-arrow {
    display: block !important;
  }
}

.card-slider .slick-list {
  overflow: visible;
}

@media only screen and (min-width: 62em) {
  .card-slider .slick-list {
    overflow: hidden;
    margin: -1em 0;
    padding: 1em 0;
  }
  .card-slider .slick-list::before {
    display: block;
    content: "";
    position: absolute;
    width: 2em;
    left: -2em;
    top: 0;
    bottom: 0;
    -webkit-box-shadow: 6px 0px 12px -6px rgba(0, 0, 0, 0.2);
            box-shadow: 6px 0px 12px -6px rgba(0, 0, 0, 0.2);
    background: #ffffff;
    z-index: 1;
    -webkit-transition: opacity 0.3s linear;
    transition: opacity 0.3s linear;
    -webkit-transition-delay: 0.3s;
            transition-delay: 0.3s;
    opacity: 0;
  }
  .card-slider.is-exceeding-left .slick-list::before {
    opacity: 1;
    -webkit-transition-delay: 0s;
            transition-delay: 0s;
  }
  .card-slider .slick-list::after {
    display: block;
    content: "";
    position: absolute;
    width: 2em;
    right: -2em;
    top: 0;
    bottom: 0;
    -webkit-box-shadow: -6px 0px 12px -6px rgba(0, 0, 0, 0.2);
            box-shadow: -6px 0px 12px -6px rgba(0, 0, 0, 0.2);
    background: #ffffff;
    -webkit-transition: opacity 0.3s linear;
    transition: opacity 0.3s linear;
    -webkit-transition-delay: 0.3s;
            transition-delay: 0.3s;
    opacity: 0;
  }
  .card-slider.is-exceeding-right .slick-list::after {
    opacity: 1;
    -webkit-transition-delay: 0s;
            transition-delay: 0s;
  }
}

.card-slider:not(.slick-initialized) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  padding-bottom: 1.8rem;
}

.card-slider:not(.slick-initialized) > .card-slider__item {
  width: 100%;
  max-width: 18.33rem;
  display: none;
}

.card-slider:not(.slick-initialized) > .card-slider__item:not(:nth-child(n+2)) {
  display: block;
}

@media only screen and (min-width: 36em) {
  .card-slider:not(.slick-initialized) > .card-slider__item {
    width: 48%;
  }
  .card-slider:not(.slick-initialized) > .card-slider__item:not(:nth-child(n+3)) {
    display: block;
  }
}

@media only screen and (min-width: 62em) {
  .card-slider:not(.slick-initialized) > .card-slider__item {
    width: 31.6%;
  }
  .card-slider:not(.slick-initialized) > .card-slider__item:not(:nth-child(n+4)) {
    display: block;
  }
}

@media only screen and (min-width: 75em) {
  .card-slider:not(.slick-initialized) > .card-slider__item {
    width: 29%;
    max-width: none;
  }
  .card-slider:not(.slick-initialized) > .card-slider__item:not(:nth-child(n+4)) {
    display: block;
  }
}

/* Loading appeareance */
/* .carousel:not(.slick-initialized) {
  margin-bottom: 2.27778rem;
} */

/* .carousel:not(.slick-initialized) > *:not(:first-child) {
  display: none;
} */

/* Icons */
/* .slick-slide:focus {
  outline: none;
} */

/* Arrows */
/* .slick-prev,
.slick-next {
  background: transparent;
  border: none;
  color: transparent;
  cursor: pointer;
  display: block;
  font-size: 0px;
  line-height: 0px;
  min-width: 0;
  outline: none;
  padding: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
          transform: translate(0, -50%);
  z-index: 900;
} */

/* .slick-prev::before,
.slick-next::before {
  -webkit-transition: all 0.16s linear;
  transition: all 0.16s linear;
} */

/* .slick-prev:focus,
.slick-next:focus {
  outline: none;
} */

.slick-prev:focus::before,
.slick-next:focus::before {
  -webkit-box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
          box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
  outline: none;
}

/* .slick-prev:hover::before,
.slick-next:hover::before {
  background-color: #f2f2f2;
} */

/* .slick-prev.slick-disabled,
.slick-next.slick-disabled {
  opacity: 0.4;
  pointer-events: none;
  outline: none;
} */

/* .slick-prev.slick-disabled::before,
.slick-next.slick-disabled::before {
  -webkit-box-shadow: none;
          box-shadow: none;
} */

.slick-prev::before,
.slick-next::before {
  font-family: "SH Icon",sans-serif;
  font-size: 1rem;
  line-height: 1;
  color: #ec6602;
  opacity: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  padding: 0;
  width: 2.22778rem;
  height: 2.22778rem;
  /* line-height: 2.22778; */
  text-align: center;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid #b3b3b3;
  -webkit-transition: all 0.16s linear;
  transition: all 0.16s linear;
}

/* .ie .slick-prev::before, .ie
.slick-next::before {
  line-height: 2.005;
} */

/* .ios .slick-prev::before, .ios
.slick-next::before {
  height: 2.22878rem;
} */

/* .slick-prev {
  left: -3.89444rem;
} */

/* [dir='rtl'] .slick-prev {
  left: auto;
  right: -3.89444rem;
} */

/* .slick-prev::before {
  content: '\e81E';
} */

/* [dir='rtl'] .slick-prev::before {
  content: '\e81C';
} */

/* .slick-next {
  right: -3.89444rem;
} */

/* [dir='rtl'] .slick-next {
  left: -3.89444rem;
  right: auto;
} */

/* .slick-next::before {
  content: '\e81C';
} */

/* [dir='rtl'] .slick-next::before {
  content: '\e81E';
} */

/* .slick-next:focus,
.slick-prev:focus {
  overflow: visible;
} */

/* Dots */
/* .slick-dots {
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
  position: relative;
  margin-top: 1.05556rem;
  line-height: 0;
} */

/* .slick-dots li {
  position: relative;
  display: inline-block;
  height: 1.22222rem;
  width: 1.22222rem;
  margin: 0 3px;
  padding: 0;
  cursor: pointer;
} */

/* .slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 1.22222rem;
  width: 1.22222rem;
  min-width: 0;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
} */

/* .slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
} */

/* .slick-dots li button:hover::before, .slick-dots li button:focus::before {
  opacity: 1;
} */

/* .slick-dots li button::before {
  position: absolute;
  top: 0;
  left: 0;
  content: '\25CF';
  width: 1.22222rem;
  height: 1.22222rem;
  font-family: "SH Icon",sans-serif;
  font-size: 1.22222rem;
  line-height: 1;
  text-align: center;
  color: #b3b3b3;
  opacity: 0.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

/* .slick-dots li.slick-active button:before {
  color: #1a1a1a;
  opacity: 1;
} */

/* .slick-dots li:first-child:last-child {
  visibility: hidden;
} */

.checkbox {
  opacity: 0;
  width: 1.11111rem;
  height: 1.11111rem;
  position: absolute;
}

.checkbox + label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  width: auto;
  padding-left: 0.83333rem;
  margin: 0 0 0 1.11111rem;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.checkbox + label::before {
  line-height: 1;
  content: "";
  display: inline-block;
  width: 1.11111rem;
  height: 1.11111rem;
  position: absolute;
  left: -1.11111rem;
  background-color: #ffffff;
  border: 1px solid #666666;
  top: 0.3rem;
  border-radius: 2px;
}

.checkbox:hover + label::before {
  background-color: #f2f2f2;
}

.checkbox:checked + label::before {
  background-color: #ec6602;
  border: 1px solid #ec6602;
  content: "\e818";
  font-family: "SH Icon",sans-serif;
  font-size: 0.77778rem;
  font-weight: 400;
  line-height: 1rem;
  color: #ffffff;
  text-align: center;
}

.checkbox:focus + label::before {
  -webkit-box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
          box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
}

.checkbox:disabled + label {
  opacity: 0.4;
  pointer-events: none;
  cursor: default;
}

.checkbox.is-invalid + label:before {
  border-color: #e7001d;
}

.checkbox.is-invalid ~ .checkbox__hint {
  color: #666666;
  display: block;
  margin-left: 2rem;
  line-height: 1.5625;
  line-height: 1.66667rem;
}

.checkbox.is-invalid ~ .checkbox__hint::before {
  font-family: "SH Icon",sans-serif;
  font-weight: normal !important;
  font-style: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  vertical-align: baseline;
  content: "";
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #e7001d;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 1em;
  width: 1.66667rem;
  height: 1.66667rem;
  /* line-height: 1.7; */
  border-radius: 50%;
  color: #ffffff;
  float: left;
  /* vertical-align: bottom; */
  margin-right: 0.27778rem;
  margin-left: -.1em;
  margin-top: calc( (30px - 1.7em)/2);
}

/* Loading appeareance */
.carousel:not(.slick-initialized) {
  margin-bottom: 2.27778rem;
}

.carousel:not(.slick-initialized) > *:not(:first-child) {
  display: none;
}

/* Icons */
.slick-slide:focus {
  outline: none;
}

/* Arrows */
.slick-prev,
.slick-next {
  background: transparent;
  border: none;
  color: transparent;
  cursor: pointer;
  display: block;
  font-size: 0px;
  line-height: 0px;
  min-width: 0;
  outline: none;
  padding: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
          transform: translate(0, -50%);
  z-index: 900;
}

.slick-prev:focus,
.slick-next:focus {
  outline: none;
}

/* .slick-prev:focus::before,
.slick-next:focus::before {
  -webkit-box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
          box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
  outline: none;
} */

.slick-prev:hover::before,
.slick-next:hover::before {
  background-color: #f2f2f2;
}

.slick-prev.slick-disabled,
.slick-next.slick-disabled {
  opacity: 0.4;
  pointer-events: none;
  outline: none;
}

.slick-prev.slick-disabled::before,
.slick-next.slick-disabled::before {
  -webkit-box-shadow: none;
          box-shadow: none;
}

/* .slick-prev::before,
.slick-next::before {
  font-family: "SH Icon",sans-serif;
  font-size: 1rem;
  line-height: 1;
  color: #ec6602;
  opacity: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  padding: 0;
  width: 2.22778rem;
  height: 2.22778rem;
  text-align: center;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid #b3b3b3;
  -webkit-transition: all 0.16s linear;
  transition: all 0.16s linear;
} */

.ie .slick-prev::before, .ie
.slick-next::before {
  line-height: 2.005;
}

.ios .slick-prev::before, .ios
.slick-next::before {
  height: 2.22878rem;
}

.slick-prev {
  left: -3.89444rem;
}

[dir='rtl'] .slick-prev {
  left: auto;
  right: -3.89444rem;
}

.slick-prev::before {
  content: '\e81E';
}

[dir='rtl'] .slick-prev::before {
  content: '\e81C';
}

.slick-next {
  right: -3.89444rem;
}

[dir='rtl'] .slick-next {
  left: -3.89444rem;
  right: auto;
}

.slick-next::before {
  content: '\e81C';
}

[dir='rtl'] .slick-next::before {
  content: '\e81E';
}

/* .slick-next:focus,
.slick-prev:focus {
  overflow: visible;
} */

/* Dots */
.slick-dots {
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
  position: relative;
  margin-top: 1.05556rem;
  line-height: 0;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 1.22222rem;
  width: 1.22222rem;
  margin: 0 3px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 1.22222rem;
  width: 1.22222rem;
  min-width: 0;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover::before, .slick-dots li button:focus::before {
  opacity: 1;
}

.slick-dots li button::before {
  position: absolute;
  top: 0;
  left: 0;
  content: '\25CF';
  width: 1.22222rem;
  height: 1.22222rem;
  font-family: "SH Icon",sans-serif;
  font-size: 1.22222rem;
  line-height: 1;
  text-align: center;
  color: #b3b3b3;
  opacity: 0.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  color: #1a1a1a;
  opacity: 1;
}

.slick-dots li:first-child:last-child {
  visibility: hidden;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.date-time-picker[data-inline="true"] {
  height: auto;
  border: 0;
  width: auto;
}

.date-time-picker[data-inline="true"] .date-time-picker__input,
.date-time-picker[data-inline="true"] .date-time-picker__label {
  display: none;
}

.flatpickr-calendar {
  -webkit-box-shadow: 0 4px 8px rgba(0, 0, 0, .1), 0 -1px 4px rgba(0, 0, 0, .05);
          box-shadow: 0 4px 8px rgba(0, 0, 0, .1), 0 -1px 4px rgba(0, 0, 0, .05);
  background-color: #ffffff;
  border: 0;
  direction: ltr;
  display: none;
  font-size: 0.8889rem;
  margin-top: 2px;
  opacity: 0;
  outline: 0;
  padding: 0;
  position: absolute;
  text-align: center;
  -ms-touch-action: manipulation;
      touch-action: manipulation;
  visibility: hidden;
  width: 19.44444rem;
  z-index: 0;
}

.flatpickr-calendar.open, .flatpickr-calendar.inline {
  opacity: 1;
  max-height: 640px;
  visibility: visible;
}

.flatpickr-calendar.open {
  display: inline-block;
  z-index: 8888;
}

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid #e6e6e6;
  margin-top: 0;
}

.flatpickr-calendar.arrowBottom {
  margin-top: -10px;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.flatpickr-calendar .flatpickr-months {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.flatpickr-calendar .flatpickr-months .flatpickr-month {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  position: relative;
}

.flatpickr-calendar .flatpickr-months .flatpickr-month .flatpickr-current-month {
  font-family: Calibri, sans-serif !important;
  font-size: 1rem;
  font-weight: 800;
  height: 2.778em;
  padding: 0.556em 0.278em;
}

.flatpickr-calendar .flatpickr-months .flatpickr-month .flatpickr-current-month .numInputWrapper {
  display: inline-block;
}

.flatpickr-calendar .flatpickr-months .flatpickr-month .flatpickr-current-month .numInputWrapper .numInput {
  pointer-events: none;
  width: 2.5em;
  font-family: Calibri, sans-serif !important;
  font-size: 1rem;
  font-weight: 800;
  line-height: inherit;
  border: 0;
  padding: 0;
  margin: 0;
}

.flatpickr-calendar .flatpickr-months .flatpickr-month .flatpickr-current-month .numInputWrapper .arrowUp,
.flatpickr-calendar .flatpickr-months .flatpickr-month .flatpickr-current-month .numInputWrapper .arrowDown {
  display: none;
}

.flatpickr-calendar .flatpickr-months .flatpickr-prev-month,
.flatpickr-calendar .flatpickr-months .flatpickr-next-month {
  position: absolute;
  width: 2.77778rem;
  padding: 0.55556rem;
  cursor: pointer;
  z-index: 8889;
  color: #666666;
}

.flatpickr-calendar .flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-calendar .flatpickr-months .flatpickr-next-month:hover {
  color: #1a1a1a;
}

.flatpickr-calendar .flatpickr-months .flatpickr-next-month {
  right: 0;
}

.flatpickr-calendar .flatpickr-weekdays {
  font-size: 1rem;
  color: #666666;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.flatpickr-calendar .flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.flatpickr-calendar .flatpickr-weekdays .flatpickr-weekdaycontainer .flatpickr-weekday {
  cursor: help;
  margin: 0;
  text-align: center;
  display: block;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.flatpickr-calendar .flatpickr-days {
  font-size: 1rem;
  color: #1a1a1a;
}

.flatpickr-calendar .flatpickr-days .dayContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 2.77778rem;
  height: 2.77778rem;
  -ms-flex-preferred-size: 14.286%;
      flex-basis: 14.286%;
  border-radius: 50%;
  position: relative;
}

.flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day::before {
  content: ' ';
  display: block;
  position: absolute;
  width: 2.22222rem;
  height: 2.22222rem;
  z-index: -1;
  top: 50%;
  margin-top: -1.11111rem;
  left: 50%;
  margin-left: -1.11111rem;
  border-radius: 50%;
}

.flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day.today {
  color: #cf4b00;
}

.flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day.prevMonthDay, .flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day.nextMonthDay {
  color: #999999;
}

.flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day:hover::before {
  background-color: #ededed;
}

.flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day.selected {
  color: #ffffff;
}

.flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day.selected::before {
  background-color: #cf4b00;
}

.flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day.disabled {
  color: #b3b3b3;
  cursor: inherit;
}

.flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day.disabled:hover:before {
  display: none;
}

.flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day.startRange::after, .flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day.endRange::after, .flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day.inRange::after {
  content: ' ';
  display: block;
  position: absolute;
  width: 2.91667rem;
  height: 2.22222rem;
  z-index: -2;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  background-color: #e6e6e6;
}

.flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day.startRange:not(.selected) {
  color: #ffffff;
}

.flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day.startRange:not(.selected)::before {
  content: ' ';
  display: block;
  position: absolute;
  width: 2.22222rem;
  height: 2.22222rem;
  z-index: -1;
  border-radius: 50%;
  background-color: #cf4b00;
}

.flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day.startRange::after {
  width: 1.44444rem;
  left: 50%;
}

.flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day.endRange:not(.selected) {
  color: #ffffff;
}

.flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day.endRange:not(.selected)::before {
  content: ' ';
  display: block;
  position: absolute;
  width: 2.22222rem;
  height: 2.22222rem;
  z-index: -1;
  border-radius: 50%;
  background-color: #cf4b00;
}

.flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day.endRange::after {
  width: 1.38889rem;
  left: 0;
}

.flatpickr-calendar .flatpickr-day:not(.inRange):not(.startRange) + .flatpickr-day.endRange::after, .flatpickr-calendar .flatpickr-day.startRange.endRange::after {
  display: none;
}

.flatpickr-calendar.showTimeInput .flatpickr-time {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.flatpickr-calendar .flatpickr-time {
  display: none;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 2.77778rem 0;
}

.flatpickr-calendar .flatpickr-time .flatpickr-time-separator {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 1.66667rem;
  height: 1.66667rem;
  -ms-flex-preferred-size: 1.66667rem;
      flex-basis: 1.66667rem;
  text-align: left;
}

.flatpickr-calendar .flatpickr-time .flatpickr-am-pm {
  font-family: "Siemens Sans", "SH-Bree-Text", "Open Sans", "Roboto", "Droid Sans", "Segoe UI", -apple-system, BlinkMacSystemFont, sans-serif;
  /*
  Override focus styles for mouse users ONLY if
  :focus-visible is supported on this platform.
  */
  width: 1.66667rem;
  height: 1.66667rem;
  line-height: 1.72222rem;
  -ms-flex-preferred-size: 1.66667rem;
      flex-basis: 1.66667rem;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  margin-left: 1.66667rem;
}

.flatpickr-calendar .flatpickr-time .flatpickr-am-pm:hover {
  background-color: #f2f2f2;
}

.flatpickr-calendar .flatpickr-time .flatpickr-am-pm:hover:not(:focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.flatpickr-calendar .flatpickr-time .numInputWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 1.66667rem;
  height: 1.66667rem;
  -ms-flex-preferred-size: 1.66667rem;
      flex-basis: 1.66667rem;
  position: relative;
}

.flatpickr-calendar .flatpickr-time .numInputWrapper .numInput {
  font-family: "Siemens Sans", "SH-Bree-Text", "Open Sans", "Roboto", "Droid Sans", -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: inherit;
  border: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
}

.flatpickr-calendar .flatpickr-time .numInputWrapper .arrowUp,
.flatpickr-calendar .flatpickr-time .numInputWrapper .arrowDown {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  -webkit-transform: translateY(-100%);
      -ms-transform: translateY(-100%);
          transform: translateY(-100%);
  cursor: pointer;
  color: #666666;
}

.flatpickr-calendar .flatpickr-time .numInputWrapper .arrowUp::before,
.flatpickr-calendar .flatpickr-time .numInputWrapper .arrowDown::before {
  font-family: "SH Icon",sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  font-size: 0.8em;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: baseline;
  content: '\e81d';
  display: block;
}

.flatpickr-calendar .flatpickr-time .numInputWrapper .arrowUp:hover,
.flatpickr-calendar .flatpickr-time .numInputWrapper .arrowDown:hover {
  color: #1a1a1a;
}

.flatpickr-calendar .flatpickr-time .numInputWrapper .arrowDown {
  -webkit-transform: translateY(100%);
      -ms-transform: translateY(100%);
          transform: translateY(100%);
}

.flatpickr-calendar .flatpickr-time .numInputWrapper .arrowDown::before {
  content: '\e81f';
}

.disclaimer {
  font-size: 0.77778rem;
  color: #666666;
  line-height: 1.11111rem;
  letter-spacing: 0.3;
  position: relative;
}

.disclaimer__numbering + .disclaimer__text {
  padding-left: 1.25rem;
}

.disclaimer__numbering {
  font-size: 0.66667rem;
  color: #666666;
  position: absolute;
  top: 0;
  left: 0;
}

.disclaimer__text {
  display: block;
}

.dropdown {
  display: inline-block;
  position: relative;
}

.dropdown__toggle {
  display: block;
  cursor: pointer;
}

.dropdown__toggle [class^=icon] {
  color: #666666;
}

.dropdown__toggle:hover [class^=icon] {
  color: #1a1a1a;
}

.dropdown__panel {
  display: none;
  position: absolute;
  z-index: 8888;
  margin: 0;
  top: 100%;
  left: 0;
  min-width: 100%;
  background-color: #ffffff;
  -webkit-box-shadow: 0 4px 8px rgba(0, 0, 0, .1), 0 -1px 4px rgba(0, 0, 0, .05);
          box-shadow: 0 4px 8px rgba(0, 0, 0, .1), 0 -1px 4px rgba(0, 0, 0, .05);
  border: 1px solid #e6e6e6;
}

.dropdown.is-open .dropdown__toggle [class^=icon] {
  color: #1a1a1a;
}

.dropdown.is-open .dropdown__panel {
  display: block;
}

.dropdown--right .dropdown__panel {
  left: inherit;
  right: 0;
}

.feedback__button {
  color: #666666;
}

.feedback__button i::before {
  font-size: 2rem;
  left: -6px;
  position: relative;
}

.feedback__button:disabled.is-selected, .feedback__button:hover {
  color: #1a1a1a;
}

.feedback__button:disabled {
  opacity: 0.4;
  pointer-events: none;
  cursor: not-allowed;
}

[class*=bg-color-gray-shade] .feedback__button,
.bg-color-black .feedback__button {
  color: #999999;
}

[class*=bg-color-gray-shade] .feedback__button:disabled.is-selected, [class*=bg-color-gray-shade] .feedback__button:hover,
.bg-color-black .feedback__button:disabled.is-selected,
.bg-color-black .feedback__button:hover {
  color: #ffffff;
}

[class*=bg-color-gray-shade] .feedback .feedback__modal-container,
.bg-color-black .feedback .feedback__modal-container {
  background-color: #000000;
  -webkit-box-shadow: 0 4px 8px rgba(0, 0, 0, .2), 0 -1px 4px rgba(0, 0, 0, .1);
          box-shadow: 0 4px 8px rgba(0, 0, 0, .2), 0 -1px 4px rgba(0, 0, 0, .1);
}

.figure__image-container {
  display: block;
}

.footer {
  margin-top: 5rem;
  padding-bottom: 3rem;
}

.footer__col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.footer__col-title {
  font-size: 0.8889rem;
  margin: 1.5rem 0 1.11111rem;
}

.footer__legal-links {
  width: 100%;
  margin-top: .1rem;
}

@media only screen and (min-width: 48em) {
  .footer__legal-links {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
}

.footer__social-channels {
  margin-top: 1.1rem;
  margin-bottom: 1.5rem;
  width: 100%;
}

@media only screen and (min-width: 48em) {
  .footer__social-channels {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
}

.footer__search {
  display: none;
}

@media only screen and (min-width: 62em) {
  .footer__search {
    display: block;
  }
}

.footer hr {
  margin: 1.11111rem 0 1.11111rem;
}

.hero-block {
  width: 100%;
  background-color: #000000;
}

@media screen and (max-width: 30em) {
  .hero-block__image {
    width: 100vw;
    margin-left: -0.83333rem;
  }
}

@media only screen and (min-width: 48em) {
  .hero-block__image {
    height: 100%;
  }
}

.hero-block__pulse {
  width: 100%;
  margin: 1.66667rem 0;
  display: none;
}

@media only screen and (min-width: 36em) {
  .hero-block__pulse {
    display: block;
  }
}

@media only screen and (min-width: 62em) {
  .hero-block__pulse {
    margin: 3.33333rem 0;
  }
}

.hero-block__panel {
  background-color: #000000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.hero-block__content {
  height: 100%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

@media only screen and (min-width: 62em) {
  .hero-block__content > *:last-child {
    margin-bottom: 0;
  }
}

.hero-block__title {
  margin-top: 0.83333rem;
  margin-bottom: 1.11111rem;
  color: #ec6602;
}

@media only screen and (min-width: 62em) {
  .hero-block__title {
    margin-top: 0;
  }
}

.hero-block__subtitle {
  color: #ffffff;
  margin-top: 0;
  margin-bottom: 0;
  display: block;
}

.hero-block__text {
  background-color: #ffffff;
  padding-top: 1.11111rem;
}

.hero-block--white {
  background-color: #ffffff;
}

.hero-block--white .hero-block__pulse {
  display: none;
}

.hero-block--white .hero-block__subtitle {
  color: #1a1a1a;
}

.hero-block--white .hero-block__panel {
  background-color: #ffffff;
}

.hero-block--white .hero-block__text {
  padding-top: 0;
}

@media only screen and (min-width: 62em) {
  .hero-block--white .hero-block__text {
    padding-top: 1.11111rem;
  }
}

.hotspots {
  position: relative;
}

.hotspots__image-container {
  position: relative;
}

.hotspots__image {
  width: 100%;
}

@media only screen and (min-width: 48em) {
  .hotspots__list {
    list-style: none;
  }
}

@media only screen and (max-width: 47.94444em) {
  .hotspots__item {
    left: 0 !important;
  }
}

@media only screen and (min-width: 48em) {
  .hotspots__item {
    left: inherit;
    display: none;
    position: absolute;
    text-align: left;
    color: #1a1a1a;
    background-color: #ffffff;
    z-index: 9999;
    padding: 1rem;
    -webkit-transform: translate(20px, 10px);
        -ms-transform: translate(20px, 10px);
            transform: translate(20px, 10px);
    -webkit-box-shadow: 2px 2px 10px rgba(26, 26, 26, 0.1);
            box-shadow: 2px 2px 10px rgba(26, 26, 26, 0.1);
    max-width: 300px;
  }
  .hotspots__item::before {
    display: none !important;
  }
  .hotspots__item.is-active {
    display: block;
  }
}

.hotspots__toggle-button {
  display: none;
  background-color: #ffffff;
  width: 2.22222rem;
  height: 2.22222rem;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  /*
  Override focus styles for mouse users ONLY if
  :focus-visible is supported on this platform.
  */
}

@media only screen and (min-width: 48em) {
  .hotspots__toggle-button {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
}

.hotspots__toggle-button:hover {
  background-color: #f2f2f2;
}

.hotspots__toggle-button:hover:not(:focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.hotspots__scroll-button {
  font-size: 0.8889rem;
  position: absolute;
  background-color: #ffffff;
  width: 2.22222rem;
  height: 2.22222rem;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-weight: 600;
  /*
  Override focus styles for mouse users ONLY if
  :focus-visible is supported on this platform.
  */
}

@media only screen and (min-width: 48em) {
  .hotspots__scroll-button {
    display: none;
  }
}

.hotspots__scroll-button:hover {
  background-color: #f2f2f2;
}

.hotspots__scroll-button:hover:not(:focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
}

@-webkit-keyframes pulsate {
  to {
    -webkit-box-shadow: 0 0 0 10px rgba(252, 220, 202, 0);
            box-shadow: 0 0 0 10px rgba(252, 220, 202, 0);
  }
}

@keyframes pulsate {
  to {
    -webkit-box-shadow: 0 0 0 10px rgba(252, 220, 202, 0);
            box-shadow: 0 0 0 10px rgba(252, 220, 202, 0);
  }
}

/* .label {
  background-color: #ededed;
  color: #1a1a1a;
  font-family: "Siemens Sans", "SH-Bree-Text", "Open Sans", "Roboto", "Droid Sans", "Segoe UI", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 600;
  font-size: 0.66667rem;
  border-radius: 3px;
  display: inline-block;
  line-height: 1;
  margin: 0;
  padding: 0.22222rem 0.33333rem;
  vertical-align: text-bottom;
  white-space: nowrap;
} */

.label--attached {
  margin-left: 0.41667rem;
  margin-right: 0.41667rem;
}

.label--superscript {
  font-size: 0.55556rem;
  min-width: 1.33em;
  padding: 0.2rem;
  text-align: center;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
}

[class*=bg-color-gray-tint] .label {
  background-color: #ffffff;
}

[class*=bg-color-gray-shade] .label,
.bg-color-black .label {
  background-color: #333333;
  color: #ffffff;
}

.legal-links {
  list-style-type: none;
  margin: 0;
  margin-top: 1.11111rem;
  padding-left: 0;
  line-height: 1.11111rem;
  font-size: 0.77778rem;
}

.legal-links__item {
  display: inline-block;
  margin: 0;
}

.legal-links__item:after {
  display: inline-block;
  content: "|";
  color: #666666;
  margin: 0 0.2em;
}

.legal-links__item:last-of-type:after {
  display: none;
}

.legal-links__link, .legal-links__item:first-child {
  color: #666666;
}

.legal-links__link:hover {
  color: #1a1a1a;
}

.bg-color-gray-shade-4 .legal-links__item:after {
  color: #999999;
}

.bg-color-gray-shade-4 .legal-links__link, .bg-color-gray-shade-4 .legal-links__item:first-child {
  color: #999999;
}

.list {
  margin-left: 0.888rem;
  padding-left: 0;
}

.list--inline .list__item {
  display: inline-block;
}

.list--bullet {
  list-style-type: none;
  padding-left: 16px;
  margin-left: 0;
}

.list--bullet > li,
.list--bullet .list__item {
  list-style: none;
}

.list--bullet > li:before,
.list--bullet .list__item:before {
  content: " ";
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #1a1a1a;
  position: absolute;
  left: -14.4px;
  top: 0.66667rem;
}

.list--numbered {
  padding-left: 1.66667rem;
  margin-left: 0;
  counter-reset: item;
  list-style-type: none;
}

.list--numbered > li::before,
.list--numbered .list__item::before {
  font-size: 0.77778rem;
  font-weight: 400;
  content: counter(item);
  counter-increment: item;
  display: inline;
  position: absolute;
  left: -1.66667rem;
  top: 0.05556rem;
}

.list--numbered > li:nth-child(1)::before,
.list--numbered .list__item:nth-child(1)::before {
  content: "01";
}

.list--numbered > li:nth-child(2)::before,
.list--numbered .list__item:nth-child(2)::before {
  content: "02";
}

.list--numbered > li:nth-child(3)::before,
.list--numbered .list__item:nth-child(3)::before {
  content: "03";
}

.list--numbered > li:nth-child(4)::before,
.list--numbered .list__item:nth-child(4)::before {
  content: "04";
}

.list--numbered > li:nth-child(5)::before,
.list--numbered .list__item:nth-child(5)::before {
  content: "05";
}

.list--numbered > li:nth-child(6)::before,
.list--numbered .list__item:nth-child(6)::before {
  content: "06";
}

.list--numbered > li:nth-child(7)::before,
.list--numbered .list__item:nth-child(7)::before {
  content: "07";
}

.list--numbered > li:nth-child(8)::before,
.list--numbered .list__item:nth-child(8)::before {
  content: "08";
}

.list--numbered > li:nth-child(9)::before,
.list--numbered .list__item:nth-child(9)::before {
  content: "09";
}

.list--block {
  list-style: none;
  margin: 0;
}

.list--block .list__item {
  margin: 0;
  position: relative;
}

.list--block .list__link {
  display: block;
  margin: 0;
  padding: 0.3055rem 0;
}

.list--link {
  padding-left: 0;
  list-style-type: none;
  margin-left: 0;
}

.list--link .list__item {
  list-style: none;
  margin-left: 0;
}

.list--link .list__item::before {
  display: none;
}

.list--link .list__item--no-link::before {
  display: block;
  left: 0.5rem;
}

.list--link .list__link {
  display: inline-block;
  padding: 0 0 0 1.66667rem;
}

.list--link .list__link::before {
  position: absolute;
  left: 0;
  top: -.25em;
  padding: .25em 0;
  line-height: inherit;
}

.list--link .list__link:hover, .list--link .list__link:active {
  background: transparent;
}

.list--link .list__link.icon--circle {
  padding: 0 0 0 2.22222rem;
  display: inline-block;
}

.list--link .list__link.icon--circle::before {
  top: 0;
  padding: 0;
  -webkit-transition: background-color 0.16s linear;
  transition: background-color 0.16s linear;
}

.list--link .list__link:hover.icon--circle::before {
  border-color: #b3b3b3;
  background-color: #f2f2f2;
}

@-webkit-keyframes _loading-animation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes _loading-animation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loading-animation {
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  border: 5px solid #e6e6e6;
  border-top-color: #ec6602;
  border-radius: 50%;
  height: 60px;
  width: 60px;
}

/* .logo {
   background: url("assets/logo.svg") no-repeat; 
  background-size: cover;
  display: block;
} */

@media only screen and (min-width: 0em) {
  .logo {
    width: 11.11111rem;
    height: 2.77778rem;
    margin: -0.69444rem -2.77778rem;
    -webkit-transform: scale(0.5);
        -ms-transform: scale(0.5);
            transform: scale(0.5);
  }
}

@media only screen and (min-width: 48em) {
  .logo {
    width: 18.55556rem;
    height: 4.63889rem;
    margin: -1.15972rem -4.63889rem;
    -webkit-transform: scale(0.5);
        -ms-transform: scale(0.5);
            transform: scale(0.5);
  }
}

@media only screen and (min-width: 75em) {
  .logo {
    width: 18.77778rem;
    height: 4.69444rem;
    margin: -1.17361rem -4.69444rem;
    -webkit-transform: scale(0.5);
        -ms-transform: scale(0.5);
            transform: scale(0.5);
  }
}

.logo--compact {
  width: 13.88889rem;
  height: 3.47222rem;
  margin: -0.86806rem -3.47222rem;
  -webkit-transform: scale(0.5);
      -ms-transform: scale(0.5);
          transform: scale(0.5);
}

.menu {
  padding: 0;
  list-style: none;
}

.menu__item {
  background-color: #ffffff;
  color: #1a1a1a;
  cursor: pointer;
  display: block;
  margin: 0;
  padding: 0.55556rem 0.83333rem;
  text-align: left;
  text-overflow: ellipsis;
  -webkit-transition: background-color 0.16s linear;
  transition: background-color 0.16s linear;
  white-space: nowrap;
  /*
  Override focus styles for mouse users ONLY if
  :focus-visible is supported on this platform.
  */
  /*
  Provide basic, default focus styles.
  */
  /*
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
  */
  /*
  Define a strong focus indicator for keyboard focus.
  */
  /*
  Remove default focus styles for mouse users ONLY if
  :focus-visible is supported on this platform.
  */
  /*
  If :focus-visible is supported on this
  platform, provide enhanced focus styles for keyboard
  focus.
  */
}

.menu__item:hover {
  background-color: #f2f2f2;
  color: inherit;
}

.menu__item:hover:not(:focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.menu__item:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
          box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
  outline: none;
  position: relative;
}

.js-focus-visible .menu__item:focus:not(.focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}

.js-focus-visible .menu__item:focus.focus-visible {
  -webkit-box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
          box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
  outline: none;
  position: relative;
}

.menu__item:focus:not(:focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}

.menu__item:focus-visible {
  -webkit-box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
          box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
  outline: none;
  position: relative;
}

.menu__item:active {
  background-color: #f2f2f2;
  /*
    Remove default focus styles for mouse users ONLY if
    :focus-visible is supported on this platform.
    */
  -webkit-box-shadow: none;
          box-shadow: none;
}

.menu__item:active:not(:focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.menu__item.is-active, .menu__item.is-current, .menu__item.is-selected {
  background-color: #e6e6e6;
  color: inherit;
  outline: none;
}

.meta-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0 0 0 0.55555rem;
}

.meta-menu__list {
  overflow: visible;
  /* keep overflow visible to see focus outlines */
  white-space: nowrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.meta-menu__item {
  -webkit-box-flex: 0;
      -ms-flex: none;
          flex: none;
  margin: 0;
  white-space: nowrap;
}

.meta-menu__link {
  color: #666666;
  font-size: 0.8889rem;
  font-family: "Siemens Sans", "SH-Bree-Text", "Open Sans", "Roboto", "Droid Sans", "Segoe UI", -apple-system, BlinkMacSystemFont, sans-serif;
  /*
  Provide basic, default focus styles.
  */
  /*
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
  */
  /*
  Define a strong focus indicator for keyboard focus.
  */
  /*
  Remove default focus styles for mouse users ONLY if
  :focus-visible is supported on this platform.
  */
  /*
  If :focus-visible is supported on this
  platform, provide enhanced focus styles for keyboard
  focus.
  */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 0.55555rem;
  -webkit-transition: color 0.16s linear;
  transition: color 0.16s linear;
}

.meta-menu__link:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
          box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
  outline: none;
}

.js-focus-visible .meta-menu__link:focus:not(.focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}

.js-focus-visible .meta-menu__link:focus.focus-visible {
  -webkit-box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
          box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
  outline: none;
}

.meta-menu__link:focus:not(:focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}

.meta-menu__link:focus-visible {
  -webkit-box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
          box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
  outline: none;
}

.meta-menu__link i::before {
  color: #666666;
  font-size: 1.6em;
  position: relative;
  top: 2px;
  margin-right: inherit;
  -webkit-transition: color 0.16s linear;
  transition: color 0.16s linear;
}

.meta-menu__link:hover {
  color: #1a1a1a;
}

.meta-menu__link:hover i::before {
  color: #1a1a1a;
}

.meta-menu__hidden-list {
  margin-bottom: 0;
}

.meta-menu__title {
  margin-left: 0.25rem;
  display: none;
}

@media only screen and (min-width: 62em) {
  .meta-menu__title {
    display: inline-block;
  }
}

.meta-menu__hidden-list .meta-menu__title {
  display: inline-block;
}

.meta-menu__toggle-button {
  -webkit-box-flex: 0;
      -ms-flex: none;
          flex: none;
  margin: 0 0.55555rem;
  display: none;
}

.meta-menu__toggle-button.is-visible {
  display: block;
}

.modal-dialog {
  display: none;
  z-index: 4444;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  outline: 0;
  background-color: rgba(26, 26, 26, 0.5);
}

.modal-dialog.is-open {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.modal-dialog__container {
  position: relative;
  background-color: #ffffff;
  border-radius: 0;
  overflow: hidden;
  -webkit-box-shadow: 0 4px 8px rgba(0, 0, 0, .1), 0 -1px 4px rgba(0, 0, 0, .05);
          box-shadow: 0 4px 8px rgba(0, 0, 0, .1), 0 -1px 4px rgba(0, 0, 0, .05);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

@media only screen and (max-width: 47.94444em) {
  .modal-dialog__container {
    height: 100%;
    width: 100%;
  }
}

@media only screen and (min-width: 48em) {
  .modal-dialog__container {
    display: block;
    width: 600px;
    max-width: 100%;
  }
}

.modal-dialog__header {
  padding: 1.11111rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-flex: 0;
      -ms-flex: none;
          flex: none;
}

.modal-dialog__indicator {
  display: none;
  margin-right: 0.55556rem;
}

.modal-dialog__title {
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: calc(100% - 2rem);
}

.modal-dialog__close {
  font-size: 1.22222rem;
  -webkit-box-flex: 0;
      -ms-flex: none;
          flex: none;
}

.modal-dialog__close i::before {
  color: #666666;
  margin: 0;
}

.modal-dialog__close:hover i::before {
  color: #1a1a1a;
}

.modal-dialog__body {
  padding: 1.11111rem;
  overflow: hidden;
  overflow-y: auto;
  max-height: calc(100vh - 9rem);
}

.modal-dialog__body > *:first-child {
  margin-top: 0;
}

.modal-dialog__body > *:last-child {
  margin-bottom: 0;
}

.modal-dialog__footer {
  padding: 1.11111rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-flex: 0;
      -ms-flex: none;
          flex: none;
  margin-top: auto;
}

.modal-dialog__footer > * {
  margin: 0 0 0 0.55556rem;
}

.modal-dialog--success .modal-dialog__indicator {
  display: inline-block;
}

.modal-dialog--success .modal-dialog__indicator::after {
  font-family: "SH Icon",sans-serif;
  font-weight: normal !important;
  font-style: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  vertical-align: baseline;
  content: "";
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #009a38;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 1em;
  width: 1.66667rem;
  height: 1.66667rem;
  /* line-height: 1.7; */
  border-radius: 50%;
  color: #ffffff;
}

.modal-dialog--warning .modal-dialog__indicator {
  display: inline-block;
}

.modal-dialog--warning .modal-dialog__indicator::after {
  font-family: "SH Icon",sans-serif;
  font-weight: normal !important;
  font-style: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  vertical-align: baseline;
  content: "";
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #ffd200;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 1em;
  width: 1.66667rem;
  height: 1.66667rem;
  /* line-height: 1.7; */
  border-radius: 50%;
  color: #000000;
  /* color: #000000; */
}

.modal-dialog--error .modal-dialog__indicator {
  display: inline-block;
}

.modal-dialog--error .modal-dialog__indicator::after {
  font-family: "SH Icon",sans-serif;
  font-weight: normal !important;
  font-style: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  vertical-align: baseline;
  content: "";
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #e7001d;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 1em;
  width: 1.66667rem;
  height: 1.66667rem;
  /* line-height: 1.7; */
  border-radius: 50%;
  color: #ffffff;
}

[class*=bg-color-gray-shade] .modal-dialog__close i::before,
.bg-color-black .modal-dialog__close i::before {
  color: #999999;
  margin: 0;
}

[class*=bg-color-gray-shade] .modal-dialog__close:hover i::before,
.bg-color-black .modal-dialog__close:hover i::before {
  color: #ffffff;
}

.multimedia-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: 1.66667rem 0;
  overflow-x: hidden;
}

@media only screen and (min-width: 36em) {
  .multimedia-container {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}

.multimedia-container__item.multimedia-container__item.multimedia-container__item {
  margin: 0 0.83333rem;
  display: table;
}

.multimedia-container__link {
  position: relative;
  display: block;
}

.multimedia-container__height-control {
  max-height: none;
  max-width: 100%;
}

@media only screen and (min-width: 36em) {
  .multimedia-container__height-control {
    max-height: 48vw;
  }
}

@media only screen and (min-width: 62em) {
  .multimedia-container__height-control {
    max-height: 480px;
  }
}

@media only screen and (max-height: 49em) {
  .multimedia-container__height-control {
    max-height: 380px;
  }
}

.multimedia-container__caption {
  display: table-caption;
  caption-side: bottom;
  background-color: #ededed;
  min-height: 5.7em;
  padding: 1rem;
  color: #1a1a1a;
}

@media only screen and (min-width: 36em) {
  .multimedia-container__caption {
    min-height: 7.3em;
    min-height: 8.498em;
    min-height: 9.9em;
    padding: 1.66667rem;
  }
}

.multimedia-container__controls {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
  margin: 1.66667rem 0;
  text-align: center;
}

.multimedia-container__dots {
  display: inline-block;
  position: relative;
}

.multimedia-container:not(.slick-initialized) .multimedia-container__item:not(:first-child) {
  display: none;
}

@media only screen and (min-width: 36em) {
  .multimedia-container:not(.slick-initialized) .multimedia-container__height-control {
    height: 48vw;
  }
}

@media only screen and (min-width: 62em) {
  .multimedia-container:not(.slick-initialized) .multimedia-container__height-control {
    height: 480px;
  }
}

.multimedia-container .multimedia-container__height-control[data-lazy] {
  background: #ededed;
  display: block;
  width: 95vw;
}

@media only screen and (min-width: 36em) {
  .multimedia-container .multimedia-container__height-control[data-lazy] {
    height: 48vw;
    width: auto;
    min-width: 15em;
  }
}

@media only screen and (min-width: 48em) {
  .multimedia-container .multimedia-container__height-control[data-lazy] {
    min-width: 21em;
  }
}

@media only screen and (min-width: 62em) {
  .multimedia-container .multimedia-container__height-control[data-lazy] {
    height: 480px;
    min-width: 26em;
  }
}

.multimedia-container .slick-slide {
  cursor: pointer;
}

@media only screen and (min-width: 36em) {
  .multimedia-container .slick-slide {
    opacity: .3;
    -webkit-transition: opacity 0.16s linear;
    transition: opacity 0.16s linear;
  }
}

.multimedia-container .slick-current {
  opacity: 1;
  cursor: default;
}

.multimedia-container .slick-arrow {
  min-width: auto;
  display: none !important;
}

@media only screen and (min-width: 48em) {
  .multimedia-container .slick-arrow {
    display: block !important;
  }
}

.multimedia-container .slick-dots {
  position: static;
  margin-top: 0;
}

.multimedia-container__height-control--hidden {
  display: none;
}

.multimedia-container .flowplayer {
  display: block;
  pointer-events: none;
}

.multimedia-container .flowplayer:not(.is-fullscreen) .fp-player {
  position: relative;
}

.multimedia-container .flowplayer .fp-ratio {
  display: none;
}

.multimedia-container .flowplayer .multimedia-container__height-control--hidden {
  display: block;
  visibility: hidden;
}

.multimedia-container .multimedia-container__link {
  pointer-events: none;
}

.multimedia-container .slick-current .flowplayer,
.multimedia-container .slick-current .multimedia-container__link {
  pointer-events: auto;
}

.navigation-bar {
  background-color: #ededed;
  -webkit-box-shadow: 0 4px 0.4rem rgba(27, 27, 27, 0.2);
          box-shadow: 0 4px 0.4rem rgba(27, 27, 27, 0.2);
}

.navigation-bar__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0 auto;
  max-width: 100%;
  height: 50px;
  padding-right: 0.833rem;
  padding-left: 0.833rem;
}

@media only screen and (min-width: 36em) {
  .navigation-bar__container {
    width: 30em;
  }
}

@media only screen and (min-width: 48em) {
  .navigation-bar__container {
    width: 40em;
  }
}

@media only screen and (min-width: 62em) {
  .navigation-bar__container {
    width: 53.333em;
  }
}

@media only screen and (min-width: 75em) {
  .navigation-bar__container {
    width: 80em;
  }
}

.navigation-bar__panel {
  background-color: #ededed;
}

.navigation-bar__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.navigation-bar__toggle-button {
  color: #666666;
  width: 2.1111rem;
  height: 44px;
  margin-right: 1.11111rem;
}

.navigation-bar__toggle-button [class^=icon]::before {
  font-size: 1.66667rem;
  position: relative;
  top: 3px;
}

.navigation-bar__toggle-button:hover {
  color: #1a1a1a;
}

@media only screen and (min-width: 48em) {
  .navigation-bar__toggle-button {
    display: none;
  }
}

.navigation-bar__app-identifier {
  font-family: Calibri, sans-serif !important;
  font-size: 0.8889rem;
  color: #1a1a1a;
}

@media only screen and (min-width: 48em) {
  .navigation-bar__app-identifier {
    display: none;
  }
}

.navigation-bar__list {
  display: none;
  list-style: none;
  background-color: #ededed;
}

@media only screen and (min-width: 48em) {
  .navigation-bar__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    white-space: nowrap;
    margin: 0 -1.11111rem;
    padding: 0;
  }
}

.navigation-bar__additional-functions {
  -webkit-box-flex: 0;
      -ms-flex: none;
          flex: none;
  margin-left: auto;
}

.navigation-bar__item {
  display: block;
  margin: 0;
  padding: 0;
}

.navigation-bar__link {
  color: #666666;
  font-family: Calibri, sans-serif !important;
  display: block;
  position: relative;
  margin: 0;
  padding: 0.55556rem 1.11111rem 0.55556rem;
  font-size: 0.88889rem;
  line-height: 1.66667rem;
  font-weight: 600;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  white-space: nowrap;
}

.navigation-bar__link:focus {
  color: #666666;
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.navigation-bar__link:focus::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-left: 2px solid rgba(236, 102, 2, 0.5);
}

@media only screen and (min-width: 48em) {
  .navigation-bar__link:focus::after {
    content: "";
    bottom: 0;
    border-left: none;
    border-bottom: 2px solid rgba(236, 102, 2, 0.5);
  }
}

.navigation-bar__link:hover, .navigation-bar__link:active {
  color: #1a1a1a;
}

.navigation-bar__link:hover::after, .navigation-bar__link:active::after {
  border-left: none;
}

@media only screen and (min-width: 48em) {
  .navigation-bar__link:hover::after, .navigation-bar__link:active::after {
    border-bottom: none;
  }
}

.navigation-bar__link.is-active {
  color: #1a1a1a;
}

@media only screen and (min-width: 48em) {
  .navigation-bar__link.is-active::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-bottom: 2px solid #ec6602;
  }
}

.navigation-bar__dropdown-panel {
  margin-top: 0;
}

.navigation-bar__dropdown-item.is-active {
  background-color: #ededed;
}

.navigation-bar__dropdown-item:focus {
  color: #000000;
  background-color: #e6e6e6;
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.navigation-bar__dropdown-item:hover, .navigation-bar__dropdown-item:active {
  background-color: #ededed;
}

@media only screen and (max-width: 47.94444em) {
  .navigation-bar.is-open .navigation-bar__container {
    position: fixed;
    z-index: 2222;
    background-color: #ededed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .navigation-bar.is-open .navigation-bar__panel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    -webkit-box-shadow: 0 4px 0.4rem rgba(27, 27, 27, 0.2);
            box-shadow: 0 4px 0.4rem rgba(27, 27, 27, 0.2);
    z-index: 2223;
  }
  .navigation-bar.is-open .navigation-bar__wrapper {
    margin: 0 auto;
    max-width: 100%;
    padding-right: .833rem;
    padding-left: .833rem;
  }
}

@media only screen and (max-width: 47.94444em) and (min-width: 36em) {
  .navigation-bar.is-open .navigation-bar__wrapper {
    width: 30em;
  }
}

@media only screen and (max-width: 47.94444em) and (min-width: 48em) {
  .navigation-bar.is-open .navigation-bar__wrapper {
    width: 40em;
  }
}

@media only screen and (max-width: 47.94444em) and (min-width: 62em) {
  .navigation-bar.is-open .navigation-bar__wrapper {
    width: 53.333em;
  }
}

@media only screen and (max-width: 47.94444em) and (min-width: 75em) {
  .navigation-bar.is-open .navigation-bar__wrapper {
    width: 80em;
  }
}

@media only screen and (max-width: 47.94444em) {
  .navigation-bar.is-open .navigation-bar__toggle-button ::before {
    content: '\e817';
  }
  .navigation-bar.is-open .navigation-bar__list {
    position: absolute;
    top: 44px;
    left: 0;
    width: 100%;
    bottom: 0;
    display: block;
    padding-top: 0.83333rem;
  }
  .navigation-bar.is-open .navigation-bar__item {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    padding-right: 0.83333rem;
    padding-left: 1.83332rem;
  }
}

@media only screen and (max-width: 47.94444em) and (min-width: 36em) {
  .navigation-bar.is-open .navigation-bar__item {
    width: 30em;
  }
}

@media only screen and (max-width: 47.94444em) and (min-width: 48em) {
  .navigation-bar.is-open .navigation-bar__item {
    width: 40em;
  }
}

@media only screen and (max-width: 47.94444em) and (min-width: 62em) {
  .navigation-bar.is-open .navigation-bar__item {
    width: 53.333em;
  }
}

@media only screen and (max-width: 47.94444em) and (min-width: 75em) {
  .navigation-bar.is-open .navigation-bar__item {
    width: 80em;
  }
}

@media only screen and (max-width: 47.94444em) {
  .navigation-bar.is-open .navigation-bar__link {
    font-size: 0.8889rem;
  }
  .navigation-bar.is-open .navigation-bar__link.is-active {
    border: none;
  }
  .navigation-bar.is-open .navigation-bar__additional-functions {
    display: none;
  }
}

.navigation-bar--dark {
  background-color: #1a1a1a;
}

.navigation-bar--dark .navigation-bar__panel {
  background-color: #1a1a1a;
}

.navigation-bar--dark .navigation-bar__list {
  background-color: #1a1a1a;
}

.navigation-bar--dark .navigation-bar__link {
  color: #999999;
}

.navigation-bar--dark .navigation-bar__link:hover, .navigation-bar--dark .navigation-bar__link:active {
  color: #e6e6e6;
}

.navigation-bar--dark .navigation-bar__link.is-active {
  color: #e6e6e6;
}

.navigation-bar--dark .navigation-bar__toggle-button {
  color: #999999;
}

.navigation-bar--dark .navigation-bar__toggle-button:hover {
  color: #cccccc;
}

.navigation-bar--dark .navigation-bar__app-identifier {
  color: #999999;
}

.overlay {
  display: none;
  z-index: 4444;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  outline: 0;
  background-color: rgba(26, 26, 26, 0.5);
  overflow-x: hidden;
  overflow-y: auto;
}

.overlay.is-open {
  display: block;
}

.overlay__container {
  position: relative;
  background-color: #ffffff;
  border-radius: 0;
  overflow: hidden;
  -webkit-box-shadow: 0 4px 8px rgba(0, 0, 0, .1), 0 -1px 4px rgba(0, 0, 0, .05);
          box-shadow: 0 4px 8px rgba(0, 0, 0, .1), 0 -1px 4px rgba(0, 0, 0, .05);
  display: block;
  height: 100%;
}

@media only screen and (min-width: 48em) {
  .overlay__container {
    height: inherit;
    max-width: 600px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
}

.overlay__close {
  position: absolute;
  right: 1.11111rem;
  top: 1.11111rem;
  z-index: 4445;
}

.overlay__body {
  padding: 0;
}

/* .quotation {
  width: 100%;
} */

/* .quotation--right-aligned .media-object {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
} */

/* .quotation--right-aligned .quotation__image {
  margin-right: 0;
  margin-left: 1.11111rem;
} */

@media only screen and (max-width: 47.94444em) {
  .media-object--stacked-sm .quotation__image {
    margin-bottom: 1.66667rem;
  }
}

/* .quotation__text {
  color: #1a1a1a;
  font-size: 1.33333rem;
  line-height: 1.94444rem;
} */

/* .quotation__cite {
  color: #000000;
  font-size: 0.77778rem;
  line-height: 1.11111rem;
} */

/* .quotation__author {
  display: block;
  line-height: 1;
  font-weight: bold;
} */

.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 0.8889rem;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.pagination__item {
  color: #666666;
  width: 30px;
  height: 30px;
  margin-right: 0.05556rem;
  border-radius: 99rem;
  text-align: center;
  -webkit-transition: all 0.16s linear;
  transition: all 0.16s linear;
}

.pagination__item:not([href]) {
  background-color: #e6e6e6;
  color: #666666;
  font-weight: 600;
}

.pagination__item[href] {
  background-color: transparent;
  /*
  Override focus styles for mouse users ONLY if
  :focus-visible is supported on this platform.
  */
}

.pagination__item[href]:hover {
  background-color: #f2f2f2;
  color: #666666;
}

.pagination__item[href]:hover:not(:focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.pagination__item:first-child, .pagination__item:last-child {
  font-weight: 400;
  background-color: transparent;
}

.pagination__item:first-child:hover, .pagination__item:last-child:hover {
  color: #1a1a1a;
  background-color: transparent;
}

.pagination__item:first-child:not([href]), .pagination__item:last-child:not([href]) {
  opacity: 0.4;
  pointer-events: none;
}

.quotation {
  width: 100%;
}

.quotation--right-aligned .media-object {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}

.quotation--right-aligned .quotation__image {
  margin-right: 0;
  margin-left: 1.11111rem;
}

@media only screen and (max-width: 47.94444em) {
  .media-object--stacked-sm .quotation__image {
    margin-bottom: 1.66667rem;
  }
}

.quotation__text {
  color: #1a1a1a;
  font-size: 1.33333rem;
  line-height: 1.94444rem;
}

.quotation__cite {
  color: #000000;
  font-size: 0.77778rem;
  line-height: 1.11111rem;
}

.quotation__author {
  display: block;
  line-height: 1;
  font-weight: bold;
}

.progress__status {
  font-weight: 800;
  font-size: 1.11111rem;
  color: #1a1a1a;
  line-height: 1.66667rem;
  width: 100%;
  text-align: center;
  letter-spacing: 0;
  /* line-height: 1.11111rem; */
  margin-bottom: 0.55556rem;
}

.progress__bar-container {
  width: 100%;
  background-color: #e6e6e6;
  border-radius: 5px;
}

.progress__bar {
  border-radius: 5px 0 0 5px;
  background-color: #ec6602;
  -webkit-transition: width 0.2s linear;
  transition: width 0.2s linear;
  text-align: right;
  padding-right: .5em;
  color: inherit;
  white-space: nowrap;
  overflow: hidden;
  height: 10px;
}

.progress__additional-text {
  font-size: 0.77778rem;
  color: #666666;
  line-height: 1.11111rem;
  letter-spacing: 0;
  margin-top: 0.55556rem;
}

.progress--tiny {
  font-size: 2px;
  color: #ec6602;
}

.radio {
  opacity: 0;
  width: 1.11111rem;
  height: 1.11111rem;
  position: absolute;
}

.radio + label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  width: auto;
  padding-left: 0.83333rem;
  margin: 0 0 0 1.11111rem;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.radio + label::before {
  line-height: 1;
  content: "";
  display: inline-block;
  width: 1.11111rem;
  height: 1.11111rem;
  position: absolute;
  left: -1.11111rem;
  background-color: #ffffff;
  border: 1px solid #666666;
  top: 0.3rem;
  border-radius: 50%;
}

.radio:hover + label::before {
  background-color: #f2f2f2;
}

.radio:checked + label::before {
  border: 6px solid #ec6602;
}

.radio:focus + label::before {
  -webkit-box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
          box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
}

.radio:disabled + label {
  opacity: 0.4;
  pointer-events: none;
  cursor: default;
}

.radio.is-invalid + label::before {
  border-color: #e7001d;
}

.search-field {
  height: 42px;
}

.search-field > .icon-search {
  font-size: 1.22222rem;
}

.search-field__input {
  padding-top: 2px !important;
  font-size: 1rem;
  font-family: "Siemens Sans", "SH-Bree-Text", "Open Sans", "Roboto", "Droid Sans", "Segoe UI", -apple-system, BlinkMacSystemFont, sans-serif;
}

.search-field__autocomplete {
  position: absolute;
  top: 56px;
  left: 0;
  width: 100%;
  z-index: 8888;
  overflow: hidden;
  overflow-y: auto;
  max-height: 200px;
  display: none;
  background-color: #ffffff;
  -webkit-box-shadow: 0 4px 8px rgba(0, 0, 0, .1);
          box-shadow: 0 4px 8px rgba(0, 0, 0, .1);
  border: 1px solid #e6e6e6;
  border-top: none;
}

.search-field.has-results .search-field__autocomplete {
  display: block;
}

.search-field--outlined {
  border: 1px solid #b3b3b3;
  border-color: rgba(0, 0, 0, 0.24);
  border-radius: 4px !important;
  background-color: #ffffff !important;
}

.search-results__item {
  margin: 0;
}

.search-results__link {
  color: #1a1a1a;
}

.search-results__title {
  font-family: "Siemens Sans", "SH-Bree-Text", "Open Sans", "Roboto", "Droid Sans", "Segoe UI", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 800;
  font-size: 0.8889rem;
  line-height: 1.38889rem;
  margin-top: 1.11111rem;
  margin-bottom: 0;
}

.search-results__url {
  color: #666666;
  font-size: 0.77778rem;
  line-height: 1.11111rem;
  letter-spacing: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  text-align: left;
}

.search-results__snippet {
  margin-top: 0.5em;
  font-size: 0.77778rem;
  line-height: 1.11111rem;
}

.selectbox {
  font-size: 1rem;
  background-color: #ededed;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  display: inline-block;
  position: relative;
  height: 3.11111rem;
  width: 12.22222rem;
  margin-bottom: 1.66667rem;
  border-bottom: 1px solid #b3b3b3;
  cursor: pointer;
}

.selectbox::before {
  color: #666666;
  font-family: "SH Icon",sans-serif;
  font-style: normal !important;
  font-weight: 400 !important;
  font-variant: normal !important;
  font-size: 0.6em;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: baseline;
  content: "\e81f";
  position: absolute;
  right: 0.83333rem;
  top: 50%;
  -webkit-transform: translateY(-30%);
      -ms-transform: translateY(-30%);
          transform: translateY(-30%);
  pointer-events: none;
}

.selectbox:hover::before {
  color: #1a1a1a;
}

.selectbox__select {
  font-size: 1rem;
  color: #1a1a1a;
  font-family: Calibri, sans-serif !important;
  appearance: none;
  -ms-flex-item-align: end;
      align-self: flex-end;
  width: 100%;
  height: 100%;
  padding: 1.11111rem 0.83333rem 0;
  line-height: 1.2;
  border: none;
  background: transparent;
  pointer-events: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  /* appearance: none; */
}

.selectbox__select::-ms-value {
  background: none;
  /* remove blue background on ie10/ie11 when selected*/
  color: #1a1a1a;
}

.selectbox__select::-ms-expand {
  display: none;
}

.selectbox__select:focus {
  outline: none;
}

.selectbox__select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #1a1a1a;
}

.selectbox__select:-moz-focusring * {
  color: #1a1a1a;
  text-shadow: none;
}

.selectbox__label {
  font-size: 1rem;
  color: #666666;
  font-family: Calibri, sans-serif !important;
  bottom: 0.88889rem;
  cursor: text;
  font-weight: 400;
  left: 0.83333rem;
  letter-spacing: 0.00937em;
  line-height: 1.15rem;
  margin-bottom: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  right: auto;
  text-decoration: inherit;
  text-overflow: ellipsis;
  text-transform: inherit;
  -webkit-transform-origin: left top;
      -ms-transform-origin: left top;
          transform-origin: left top;
  -webkit-transition: color 0.15s cubic-bezier(0.4, 0, 0.2, 1), width 0.15s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  transition: color 0.15s cubic-bezier(0.4, 0, 0.2, 1), width 0.15s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.15s cubic-bezier(0.4, 0, 0.2, 1), color 0.15s cubic-bezier(0.4, 0, 0.2, 1), width 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.15s cubic-bezier(0.4, 0, 0.2, 1), color 0.15s cubic-bezier(0.4, 0, 0.2, 1), width 0.15s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap;
  width: calc(100% - to-rem(40));
  will-change: transform;
  -webkit-font-smoothing: antialiased;
}

.selectbox__hint {
  color: #666666;
  display: block;
  margin-bottom: 1.66667rem;
  padding: 0.38889rem 0.83333rem 0;
  font-size: 0.775rem;
  line-height: 1.11111rem;
}

.selectbox__option-list {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 8888;
  min-width: 100%;
  overflow: hidden;
  overflow-y: auto;
  max-height: 16.66667rem;
  background-color: #ffffff;
  -webkit-box-shadow: 0 4px 8px rgba(0, 0, 0, .1);
          box-shadow: 0 4px 8px rgba(0, 0, 0, .1);
  margin-top: 1px;
  border: 1px solid #e6e6e6;
}

.selectbox--large {
  width: 100%;
}

.selectbox.is-open .selectbox__option-list {
  display: block;
}

.selectbox.is-disabled {
  opacity: 0.4;
}

.selectbox.is-active .selectbox__label {
  -webkit-transform: translateY(-40%) scale(0.775);
      -ms-transform: translateY(-40%) scale(0.775);
          transform: translateY(-40%) scale(0.775);
  width: 100%;
}

.selectbox.has-focus {
  border-color: #1a1a1a;
}

.selectbox.has-focus::before {
  color: #1a1a1a;
}

.selectbox.is-valid {
  border-color: #009a38;
}

.selectbox.is-valid .selectbox__label {
  width: calc(100% - to-rem(60));
}

.selectbox.is-valid.is-active .selectbox__label {
  width: calc(100% - to-rem(60));
}

.selectbox.is-valid .selectbox__select {
  padding-right: 3.33333rem;
}

.selectbox.is-valid::after {
  font-family: "SH Icon",sans-serif;
  font-weight: normal !important;
  font-style: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  vertical-align: baseline;
  content: "";
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #009a38;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 1em;
  width: 1.66667rem;
  height: 1.66667rem;
  /* line-height: 1.7; */
  border-radius: 50%;
  color: #ffffff;
  position: absolute;
  left: auto;
  right: 1.66667rem;
  bottom: 0.66667rem;
  cursor: pointer;
}

.selectbox.is-invalid {
  border-color: #e7001d;
}

.selectbox.is-invalid .selectbox__label {
  width: calc(100% - to-rem(60));
}

.selectbox.is-invalid.is-active .selectbox__label {
  width: calc(100% - to-rem(60));
}

.selectbox.is-invalid .selectbox__select {
  padding-right: 3.33333rem;
  padding-left: 0.83333rem;
}

.selectbox.is-invalid::after {
  font-family: "SH Icon",sans-serif;
  font-weight: normal !important;
  font-style: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  vertical-align: baseline;
  content: "";
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #e7001d;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 1em;
  width: 1.66667rem;
  height: 1.66667rem;
  /* line-height: 1.7; */
  border-radius: 50%;
  color: #ffffff;
  position: absolute;
  left: auto;
  right: 1.66667rem;
  bottom: 0.66667rem;
  cursor: pointer;
}

.bg-color-gray-tint-5 .selectbox {
  background-color: #ffffff;
}

[class*=bg-color-gray-shade] .selectbox,
.bg-color-black .selectbox {
  background-color: #1a1a1a;
}

[class*=bg-color-gray-shade] .selectbox::before,
.bg-color-black .selectbox::before {
  color: #999999;
}

[class*=bg-color-gray-shade] .selectbox:hover::before,
.bg-color-black .selectbox:hover::before {
  color: #e6e6e6;
}

[class*=bg-color-gray-shade] .selectbox.has-focus,
.bg-color-black .selectbox.has-focus {
  border-color: #e6e6e6;
}

[class*=bg-color-gray-shade] .selectbox.has-focus::before,
.bg-color-black .selectbox.has-focus::before {
  color: #e6e6e6;
}

[class*=bg-color-gray-shade] .selectbox__option-list,
.bg-color-black .selectbox__option-list {
  background-color: #4d4d4d;
  color: #ffffff;
  border-color: #404040;
}

[class*=bg-color-gray-shade] .selectbox__label,
.bg-color-black .selectbox__label {
  color: #cccccc;
}

[class*=bg-color-gray-shade] .selectbox__select,
.bg-color-black .selectbox__select {
  color: #ffffff;
}

[class*=bg-color-gray-shade] .selectbox__hint,
.bg-color-black .selectbox__hint {
  color: #999999;
}

[class*=bg-color-gray-shade] .selectbox__option,
.bg-color-black .selectbox__option {
  background-color: #4d4d4d;
  color: #ffffff;
}

[class*=bg-color-gray-shade] .selectbox__option:hover,
.bg-color-black .selectbox__option:hover {
  background-color: #666666;
}

[class*=bg-color-gray-shade] .selectbox__option.is-selected,
.bg-color-black .selectbox__option.is-selected {
  background-color: #666666;
}

[class*=bg-color-gray-shade] .selectbox.is-valid,
.bg-color-black .selectbox.is-valid {
  border-color: #009a38;
}

[class*=bg-color-gray-shade] .selectbox.is-invalid,
.bg-color-black .selectbox.is-invalid {
  border-color: #e7001d;
}

.sitemap__link {
  color: #666666;
  text-decoration: none;
  /*
  Provide basic, default focus styles.
  */
  /*
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
  */
  /*
  Define a strong focus indicator for keyboard focus.
  */
  /*
  Remove default focus styles for mouse users ONLY if
  :focus-visible is supported on this platform.
  */
  /*
  If :focus-visible is supported on this
  platform, provide enhanced focus styles for keyboard
  focus.
  */
  font-size: 0.77778rem;
  line-height: 1.2857;
  padding: 0.5rem 0;
}

.sitemap__link:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
          box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
  outline: none;
  color: inherit;
}

.js-focus-visible .sitemap__link:focus:not(.focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}

.js-focus-visible .sitemap__link:focus.focus-visible {
  -webkit-box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
          box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
  outline: none;
  color: inherit;
}

.sitemap__link:focus:not(:focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}

.sitemap__link:focus-visible {
  -webkit-box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
          box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
  outline: none;
  color: inherit;
}

.sitemap__link:hover, .sitemap__link:active {
  color: #1a1a1a;
  text-decoration: none;
  /*
    Override focus styles for mouse users ONLY if
    :focus-visible is supported on this platform.
    */
}

.sitemap__link:hover:not(:focus-visible), .sitemap__link:active:not(:focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.sitemap__link:hover {
  color: #1a1a1a;
}

.sitemap__link i:before {
  color: inherit;
}

.sitemap__title {
  font-size: 0.8889rem;
  margin: 1.5rem 0 1.11111rem;
  color: #1a1a1a;
}

[class*=bg-color-gray-shade] .sitemap__link,
.bg-color-black .sitemap__link {
  color: #999999;
}

[class*=bg-color-gray-shade] .sitemap__link:hover,
.bg-color-black .sitemap__link:hover {
  color: #ffffff;
}

[class*=bg-color-gray-shade] .sitemap__link i:before,
.bg-color-black .sitemap__link i:before {
  color: inherit;
}

[class*=bg-color-gray-shade] .sitemap__title,
.bg-color-black .sitemap__title {
  margin: 1.5rem 0 1.11111rem;
  color: #e6e6e6;
}

.social-list {
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  font-size: 1.5em;
  list-style-type: none;
  margin: 0 -0.55556rem -1.11111rem 0;
  padding-left: 0;
}

.social-list__item {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  margin: 0 0.55556rem 1.11111rem 0;
}

.splash-screen {
  z-index: 4444;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  outline: 0;
  background-color: rgba(26, 26, 26, 0.5);
  overflow-x: hidden;
  overflow-y: auto;
}

.splash-screen.is-open {
  display: block;
}

.splash-screen__container {
  position: relative;
  background-color: #ffffff;
  border-radius: 0;
  overflow: hidden;
  -webkit-box-shadow: 0 4px 8px rgba(0, 0, 0, .1), 0 -1px 4px rgba(0, 0, 0, .05);
          box-shadow: 0 4px 8px rgba(0, 0, 0, .1), 0 -1px 4px rgba(0, 0, 0, .05);
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

@media only screen and (min-width: 48em) {
  .splash-screen__container {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    max-width: 1000px;
    max-height: 600px;
    height: inherit;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
}

.splash-screen__image {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
}

@media only screen and (min-width: 48em) {
  .splash-screen__image {
    min-width: 382px;
    width: 382px;
    height: 100%;
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
    background-color: #000000;
  }
}

.splash-screen__image [class*=aspect-ratio] {
  background-color: #000000;
}

.splash-screen__body {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
  padding: 1rem;
}

.splash-screen__body > *:first-child {
  margin-top: 0;
}

.splash-screen__body > *:last-child {
  margin-bottom: 0;
}

@media only screen and (min-width: 48em) {
  .splash-screen__body {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
    padding: 2rem;
    -ms-flex-item-align: center;
        align-self: center;
  }
}

.stage {
  width: 100%;
}

.stage-slide {
  position: relative;
  background-color: #000000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.stage-slide__content {
  width: 100%;
}

@media only screen and (min-width: 62em) {
  .stage-slide__content {
    position: absolute;
    bottom: 3.33333rem;
  }
}

.stage-slide__title {
  color: #ec6602;
  margin-top: 0;
  margin-bottom: 1rem;
}

.stage-slide__description {
  color: #ffffff;
  margin-top: 0;
  margin-bottom: 1.66667rem;
}

.stage-slide__pulse {
  margin: 1.66667rem 0;
  width: 100%;
}

@media only screen and (min-width: 62em) {
  .stage-slide__pulse {
    margin: 3.33333rem 0;
  }
}

.stage-slide__image-container {
  width: 100vw;
}

.stage-slide__image {
  background-size: cover;
  background-position: 50% 50%;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
}

@media only screen and (min-width: 62em) {
  .stage-slide__image {
    height: 460px;
    padding: 0;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50vw;
            flex: 0 0 50vw;
  }
  .stage-slide__image > img {
    display: none;
  }
}

@media only screen and (min-width: 75em) {
  .stage-slide__image {
    height: 600px;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 calc(50vw + 8.3333334%);
            flex: 0 0 calc(50vw + 8.3333334%);
  }
}

.stage-slide--white {
  background-color: #ffffff;
}

.stage-slide--white .stage-slide__description {
  color: #000000;
}

.steps-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.steps-bar__item {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 0%;
          flex: 1 1 0%;
  position: relative;
}

.steps-bar__item::before, .steps-bar__item::after {
  border: 1px dotted #e6e6e6;
  display: block;
  content: "";
  position: absolute;
  top: calc(1rem + 20px);
}

.steps-bar__item::after {
  left: calc(50% + 30px);
  right: 0;
}

.steps-bar__item::before {
  right: calc(50% + 30px);
  left: 1px;
}

.steps-bar__item:first-child::before {
  display: none;
}

.steps-bar__item:last-child::after {
  display: none;
}

.steps-bar__number {
  width: 40px;
  height: 40px;
  border: 1px solid #b3b3b3;
  border-radius: 40px;
  font-weight: 600;
  color: #666666;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 1rem auto;
  opacity: 0.4;
}

.steps-bar__title, .steps-bar__text {
  color: #666666;
  text-align: center;
  font-size: 0.8889rem;
  line-height: 20px;
  opacity: 0.4;
}

.steps-bar__title {
  font-weight: 600;
  line-height: 30px;
}

.steps-bar__item.is-completed .steps-bar__number {
  border-color: #e6e6e6;
  background-color: #e6e6e6;
  color: #666666;
  opacity: 1;
}

.steps-bar__item.is-completed .steps-bar__title,
.steps-bar__item.is-completed .steps-bar__text {
  color: #666666;
  opacity: 1;
}

.steps-bar__item.is-current .steps-bar__number {
  color: #ffffff;
  border-color: #ec6602;
  background-color: #ec6602;
  opacity: 1;
}

.steps-bar__item.is-current .steps-bar__title,
.steps-bar__item.is-current .steps-bar__text {
  color: #666666;
  opacity: 1;
}

.table {
  white-space: nowrap;
}

.table tr:hover {
  background-color: #f2f2f2;
}

.tabs__list {
  border-bottom: 1px solid #b3b3b3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 2rem;
  margin-left: -1.11111rem;
  position: relative;
  white-space: nowrap;
}

.tabs__item {
  color: #666666;
  cursor: pointer;
  display: inline-block;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  font-weight: 600;
  line-height: 1.66667rem;
  margin: 0;
  padding: 1.11111rem 1.11111rem;
  position: relative;
  text-align: center;
  white-space: nowrap;
}

.tabs__item:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #666666;
  outline: none;
}

.tabs__item:focus::after {
  border-bottom: 2px solid rgba(236, 102, 2, 0.5);
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  right: 0;
}

.tabs__item:hover, .tabs__item:active {
  color: inherit;
}

.tabs__item:hover::after, .tabs__item:active::after {
  border-bottom: none;
}

.tabs__item.is-active {
  color: inherit;
}

.tabs__panel {
  display: none;
}

.tabs__panel.is-active {
  display: block;
}

.tabs__dropdown-panel {
  color: #666666;
}

.tabs__dropdown-toggle {
  font-family: Calibri, sans-serif !important;
  font-size: inherit;
  font-weight: 600;
}

.is-open + .tabs__dropdown-toggle {
  color: #1a1a1a;
}

.tabs__active-line {
  background: #ec6602;
  bottom: 0;
  height: 2px;
  left: 1.11111rem;
  position: absolute;
  -webkit-transition: left 0.15s cubic-bezier(0.35, 0, 0.25, 1), width 0.15s cubic-bezier(0.35, 0, 0.25, 1);
  transition: left 0.15s cubic-bezier(0.35, 0, 0.25, 1), width 0.15s cubic-bezier(0.35, 0, 0.25, 1);
  will-change: left, width;
}

.tag {
  font-weight: 600;
  font-size: 0.77778rem;
  background-color: #ffffff;
  border-radius: 0.16667rem;
  border: 1px solid #b3b3b3;
  color: #333333;
  cursor: pointer;
  display: inline-block;
  font-family: Calibri, sans-serif !important;
  line-height: 1;
  margin: 0 0 1.25em;
  min-width: auto;
  padding: 0.38889rem 0.44444rem 0.38889rem 0.5rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  -webkit-transition: all 0.16s linear;
  transition: all 0.16s linear;
  -webkit-appearance: none;
  /*
  Override focus styles for mouse users ONLY if
  :focus-visible is supported on this platform.
  */
  /*
  Provide basic, default focus styles.
  */
  /*
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
  */
  /*
  Define a strong focus indicator for keyboard focus.
  */
  /*
  Remove default focus styles for mouse users ONLY if
  :focus-visible is supported on this platform.
  */
  /*
  If :focus-visible is supported on this
  platform, provide enhanced focus styles for keyboard
  focus.
  */
}

.tag::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.tag .icon::before {
  color: #666666;
  content: '\e82d';
  display: inline-block;
  font-size: 0.66667rem;
  margin-left: 0.5em;
  position: relative;
  top: -0.05556rem;
  -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-transition: all 0.16s linear;
  transition: all 0.16s linear;
}

.tag:hover {
  background-color: #f2f2f2;
}

.tag:hover .icon::before {
  color: #1a1a1a;
}

.tag:hover:not(:focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.tag:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
          box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
  outline: none;
  padding: 0.38889rem 0.44444rem 0.38889rem 0.5rem;
  margin: 0 0 1.25em;
}

.js-focus-visible .tag:focus:not(.focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}

.js-focus-visible .tag:focus.focus-visible {
  -webkit-box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
          box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
  outline: none;
  padding: 0.38889rem 0.44444rem 0.38889rem 0.5rem;
  margin: 0 0 1.25em;
}

.tag:focus:not(:focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}

.tag:focus-visible {
  -webkit-box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
          box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
  outline: none;
  padding: 0.38889rem 0.44444rem 0.38889rem 0.5rem;
  margin: 0 0 1.25em;
}

.tag:active {
  background-color: #f2f2f2;
  /*
    Remove default focus styles for mouse users ONLY if
    :focus-visible is supported on this platform.
    */
}

.tag:active:not(:focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.tag.is-selected {
  color: #333333;
  background-color: #e6e6e6;
  border-color: #b3b3b3;
  /*
  Override focus styles for mouse users ONLY if
  :focus-visible is supported on this platform.
  */
  /*
  Provide basic, default focus styles.
  */
  /*
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
  */
  /*
  Define a strong focus indicator for keyboard focus.
  */
  /*
  Remove default focus styles for mouse users ONLY if
  :focus-visible is supported on this platform.
  */
  /*
  If :focus-visible is supported on this
  platform, provide enhanced focus styles for keyboard
  focus.
  */
}

.tag.is-selected:hover {
  background-color: #dbdbdb;
}

.tag.is-selected:hover:not(:focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.tag.is-selected:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
          box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
  outline: none;
}

.js-focus-visible .tag.is-selected:focus:not(.focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}

.js-focus-visible .tag.is-selected:focus.focus-visible {
  -webkit-box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
          box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
  outline: none;
}

.tag.is-selected:focus:not(:focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}

.tag.is-selected:focus-visible {
  -webkit-box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
          box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
  outline: none;
}

.tag.is-selected:active {
  background-color: #dbdbdb;
  /*
    Remove default focus styles for mouse users ONLY if
    :focus-visible is supported on this platform.
    */
}

.tag.is-selected:active:not(:focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.tag.is-selected .icon::before {
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
}

.tag.is-disabled {
  opacity: 0.4;
}

[class*=bg-color-gray-shade] .tag,
.bg-color-black .tag {
  color: #e6e6e6;
  background-color: #000000;
  border-color: #4d4d4d;
  /*
  Override focus styles for mouse users ONLY if
  :focus-visible is supported on this platform.
  */
  /*
  Provide basic, default focus styles.
  */
  /*
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
  */
  /*
  Define a strong focus indicator for keyboard focus.
  */
  /*
  Remove default focus styles for mouse users ONLY if
  :focus-visible is supported on this platform.
  */
  /*
  If :focus-visible is supported on this
  platform, provide enhanced focus styles for keyboard
  focus.
  */
}

[class*=bg-color-gray-shade] .tag .icon::before,
.bg-color-black .tag .icon::before {
  color: #ffffff;
}

[class*=bg-color-gray-shade] .tag:hover,
.bg-color-black .tag:hover {
  background-color: #262626;
}

[class*=bg-color-gray-shade] .tag:hover:not(:focus-visible),
.bg-color-black .tag:hover:not(:focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
}

[class*=bg-color-gray-shade] .tag:focus,
.bg-color-black .tag:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
          box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
  outline: none;
}

.js-focus-visible [class*=bg-color-gray-shade] .tag:focus:not(.focus-visible), .js-focus-visible
.bg-color-black .tag:focus:not(.focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}

.js-focus-visible [class*=bg-color-gray-shade] .tag:focus.focus-visible, .js-focus-visible
.bg-color-black .tag:focus.focus-visible {
  -webkit-box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
          box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
  outline: none;
}

[class*=bg-color-gray-shade] .tag:focus:not(:focus-visible),
.bg-color-black .tag:focus:not(:focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}

[class*=bg-color-gray-shade] .tag:focus-visible,
.bg-color-black .tag:focus-visible {
  -webkit-box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
          box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
  outline: none;
}

[class*=bg-color-gray-shade] .tag:active,
.bg-color-black .tag:active {
  background-color: #262626;
  /*
    Remove default focus styles for mouse users ONLY if
    :focus-visible is supported on this platform.
    */
}

[class*=bg-color-gray-shade] .tag:active:not(:focus-visible),
.bg-color-black .tag:active:not(:focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
}

[class*=bg-color-gray-shade] .tag.is-selected,
.bg-color-black .tag.is-selected {
  color: #ffffff;
  background-color: #333333;
  /*
  Override focus styles for mouse users ONLY if
  :focus-visible is supported on this platform.
  */
  /*
  Provide basic, default focus styles.
  */
  /*
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
  */
  /*
  Define a strong focus indicator for keyboard focus.
  */
  /*
  Remove default focus styles for mouse users ONLY if
  :focus-visible is supported on this platform.
  */
  /*
  If :focus-visible is supported on this
  platform, provide enhanced focus styles for keyboard
  focus.
  */
}

[class*=bg-color-gray-shade] .tag.is-selected:hover,
.bg-color-black .tag.is-selected:hover {
  background-color: #525252;
}

[class*=bg-color-gray-shade] .tag.is-selected:hover:not(:focus-visible),
.bg-color-black .tag.is-selected:hover:not(:focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
}

[class*=bg-color-gray-shade] .tag.is-selected:focus,
.bg-color-black .tag.is-selected:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
          box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
  outline: none;
}

.js-focus-visible [class*=bg-color-gray-shade] .tag.is-selected:focus:not(.focus-visible), .js-focus-visible
.bg-color-black .tag.is-selected:focus:not(.focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}

.js-focus-visible [class*=bg-color-gray-shade] .tag.is-selected:focus.focus-visible, .js-focus-visible
.bg-color-black .tag.is-selected:focus.focus-visible {
  -webkit-box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
          box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
  outline: none;
}

[class*=bg-color-gray-shade] .tag.is-selected:focus:not(:focus-visible),
.bg-color-black .tag.is-selected:focus:not(:focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}

[class*=bg-color-gray-shade] .tag.is-selected:focus-visible,
.bg-color-black .tag.is-selected:focus-visible {
  -webkit-box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
          box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
  outline: none;
}

[class*=bg-color-gray-shade] .tag.is-selected:active,
.bg-color-black .tag.is-selected:active {
  background-color: #525252;
  /*
    Remove default focus styles for mouse users ONLY if
    :focus-visible is supported on this platform.
    */
}

[class*=bg-color-gray-shade] .tag.is-selected:active:not(:focus-visible),
.bg-color-black .tag.is-selected:active:not(:focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
}

/* .textfield {
  background-color: #ededed;
  display: inline-block;
  position: relative;
  height: 3.11111rem;
  width: 12.22222rem;
  margin-bottom: 1.66667rem;
  border-bottom: 1px solid #666666;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
} */

.textfield__input {
  font-size: 1rem;
  color: #1a1a1a;
  font-family: Calibri, sans-serif !important;
     -moz-appearance: none;
          appearance: none;
  -ms-flex-item-align: end;
      align-self: flex-end;
  width: 100%;
  height: 100%;
  padding: 20px 0.83333rem 0;
  border: none;
  background-color: transparent;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.textfield__input::-ms-clear {
  display: none;
}

.textfield__input::-webkit-input-placeholder {
  color: #666666;
}

.textfield__input::-moz-placeholder {
  color: #666666;
}

.textfield__input:-ms-input-placeholder {
  color: #666666;
}

.textfield__input::-ms-input-placeholder {
  color: #666666;
}

.textfield__input::placeholder {
  color: #666666;
}

.textfield__input:focus {
  outline: none;
}

.textfield__label {
  font-size: 1rem;
  color: #666666;
  font-family: Calibri, sans-serif !important;
  left: 0.83333rem;
  right: auto;
  position: absolute;
  bottom: 17px;
  width: calc(100% - 34px);
  margin-bottom: 0;
  font-weight: 400;
  letter-spacing: 0.00937em;
  line-height: 1.15rem;
  cursor: text;
  text-decoration: inherit;
  text-transform: inherit;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  overflow: hidden;
  will-change: transform;
  -webkit-font-smoothing: antialiased;
  -webkit-transform-origin: left top;
      -ms-transform-origin: left top;
          transform-origin: left top;
  -webkit-transition: color 0.15s cubic-bezier(0.4, 0, 0.2, 1), width 0.15s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  transition: color 0.15s cubic-bezier(0.4, 0, 0.2, 1), width 0.15s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.15s cubic-bezier(0.4, 0, 0.2, 1), color 0.15s cubic-bezier(0.4, 0, 0.2, 1), width 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.15s cubic-bezier(0.4, 0, 0.2, 1), color 0.15s cubic-bezier(0.4, 0, 0.2, 1), width 0.15s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.textfield__hint {
  color: #666666;
  display: block;
  margin-bottom: 1.66667rem;
  padding: 0.38889rem 0.83333rem 0;
  font-size: 0.775rem;
  line-height: 1.11111rem;
}

.textfield--with-prefix .textfield__prefix {
  color: #666666;
  display: block;
}

.textfield--with-prefix .textfield__label {
  width: calc(100% - 65px);
  left: 48px;
}

.textfield--with-prefix .textfield__input {
  padding-left: 48px;
}

.textfield--with-postfix .textfield__postfix {
  display: block;
}

.textfield--with-postfix .textfield__label {
  width: calc(100% - 65px);
}

.textfield--with-postfix .textfield__input {
  padding-right: 48px;
}

.textfield--with-prefix.textfield--with-postfix .textfield__label {
  width: calc(100% - 96px);
}

.textfield--with-prefix.textfield--with-postfix .textfield__input {
  padding-left: 48px;
  padding-right: 48px;
}

.textfield--large {
  width: 100%;
}

.textfield__prefix {
  left: 15px;
  right: auto;
  position: absolute;
  bottom: 14px;
  cursor: default;
  pointer-events: none;
  display: none;
}

.textfield__postfix {
  left: auto;
  right: 15px;
  cursor: default;
  pointer-events: none;
  position: absolute;
  bottom: 14px;
  /* cursor: pointer; */
  display: none;
}

.textfield::after {
  font-family: "SH Icon",sans-serif;
  font-weight: normal !important;
  font-style: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  vertical-align: baseline;
  /* content: ""; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: transparent;
  background-color: #cccccc;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 1em;
  width: 1.66667rem;
  height: 1.66667rem;
  /* line-height: 1.7; */
  border-radius: 50%;
  color: #000000;
  content: none;
  position: absolute;
  left: auto;
  right: 0.83333rem;
  bottom: 0.66667rem;
  /* color: #cccccc; */
  cursor: pointer;
}

.textfield.is-disabled {
  opacity: 0.4;
  pointer-events: none;
}

.textfield.is-active .textfield__label {
  -webkit-transform: translateY(-40%) scale(0.775);
      -ms-transform: translateY(-40%) scale(0.775);
          transform: translateY(-40%) scale(0.775);
}

.textfield.has-focus {
  border-color: #1a1a1a;
}

.textfield.has-focus .textfield__prefix {
  color: #1a1a1a;
}

.textfield.is-valid {
  border-color: #009a38;
}

.textfield.is-valid .textfield__label {
  width: calc(100% - 77px);
}

.textfield.is-valid.textfield--with-prefix .textfield__label {
  width: calc(100% - 108px);
}

.textfield.is-valid .textfield__input {
  padding-right: 60px;
}

.textfield.is-valid::after {
  content: "";
  background-color: #009a38;
  color: #ffffff;
}

.textfield.is-invalid {
  border-color: #e7001d;
}

.textfield.is-invalid .textfield__label {
  width: calc(100% - 77px);
}

.textfield.is-invalid.textfield--with-prefix .textfield__label {
  width: calc(100% - 108px);
}

.textfield.is-invalid .textfield__input {
  padding-right: 48px;
}

.textfield.is-invalid::after {
  content: "";
  background-color: #e7001d;
  color: #ffffff;
}

.bg-color-gray-tint-5 .textfield {
  background-color: #ffffff;
}

[class*=bg-color-gray-shade] .textfield,
.bg-color-black .textfield {
  background-color: #1a1a1a;
  border-bottom: 1px solid #999999;
}

[class*=bg-color-gray-shade] .textfield__label,
.bg-color-black .textfield__label {
  color: #999999;
}

[class*=bg-color-gray-shade] .textfield__input,
.bg-color-black .textfield__input {
  color: #e6e6e6;
}

[class*=bg-color-gray-shade] .textfield__input::-webkit-input-placeholder, .bg-color-black .textfield__input::-webkit-input-placeholder {
  color: #999999;
}

[class*=bg-color-gray-shade] .textfield__input::-moz-placeholder, .bg-color-black .textfield__input::-moz-placeholder {
  color: #999999;
}

[class*=bg-color-gray-shade] .textfield__input:-ms-input-placeholder, .bg-color-black .textfield__input:-ms-input-placeholder {
  color: #999999;
}

[class*=bg-color-gray-shade] .textfield__input::-ms-input-placeholder, .bg-color-black .textfield__input::-ms-input-placeholder {
  color: #999999;
}

[class*=bg-color-gray-shade] .textfield__input::placeholder,
.bg-color-black .textfield__input::placeholder {
  color: #999999;
}

[class*=bg-color-gray-shade] .textfield__hint,
.bg-color-black .textfield__hint {
  color: #999999;
}

[class*=bg-color-gray-shade] .textfield.has-focus,
.bg-color-black .textfield.has-focus {
  border-color: #e6e6e6;
}

[class*=bg-color-gray-shade] .textfield.has-focus .textfield__prefix,
.bg-color-black .textfield.has-focus .textfield__prefix {
  color: #ffffff;
}

.textarea {
  background-color: #ededed;
  display: inline-block;
  position: relative;
  width: 12.22222rem;
  margin-bottom: 1.66667rem;
  border-bottom: 1px solid #666666;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.textarea__input {
  font-size: 1rem;
  color: #1a1a1a;
  font-family: Calibri, sans-serif !important;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  -ms-flex-item-align: end;
      align-self: flex-end;
  width: 100%;
  min-height: 56px;
  height: 56px;
  padding: 20px 0.83333rem 0;
  border: none;
  background-color: transparent;
  resize: none;
  overflow: hidden;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: block;
}

.textarea__input::-webkit-input-placeholder {
  color: #666666;
}

.textarea__input::-moz-placeholder {
  color: #666666;
}

.textarea__input:-ms-input-placeholder {
  color: #666666;
}

.textarea__input::-ms-input-placeholder {
  color: #666666;
}

.textarea__input::placeholder {
  color: #666666;
}

.textarea__input:focus {
  outline: none;
}

.textarea__label {
  font-size: 1rem;
  color: #666666;
  font-family: Calibri, sans-serif !important;
  left: 0.83333rem;
  right: auto;
  position: absolute;
  top: 19px;
  width: calc(100% - 34px);
  margin-bottom: 0;
  font-weight: 400;
  letter-spacing: 0.00937em;
  line-height: 1.15rem;
  cursor: text;
  text-decoration: inherit;
  text-transform: inherit;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  overflow: hidden;
  will-change: transform;
  -webkit-font-smoothing: antialiased;
  -webkit-transform-origin: left top;
      -ms-transform-origin: left top;
          transform-origin: left top;
  -webkit-transition: color 0.15s cubic-bezier(0.4, 0, 0.2, 1), width 0.15s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  transition: color 0.15s cubic-bezier(0.4, 0, 0.2, 1), width 0.15s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.15s cubic-bezier(0.4, 0, 0.2, 1), color 0.15s cubic-bezier(0.4, 0, 0.2, 1), width 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.15s cubic-bezier(0.4, 0, 0.2, 1), color 0.15s cubic-bezier(0.4, 0, 0.2, 1), width 0.15s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.15s cubic-bezier(0.4, 0, 0.2, 1);
}

.textarea__hint {
  color: #666666;
  display: block;
  margin-bottom: 1.66667rem;
  padding: 0.38889rem 0.83333rem 0;
  font-size: 0.775rem;
  line-height: 1.11111rem;
}

.textarea--with-prefix .textarea__prefix {
  display: block;
}

.textarea--with-prefix .textarea__label {
  width: calc(100% - 65px);
  left: 48px;
}

.textarea--with-prefix .textarea__input {
  padding-left: 48px;
}

.textarea--with-postfix .textarea__postfix {
  display: block;
}

.textarea--with-postfix .textarea__label {
  width: calc(100% - 65px);
}

.textarea--with-postfix .textarea__input {
  padding-right: 48px;
}

.textarea--with-prefix.textarea--with-postfix .textarea__label {
  width: calc(100% - 96px);
}

.textarea--with-prefix.textarea--with-postfix .textarea__input {
  padding-left: 48px;
  padding-right: 48px;
}

.textarea--large {
  width: 100%;
}

.textarea__prefix {
  left: 15px;
  right: auto;
  position: absolute;
  bottom: 14px;
  cursor: default;
  pointer-events: none;
  display: none;
}

.textarea__postfix {
  left: auto;
  right: 15px;
  cursor: default;
  pointer-events: none;
  position: absolute;
  bottom: 14px;
  /* cursor: pointer; */
  display: none;
}

.textarea::after {
  font-family: "SH Icon",sans-serif;
  font-weight: normal !important;
  font-style: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  vertical-align: baseline;
  /* content: ""; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: transparent;
  background-color: #cccccc;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 1em;
  width: 1.66667rem;
  height: 1.66667rem;
  /* line-height: 1.7; */
  border-radius: 50%;
  color: #000000;
  content: none;
  position: absolute;
  left: auto;
  right: 0.83333rem;
  bottom: 0.66667rem;
  /* color: #cccccc; */
  cursor: pointer;
}

.textarea.is-disabled {
  opacity: 0.4;
  pointer-events: none;
}

.textarea.is-active .textarea__label {
  -webkit-transform: translateY(-45%) scale(0.775);
      -ms-transform: translateY(-45%) scale(0.775);
          transform: translateY(-45%) scale(0.775);
}

.textarea.has-focus {
  border-color: #1a1a1a;
}

.textarea.has-focus .textarea__prefix {
  color: #ec6602;
}

.textarea.is-valid {
  border-color: #009a38;
}

.textarea.is-valid .textarea__label {
  width: calc(100% - 77px);
}

.textarea.is-valid.textarea--with-prefix .textarea__label {
  width: calc(100% - 108px);
}

.textarea.is-valid .textarea__input {
  padding-right: 60px;
}

.textarea.is-valid::after {
  content: "";
  background-color: #009a38;
  color: #ffffff;
}

.textarea.is-invalid {
  border-color: #e7001d;
}

.textarea.is-invalid .textarea__label {
  width: calc(100% - 77px);
}

.textarea.is-invalid.textarea--with-prefix .textarea__label {
  width: calc(100% - 108px);
}

.textarea.is-invalid .textarea__input {
  padding-right: 48px;
}

.textarea.is-invalid::after {
  content: "";
  background-color: #e7001d;
  color: #ffffff;
}

.bg-color-gray-tint-5 .textarea {
  background-color: #ffffff;
}

[class*=bg-color-gray-shade] .textarea,
.bg-color-black .textarea {
  background-color: #1a1a1a;
  border-bottom: 1px solid #999999;
}

[class*=bg-color-gray-shade] .textarea__label,
.bg-color-black .textarea__label {
  color: #999999;
}

[class*=bg-color-gray-shade] .textarea__input,
.bg-color-black .textarea__input {
  color: #e6e6e6;
}

[class*=bg-color-gray-shade] .textarea__input::-webkit-input-placeholder, .bg-color-black .textarea__input::-webkit-input-placeholder {
  color: #999999;
}

[class*=bg-color-gray-shade] .textarea__input::-moz-placeholder, .bg-color-black .textarea__input::-moz-placeholder {
  color: #999999;
}

[class*=bg-color-gray-shade] .textarea__input:-ms-input-placeholder, .bg-color-black .textarea__input:-ms-input-placeholder {
  color: #999999;
}

[class*=bg-color-gray-shade] .textarea__input::-ms-input-placeholder, .bg-color-black .textarea__input::-ms-input-placeholder {
  color: #999999;
}

[class*=bg-color-gray-shade] .textarea__input::placeholder,
.bg-color-black .textarea__input::placeholder {
  color: #999999;
}

[class*=bg-color-gray-shade] .textarea__hint,
.bg-color-black .textarea__hint {
  color: #999999;
}

[class*=bg-color-gray-shade] .textarea.has-focus,
.bg-color-black .textarea.has-focus {
  border-color: #e6e6e6;
}

[class*=bg-color-gray-shade] .textarea.has-focus .textarea__prefix,
.bg-color-black .textarea.has-focus .textarea__prefix {
  color: #ec6602;
}

.toggle-switch {
  position: relative;
}

.toggle-switch__checkbox {
  width: 2.22222rem;
  height: 1.11111rem;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
}

.toggle-switch__checkbox + .toggle-switch__label {
  position: relative;
  padding-left: 3.05556rem;
  height: 1.11111rem;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
}

.toggle-switch__checkbox + .toggle-switch__label::before {
  content: "";
  display: block;
  position: absolute;
  width: 2.22222rem;
  height: 1.11111rem;
  top: 0;
  left: 0;
  border-radius: 99rem;
  border: 0.05556rem solid #666666;
  -webkit-transition: background-color 0s;
  transition: background-color 0s;
  -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
  background-color: #ffffff;
}

.toggle-switch__checkbox + .toggle-switch__label::after {
  content: "";
  display: block;
  position: absolute;
  left: 0.16667rem;
  top: 0.16667rem;
  width: 0.77778rem;
  height: 0.77778rem;
  border-radius: 99rem;
  background: #666666;
  -webkit-transition: background-color 0.2s ease-in-out, left 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, left 0.2s ease-in-out;
}

.toggle-switch__checkbox:checked + .toggle-switch__label::before {
  background-color: #ec6602;
  border-color: #ec6602;
}

.toggle-switch__checkbox:checked + .toggle-switch__label::after {
  background-color: #ffffff;
  left: 1.27778rem;
}

.toggle-switch__checkbox:focus + .toggle-switch__label::before {
  -webkit-box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
          box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
  outline: none;
}

.toggle-switch__checkbox:hover + .toggle-switch__label::before {
  background-color: #f2f2f2;
}

.toggle-switch__checkbox:checked:hover + .toggle-switch__label::before {
  background-color: #d45c02;
}

.toggle-switch__checkbox:disabled + .toggle-switch__label {
  cursor: not-allowed;
  opacity: 0.4;
}

.toggle-switch--large .toggle-switch__checkbox {
  width: 3.33333rem;
  height: 1.66667rem;
  background: transparent;
}

.toggle-switch--large .toggle-switch__checkbox + .toggle-switch__label {
  padding-left: 4.16667rem;
  height: 1.66667rem;
}

.toggle-switch--large .toggle-switch__checkbox + .toggle-switch__label::before {
  width: 3.33333rem;
  height: 1.66667rem;
}

.toggle-switch--large .toggle-switch__checkbox + .toggle-switch__label::after {
  left: 0.22222rem;
  top: 0.22222rem;
  width: 1.22222rem;
  height: 1.22222rem;
}

.toggle-switch--large .toggle-switch__checkbox:checked + .toggle-switch__label::after {
  left: 1.88889rem;
}

.js-tooltip {
  position: relative;
  cursor: pointer;
}

.js-tooltip:after {
  content: "";
  position: absolute;
  margin-left: -25px;
  margin-top: -12.5px;
}

.tooltip {
  color: #1a1a1a;
  background-color: #ffffff;
  display: none;
  position: absolute;
  z-index: 9999;
  max-width: 340px;
  margin-top: -16px;
  padding: 0.55556rem;
  text-align: left;
  line-height: 1.11111rem;
  font-size: 0.77778rem;
  -webkit-box-shadow: 0 4px 8px rgba(0, 0, 0, .1), 0 -1px 4px rgba(0, 0, 0, .05);
          box-shadow: 0 4px 8px rgba(0, 0, 0, .1), 0 -1px 4px rgba(0, 0, 0, .05);
}

.tooltip.is-visible {
  display: block;
}

.tooltip__close-button {
  color: #666666;
  position: absolute;
  right: 0.55556rem;
  top: 0.27778rem;
  font-size: 0.77778rem;
  display: none;
}

.tooltip.is-clickable .tooltip__close-button {
  display: block;
}

.tooltip::after {
  background-color: #ededed;
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0px;
  margin-left: 0px;
  width: 0px;
  height: 0px;
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-box-shadow: 3px 3px 3px rgba(26, 26, 26, 0.05);
          box-shadow: 3px 3px 3px rgba(26, 26, 26, 0.05);
}

.tooltip--top {
  margin-top: 0px;
}

.tooltip--top::after {
  top: 0px;
  bottom: auto;
  border-top-color: transparent;
  border-bottom: 10px solid #ededed;
}

.tooltip--left {
  margin-left: 0px;
}

.tooltip--left::after {
  left: 0;
  margin-left: 0px;
}

.tooltip--right {
  margin-left: 0px;
}

.tooltip--right::after {
  right: 0;
  left: auto;
  margin-left: 0;
  margin-right: 0px;
}

.user-identifier {
  position: relative;
}

.user-identifier__toggle-button {
  font-family: Calibri, sans-serif !important;
  font-size: 0.77778rem;
  color: #666666;
  /*
  Provide basic, default focus styles.
  */
  /*
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
  */
  /*
  Define a strong focus indicator for keyboard focus.
  */
  /*
  Remove default focus styles for mouse users ONLY if
  :focus-visible is supported on this platform.
  */
  /*
  If :focus-visible is supported on this
  platform, provide enhanced focus styles for keyboard
  focus.
  */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: left;
  -webkit-transition: color 0.16s linear;
  transition: color 0.16s linear;
}

.user-identifier__toggle-button:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
          box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
  outline: none;
}

.js-focus-visible .user-identifier__toggle-button:focus:not(.focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}

.js-focus-visible .user-identifier__toggle-button:focus.focus-visible {
  -webkit-box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
          box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
  outline: none;
}

.user-identifier__toggle-button:focus:not(:focus-visible) {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}

.user-identifier__toggle-button:focus-visible {
  -webkit-box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
          box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
  outline: none;
}

.user-identifier__toggle-button:hover,
.user-identifier__toggle-button:hover ::before {
  color: #1a1a1a;
}

.user-identifier__avatar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: 0.25rem;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 14px;
  font-weight: 800;
}

.user-identifier__avatar img {
  width: 2.22222rem;
  height: 2.22222rem;
  border-radius: 99rem;
  margin-right: 0.25rem;
  margin-left: 0.25rem;
}

.user-identifier__avatar i {
  display: inherit;
}

.user-identifier__avatar i::before {
  font-size: 1.22222rem;
  color: #666666;
}

.user-identifier__initials {
  background-color: #ededed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 99rem;
  width: 2.22222rem;
  height: 2.22222rem;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  overflow: hidden;
  margin-right: 0.25rem;
  margin-left: 0.25rem;
}

.user-identifier__svg {
  margin-right: 0.25rem;
  margin-left: 0.25rem;
}

.user-identifier__details {
  display: none;
  font-size: 0.8889rem;
  line-height: 1.38889rem;
}

@media only screen and (min-width: 48em) {
  .user-identifier__details {
    display: block;
  }
}

.user-identifier__name, .user-identifier__company {
  display: block;
  font-size: 0.77778rem;
  line-height: 1.11111rem;
}

.user-identifier__name {
  font-weight: 800;
}

.inpage-navigation {
  width: 100%;
  margin-bottom: 1.11111rem;
}

.inpage-navigation__panel {
  border-bottom: 1px solid #b3b3b3;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-line-pack: center;
      align-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 0;
}

@media only screen and (min-width: 48em) {
  .inpage-navigation__panel {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}

.inpage-navigation__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media only screen and (min-width: 48em) {
  .inpage-navigation__container {
    margin: 0;
    z-index: 1111;
  }
}

.inpage-navigation__cta {
  -ms-flex-item-align: center;
      align-self: center;
  width: 100%;
  margin: 0.27778rem 0 0.77778rem;
}

@media only screen and (min-width: 48em) {
  .inpage-navigation__cta {
    width: inherit;
    white-space: nowrap;
    margin: 0.27778rem 0;
  }
}

.inpage-navigation__selectbox {
  display: block;
  height: auto;
  margin: 0;
  background: transparent;
  border: 0;
}

@media only screen and (min-width: 48em) {
  .inpage-navigation__selectbox {
    display: none;
  }
}

.inpage-navigation__select {
  height: auto;
  padding: 1.11111rem 1.11111rem;
  line-height: 1.66667rem;
  white-space: nowrap;
  text-align: center;
  cursor: pointer;
  color: #1a1a1a;
  font-weight: 600;
}

.inpage-navigation .selectbox__option {
  color: #666666;
  font-weight: 600;
}

.inpage-navigation__list {
  display: none;
  list-style: none;
  white-space: nowrap;
  margin: 0;
  padding: 0;
}

@media only screen and (min-width: 48em) {
  .inpage-navigation__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.inpage-navigation__item {
  display: inline-block;
  margin: 0;
  padding: 0;
}

.inpage-navigation__link {
  padding: 1.11111rem 1.11111rem;
  line-height: 1.66667rem;
  white-space: nowrap;
  text-align: center;
  cursor: pointer;
  color: #666666;
  font-weight: 600;
  display: block;
}

.inpage-navigation__link:focus {
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #666666;
}

.inpage-navigation__link:focus::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-bottom: 2px solid rgba(236, 102, 2, 0.5);
}

.inpage-navigation__link:hover, .inpage-navigation__link:active {
  color: #1a1a1a;
}

.inpage-navigation__link:hover::after, .inpage-navigation__link:active::after {
  border-bottom: none;
}

.inpage-navigation__link.is-active {
  color: inherit;
}

.inpage-navigation__link.is-active::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-bottom: 2px solid #ec6602;
}

.inpage-navigation.is-collapsed .inpage-navigation__list {
  display: none;
}

.inpage-navigation.is-collapsed .inpage-navigation__selectbox {
  display: block;
}

@media only screen and (min-width: 48em) {
  .inpage-navigation.is-collapsed .inpage-navigation__panel {
    padding: 0;
  }
}

.inpage-navigation--clone {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  -webkit-transform: translate(0, -100%);
      -ms-transform: translate(0, -100%);
          transform: translate(0, -100%);
  -webkit-transition: -webkit-transform 0.3s ease-in;
  transition: -webkit-transform 0.3s ease-in;
  transition: transform 0.3s ease-in;
  transition: transform 0.3s ease-in, -webkit-transform 0.3s ease-in;
  background-color: #ededed;
  border-bottom: 1px solid #b3b3b3;
  z-index: 9999;
}

.inpage-navigation--clone.is-visible {
  -webkit-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
          transform: translate(0, 0);
}

.inpage-navigation--clone .inpage-navigation__panel {
  border: none;
}

.application-bar--documentation .application-bar__container {
  width: 100%;
}

.application-bar--documentation .application-bar__app-identifier {
  margin-right: 0;
}

.application-bar--documentation .application-bar__additional-functions {
  margin-left: 0;
  margin-right: auto;
}

.logo {
   background: url("assets/logo.svg") no-repeat; 
}

@media only screen and (min-width: 48em) {
  .navigation-bar--documentation {
    display: none;
  }
}

.navigation-bar--documentation .navigation-bar__container {
  min-width: 100%;
}

.application-container {
  position: relative;
}

@media only screen and (min-width: 48em) {
  .application-container {
    position: relative;
    width: 100vw;
    height: 100vh;
  }
  .header {
    position: absolute;
    top: 0;
    height: 70px;
    width: 100%;
    z-index: 1000;
    -webkit-box-shadow: 0 4px 0.4rem rgba(27, 27, 27, 0.2);
            box-shadow: 0 4px 0.4rem rgba(27, 27, 27, 0.2);
  }
  .main-container {
    position: absolute;
    top: 110px;
    left: 330px;
    right: 0;
    bottom: 0;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    -webkit-transition: left 0.3s ease-in-out;
    transition: left 0.3s ease-in-out;
  }
  .is-collapsed .main-container {
    left: 0;
  }
}

.mainside-container {
    position: absolute;
    top: 110px;
    left:0;
    right: 0;
    bottom: 0;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    -webkit-transition: left 0.3s ease-in-out;
    transition: left 0.3s ease-in-out;
  }

  .help-container {
    position: absolute;
    top: 72px;
    left: 330px;
    right: 0;
    bottom: 0;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    -webkit-transition: left 0.3s ease-in-out;
    transition: left 0.3s ease-in-out;
  }

[class*=callout] {
  padding: 0.83333rem;
  padding-left: 3.33333rem;
  margin-bottom: 1.66667rem;
  border: 1px solid #e6e6e6;
  border-left-width: 0.27778rem;
  border-radius: .125rem;
  position: relative;
}

[class*=callout] > *:first-child {
  margin-top: 0;
}

[class*=callout] > *:last-child {
  margin-bottom: 0;
}

[class*=callout]::before {
  position: absolute;
  left: 0.83333rem;
  top: 0.83333rem;
}

.callout--info {
  border-left-color: #3abfed;
}

.callout--info::before {
  font-family: "SH Icon",sans-serif;
  font-weight: normal !important;
  font-style: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  vertical-align: baseline;
  content: "";
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #3abfed;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 1em;
  width: 1.66667rem;
  height: 1.66667rem;
  /* line-height: 1.7; */
  border-radius: 50%;
  color: #000000;
}

.callout--warning {
  border-left-color: #ffd200;
}

.callout--warning::before {
  font-family: "SH Icon",sans-serif;
  font-weight: normal !important;
  font-style: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  vertical-align: baseline;
  content: "";
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #ffd200;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 1em;
  width: 1.66667rem;
  height: 1.66667rem;
  /* line-height: 1.7; */
  border-radius: 50%;
  color: #000000;
}

.callout--danger {
  border-left-color: #e7001d;
}

.callout--danger::before {
  font-family: "SH Icon",sans-serif;
  font-weight: normal !important;
  font-style: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  vertical-align: baseline;
  content: "";
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #e7001d;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 1em;
  width: 1.66667rem;
  height: 1.66667rem;
  /* line-height: 1.7; */
  border-radius: 50%;
  color: #ffffff;
}

.callout--success {
  border-left-color: #009a38;
}

.callout--success::before {
  font-family: "SH Icon",sans-serif;
  font-weight: normal !important;
  font-style: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  vertical-align: baseline;
  content: "";
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #009a38;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 1em;
  width: 1.66667rem;
  height: 1.66667rem;
  /* line-height: 1.7; */
  border-radius: 50%;
  color: #ffffff;
}

pre {
  border-radius: 2px;
  border: 1px solid #e6e6e6;
  display: block;
  font-family: monospace;
  font-size: .85rem;
  line-height: 1.38889rem;
  margin: 0;
  padding: 0;
  position: relative;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;

}

pre code {
  -webkit-transition: max-height 0.3s ease-out;
  transition: max-height 0.3s ease-out;
  overflow: hidden;
  overflow-x: auto;
}

pre code.is-open {
  max-height: 99em;
  overflow: auto;
  -webkit-transition: max-height 0.3s ease-in;
  transition: max-height 0.3s ease-in;
}

/*

Original highlight.js style (c) Ivan Sagalaev <maniac@softwaremaniacs.org>

*/
.hljs {
  display: block;
  overflow-x: auto;
  padding: 0.77778rem 1.05556rem;
  background: #f2f2f2;
}

/* Base color: saturation 0; */
.hljs,
.hljs-subst {
  color: #444;
}

.hljs-comment {
  color: #888888;
}

.hljs-keyword,
.hljs-attribute,
.hljs-selector-tag,
.hljs-meta-keyword,
.hljs-doctag,
.hljs-name {
  font-weight: bold;
}

/* User color: hue: 0 */
.hljs-type,
.hljs-string,
.hljs-number,
.hljs-selector-id,
.hljs-selector-class,
.hljs-quote,
.hljs-template-tag,
.hljs-deletion {
  color: #880000;
}

.hljs-title,
.hljs-section {
  color: #880000;
  font-weight: bold;
}

.hljs-regexp,
.hljs-symbol,
.hljs-variable,
.hljs-template-variable,
.hljs-link,
.hljs-selector-attr,
.hljs-selector-pseudo {
  color: #BC6060;
}

/* Language color: hue: 90; */
.hljs-literal {
  color: #78A960;
}

.hljs-built_in,
.hljs-bullet,
.hljs-code,
.hljs-addition {
  color: #397300;
}

/* Meta color: hue: 200 */
.hljs-meta {
  color: #1f7199;
}

.hljs-meta-string {
  color: #4d99bf;
}

/* Misc effects */
.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}

/* .collapsible {
  color: #1a1a1a;
} */

/* .collapsible__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0.5rem 1rem;
  margin: 0;
  width: 100%;
  font-family: Calibri, sans-serif !important;
  font-weight: 800;
  font-size: 1rem;
  text-align: left;
  border: none;
  cursor: pointer;
} */

/* .collapsible__header:before {
  color: #666666;
  position: absolute;
  right: 1rem;
} */

/* .collapsible__header:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
  border-bottom-color: #1a1a1a;
} */

/* .collapsible__header:focus:before {
  color: #1a1a1a;
} */

/* .collapsible__header:hover, .collapsible__header:active {
  background-color: #ededed;
} */

/* .collapsible__body {
  display: none;
  margin: 0;
  overflow: hidden;
} */

/* .collapsible.is-expanded .collapsible__header {
  border-bottom: none;
} */

/* .collapsible.is-expanded .collapsible__header:before {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
} */

/* .collapsible.is-expanded .collapsible__body {
  display: block;
} */

#dos-and-donts + ul,
#dos-and-don-ts + ul,
.dos-and-donts {
  padding: 0.83333rem;
  margin-bottom: 1.66667rem;
  border: 1px solid #e6e6e6;
  background: #ededed;
}

#dos-and-donts + ul > *:first-child,
#dos-and-don-ts + ul > *:first-child,
.dos-and-donts > *:first-child {
  margin-top: 0;
}

#dos-and-donts + ul > *:last-child,
#dos-and-don-ts + ul > *:last-child,
.dos-and-donts > *:last-child {
  margin-bottom: 0;
}

#dos-and-donts + ul,
#dos-and-don-ts + ul {
  padding-left: 1.66667rem;
}

.image-frame {
  display: inline-block;
  background: #ededed;
  padding: 1.05556rem;
  border: 1px solid #e6e6e6;
}

.image-frame + figcaption {
  padding: .5rem 1px;
}

.grid-helper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #9592c1;
  color: #ffffff;
  height: 40px;
}

.grid-helper--gray {
  background-color: #ededed;
}

.icon-preview {
  margin-bottom: 1.666em;
}

.icon-preview__icon {
  font-size: 28px;
}

.icon-preview__caption {
  color: #666666;
  font-size: 0.77778rem;
}

.label--status {
  font-size: 0.77778rem;
  color: #333333;
}

.label--status[data-name^=Stable] {
  background-color: #80cd9c;
}

.label--status[data-name^=Experimental] {
  background-color: #ffe980;
}

.label--status[data-name^=Deprecated] {
  background-color: #f3808d;
}

.label--status[data-name^=N\/A] {
  background-color: #e6e6e6;
  color: #666666;
}

.label--status[data-name^=New] {
  background-color: #9ddff6;
}

.label--status[data-name^=Updated] {
  background-color: #87d2d2;
}

.label--status[data-name*=construction] {
  background-color: #e9d1d4;
}

.meta-menu--documentation {
  margin-right: 0;
}

.meta-menu--documentation .meta-menu__item:last-child {
  margin-right: 0;
}

/* .page {
  padding-bottom: 3rem;
} */

/* .page__title {
  margin-top: 1.5rem;
} */

/* .page hr {
  margin-top: 6.66667rem;
} */

/* .page p a:not(.button),
.page * > p a:not(.button) {
  border-bottom: 1px solid currentColor;
} */

/* .page p a:not(.button)[class*="icon"],
.page * > p a:not(.button)[class*="icon"] {
  margin-left: 1.1em;
  position: relative;
} */

/* .page p a:not(.button)[class*="icon"]::before,
.page * > p a:not(.button)[class*="icon"]::before {
  position: absolute;
  left: -1.3em;
  line-height: 1;
  margin-top: .44em;
} */

.preview-panel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  padding: 1.05556rem;
  border: 1px solid #e6e6e6;
  overflow: visible;
}

.preview-panel--adapted {
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
}

.preview-panel--centered {
  margin: 0 auto;
}

.preview-panel + figcaption {
  padding: .5rem 1px;
}

pre + .preview-panel,
.preview-panel + pre {
  margin-top: -1px;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 280px;
  background-color: #f2f2f2;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  z-index: 10000;
  /* -webkit-transform: translate3d(-100%, 0, 0);
          transform: translate3d(-100%, 0, 0); */
}

.is-open .sidebar {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-box-shadow: 0 100vh 0 100vh rgba(48, 48, 48, 0.5);
          box-shadow: 0 100vh 0 100vh rgba(48, 48, 48, 0.5);
}

.is-open .sidebar .sidebar__close-button {
  display: block;
}

@media only screen and (min-width: 48em) {
  .sidebar {
    position: absolute;
    top: 70px;
    max-width: 330px;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    z-index: 1;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .is-collapsed .sidebar {
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
  }
  .sidebar .sidebar__close-button {
    display: none;
  }
}

.sidebar__container {
  padding: 0;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

@media only screen and (min-width: 48em) {
  .sidebar__container {
    padding: 1rem 0rem;
  }
}

.sidebar__close-button {
  position: absolute;
  top: 0.5rem;
  right: -2rem;
  color: #ffffff;
  display: none;
  font-size: 1.5rem;
}

.sidebar__toggle-button {
  position: absolute;
  top: 50%;
  left: 100%;
  color: #1a1919;
  background-color: #f2f2f2;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  display: none;
}

.sidebar__toggle-button i {
  display: inline-block;
}

.is-collapsed .sidebar__toggle-button i {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

@media only screen and (min-width: 48em) {
  .sidebar__toggle-button {
    display: block;
  }
}

.sidebar-navigation__link {
  display: block;
  color: #1a1a1a;
  padding: 0.5rem 0.83333rem;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
}

.sidebar-navigation__link:hover, .sidebar-navigation__link:active {
  background-color: #ededed;
  color: #1a1a1a;
}

.sidebar-navigation__link.is-active {
  background-color: #e6e6e6;
  border-left-color: #ec6602;
}

.collapsible {
  color: #1a1a1a;
}

.collapsible__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0.5rem 1rem;
  margin: 0;
  width: 100%;
  font-family: Calibri, sans-serif !important;
  font-weight: 800;
  font-size: 1rem;
  text-align: left;
  border: none;
  cursor: pointer;
}

.collapsible__header:before {
  color: #666666;
  position: absolute;
  right: 1rem;
}

.collapsible__header:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
  border-bottom-color: #1a1a1a;
}

.collapsible__header:focus:before {
  color: #1a1a1a;
}

.collapsible__header:hover, .collapsible__header:active {
  background-color: #ededed;
}

.collapsible__body {
  display: none;
  margin: 0;
  overflow: hidden;
}

.collapsible.is-expanded .collapsible__header {
  border-bottom: none;
}

.collapsible.is-expanded .collapsible__header:before {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}

.collapsible.is-expanded .collapsible__body {
  display: block;
}

.page {
  padding-bottom: 3rem;
}

.page__title {
  margin-top: 1.5rem;
}

.page hr {
  margin-top: 6.66667rem;
}

.page p a:not(.button),
.page * > p a:not(.button) {
  border-bottom: 1px solid currentColor;
}

.page p a:not(.button)[class*="icon"],
.page * > p a:not(.button)[class*="icon"] {
  margin-left: 1.1em;
  position: relative;
}

.page p a:not(.button)[class*="icon"]::before,
.page * > p a:not(.button)[class*="icon"]::before {
  position: absolute;
  left: -1.3em;
  line-height: 1;
  margin-top: .44em;
}

.tabs--documentation {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  border-bottom: 1px solid #b3b3b3;
  margin-bottom: 2rem;
  z-index: 1111;
}

.tabs--documentation .tabs__list {
  margin: 0 auto;
  border: none;
}

.tabs--documentation .tabs__item.is-active:after {
  border-bottom: 2px solid #ec6602;
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  right: 0;
}

.overlay--fullscreen {
  display: block;
  opacity: 0;
  pointer-events: none;
}

.overlay--fullscreen.is-open {
  opacity: 1;
  pointer-events: inherit;
}

.overlay--fullscreen .overlay__container {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}

.overlay--fullscreen .overlay__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100vh;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.text-align-left {
  text-align: left !important;
}

.text-align-right {
  text-align: right !important;
}

.text-align-center {
  text-align: center !important;
}

.text-align-justify {
  text-align: justify !important;
}

.bg-color-fog {
  background-color: rgba(26, 26, 26, 0.5) !important;
  color: #ffffff !important;
}

.bg-color-black {
  background-color: #000000 !important;
  color: #ffffff !important;
}

.bg-color-gray-shade-4 {
  background-color: #1a1a1a !important;
  color: #ffffff !important;
}

.bg-color-gray-shade-3 {
  background-color: #333333 !important;
  color: #ffffff !important;
}

.bg-color-gray-shade-2 {
  background-color: #404040 !important;
  color: #ffffff !important;
}

.bg-color-gray-shade-1 {
  background-color: #4d4d4d !important;
  color: #ffffff !important;
}

.bg-color-gray {
  background-color: #666666 !important;
  color: #ffffff !important;
}

.bg-color-gray-tint-1 {
  background-color: #999999 !important;
}

.bg-color-gray-tint-2 {
  background-color: #b3b3b3 !important;
}

.bg-color-gray-tint-3 {
  background-color: #cccccc !important;
}

.bg-color-gray-tint-4 {
  background-color: #e6e6e6 !important;
}

.bg-color-gray-tint-5 {
  background-color: #ededed !important;
}

.bg-color-gray-tint-6 {
  background-color: #f2f2f2 !important;
}

.bg-color-white {
  background-color: #ffffff !important;
}

.bg-color-orange-shade-1 {
  background-color: #cf4b00 !important;
  color: #ffffff !important;
}

.bg-color-orange {
  background-color: #ec6602 !important;
}

.bg-color-orange-tint-2 {
  background-color: #f9b591 !important;
}

.bg-color-orange-tint-3 {
  background-color: #fdddcb !important;
}

.bg-color-petrol-shade-1 {
  background-color: #02787d !important;
  color: #ffffff !important;
}

.bg-color-petrol {
  background-color: #009999 !important;
}

.bg-color-petrol-tint-2 {
  background-color: #87d2d2 !important;
}

.bg-color-petrol-tint-3 {
  background-color: #c8e6e6 !important;
}

.bg-color-red-shade-1 {
  background-color: #d9001d !important;
  color: #ffffff !important;
}

.bg-color-red {
  background-color: #e7001d !important;
  color: #ffffff !important;
}

.bg-color-red-tint-2 {
  background-color: #f3808d !important;
}

.bg-color-red-tint-3 {
  background-color: #f9bfc7 !important;
}

.bg-color-green-shade-1 {
  background-color: #197c3a !important;
  color: #ffffff !important;
}

.bg-color-green {
  background-color: #009a38 !important;
}

.bg-color-green-tint-2 {
  background-color: #80cd9c !important;
}

.bg-color-green-tint-3 {
  background-color: #bfe6cd !important;
}

.bg-color-yellow {
  background-color: #ffd200 !important;
}

.bg-color-yellow-tint-2 {
  background-color: #ffe980 !important;
}

.bg-color-yellow-tint-3 {
  background-color: #fff3bf !important;
}

.bg-color-berry {
  background-color: #7a162d !important;
  color: #ffffff !important;
}

.bg-color-berry-tint-2 {
  background-color: #c69b9e !important;
}

.bg-color-berry-tint-3 {
  background-color: #e9d1d4 !important;
}

.bg-color-blue {
  background-color: #2b2483 !important;
  color: #ffffff !important;
}

.bg-color-blue-tint-2 {
  background-color: #9592c1 !important;
}

.bg-color-blue-tint-3 {
  background-color: #cac8e0 !important;
}

.bg-color-cyan {
  background-color: #3abfed !important;
}

.bg-color-cyan-tint-2 {
  background-color: #9ddff6 !important;
}

.bg-color-cyan-tint-3 {
  background-color: #ceeffb !important;
}

.has-background-image-sm-up {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% top;
}

.has-background-image,
.has-background-image-md-up {
  background-size: 0%;
}

@media only screen and (min-width: 48em) {
  .has-background-image,
  .has-background-image-md-up {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
  .has-background-image > img,
  .has-background-image-md-up > img {
    display: none;
  }
}

.border-radius-1 {
  border-radius: 1px;
}

.border-radius-2 {
  border-radius: 2px;
}

.border-radius-3 {
  border-radius: 3px;
}

.border-radius-4 {
  border-radius: 4px;
}

.border-radius-5 {
  border-radius: 5px;
}

.border-radius-round {
  border-radius: 99rem;
}

.border-none {
  border: none !important;
}

.border-top-none {
  border-top: none !important;
}

.border-right-none {
  border-right: none !important;
}

.border-bottom-none {
  border-bottom: none !important;
}

.border-left-none {
  border-left: none !important;
}

.color-fog {
  color: rgba(26, 26, 26, 0.5) !important;
}

.color-black {
  color: #000000 !important;
}

.color-gray-shade-4 {
  color: #1a1a1a !important;
}

.color-gray-shade-3 {
  color: #333333 !important;
}

.color-gray-shade-2 {
  color: #404040 !important;
}

.color-gray-shade-1 {
  color: #4d4d4d !important;
}

.color-gray {
  color: #666666 !important;
}

.color-gray-tint-1 {
  color: #999999 !important;
}

.color-gray-tint-2 {
  color: #b3b3b3 !important;
}

.color-gray-tint-3 {
  color: #cccccc !important;
}

.color-gray-tint-4 {
  color: #e6e6e6 !important;
}

.color-gray-tint-5 {
  color: #ededed !important;
}

.color-gray-tint-6 {
  color: #f2f2f2 !important;
}

.color-white {
  color: #ffffff !important;
}

.color-orange-shade-1 {
  color: #cf4b00 !important;
}

.color-orange {
  color: #ec6602 !important;
}

.color-orange-tint-2 {
  color: #f9b591 !important;
}

.color-orange-tint-3 {
  color: #fdddcb !important;
}

.color-petrol-shade-1 {
  color: #02787d !important;
}

.color-petrol {
  color: #009999 !important;
}

.color-petrol-tint-2 {
  color: #87d2d2 !important;
}

.color-petrol-tint-3 {
  color: #c8e6e6 !important;
}

.color-red-shade-1 {
  color: #d9001d !important;
}

.color-red {
  color: #e7001d !important;
}

.color-red-tint-2 {
  color: #f3808d !important;
}

.color-red-tint-3 {
  color: #f9bfc7 !important;
}

.color-green-shade-1 {
  color: #197c3a !important;
}

.color-green {
  color: #009a38 !important;
}

.color-green-tint-2 {
  color: #80cd9c !important;
}

.color-green-tint-3 {
  color: #bfe6cd !important;
}

.color-yellow {
  color: #ffd200 !important;
}

.color-yellow-tint-2 {
  color: #ffe980 !important;
}

.color-yellow-tint-3 {
  color: #fff3bf !important;
}

.color-berry {
  color: #7a162d !important;
}

.color-berry-tint-2 {
  color: #c69b9e !important;
}

.color-berry-tint-3 {
  color: #e9d1d4 !important;
}

.color-blue {
  color: #2b2483 !important;
}

.color-blue-tint-2 {
  color: #9592c1 !important;
}

.color-blue-tint-3 {
  color: #cac8e0 !important;
}

.color-cyan {
  color: #3abfed !important;
}

.color-cyan-tint-2 {
  color: #9ddff6 !important;
}

.color-cyan-tint-3 {
  color: #ceeffb !important;
}

.color-inherit {
  color: inherit !important;
}

.width-auto {
  width: auto !important;
}

.width-100 {
  width: 100% !important;
}

.height-100 {
  height: 100% !important;
}

.display-none {
  display: none !important;
}

.display-inline {
  display: inline !important;
}

.display-inline-block {
  display: inline-block !important;
}

.display-block {
  display: block !important;
}

.display-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
          flex-direction: row !important;
}

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
          flex-direction: column !important;
}

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
      -ms-flex-direction: row-reverse !important;
          flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
      -ms-flex-direction: column-reverse !important;
          flex-direction: column-reverse !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important;
}

.flex-fill {
  -webkit-box-flex: 1 !important;
      -ms-flex: 1 1 auto !important;
          flex: 1 1 auto !important;
}

.flex-grow-0 {
  -webkit-box-flex: 0 !important;
      -ms-flex-positive: 0 !important;
          flex-grow: 0 !important;
}

.flex-grow-1 {
  -webkit-box-flex: 1 !important;
      -ms-flex-positive: 1 !important;
          flex-grow: 1 !important;
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important;
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important;
}

.justify-content-start {
  -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
          justify-content: flex-start !important;
}

.justify-content-end {
  -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
          justify-content: flex-end !important;
}

.justify-content-center {
  -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
          justify-content: center !important;
}

.justify-content-between {
  -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
          justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
      justify-content: space-around !important;
}

.align-items-start {
  -webkit-box-align: start !important;
      -ms-flex-align: start !important;
          align-items: flex-start !important;
}

.align-items-end {
  -webkit-box-align: end !important;
      -ms-flex-align: end !important;
          align-items: flex-end !important;
}

.align-items-center {
  -webkit-box-align: center !important;
      -ms-flex-align: center !important;
          align-items: center !important;
}

.align-items-baseline {
  -webkit-box-align: baseline !important;
      -ms-flex-align: baseline !important;
          align-items: baseline !important;
}

.align-items-stretch {
  -webkit-box-align: stretch !important;
      -ms-flex-align: stretch !important;
          align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
      align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
      align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
      align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
      align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
      align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
      align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
      align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
      align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
      align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
      align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
      align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
      align-self: stretch !important;
}

@media (min-width: 36em) {
  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important;
  }
  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important;
  }
  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important;
  }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }
  .align-items-sm-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important;
  }
  .align-items-sm-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important;
  }
  .align-items-sm-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important;
  }
  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important;
  }
  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important;
  }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
  }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
  }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important;
  }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
  }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
  }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
  }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important;
  }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
  }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
  }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
        align-self: center !important;
  }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
  }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
  }
}

@media (min-width: 48em) {
  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important;
  }
  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
  }
  .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important;
  }
  .justify-content-md-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important;
  }
  .justify-content-md-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important;
  }
  .justify-content-md-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important;
  }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }
  .align-items-md-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important;
  }
  .align-items-md-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important;
  }
  .align-items-md-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important;
  }
  .align-items-md-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important;
  }
  .align-items-md-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important;
  }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
  }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
  }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important;
  }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
  }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
  }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
  }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important;
  }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
  }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
  }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
        align-self: center !important;
  }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
  }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
  }
}

@media (min-width: 62em) {
  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important;
  }
  .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important;
  }
  .justify-content-lg-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important;
  }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }
  .align-items-lg-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important;
  }
  .align-items-lg-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important;
  }
  .align-items-lg-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important;
  }
  .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important;
  }
  .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important;
  }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
  }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
  }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important;
  }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
  }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
  }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
  }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important;
  }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
  }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
  }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
        align-self: center !important;
  }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
  }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
  }
}

@media (min-width: 75em) {
  .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important;
  }
  .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important;
  }
  .justify-content-xl-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important;
  }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }
  .align-items-xl-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important;
  }
  .align-items-xl-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important;
  }
  .align-items-xl-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important;
  }
  .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important;
  }
  .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important;
  }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
  }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
  }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important;
  }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
  }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
  }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
  }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important;
  }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
  }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
  }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
        align-self: center !important;
  }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
  }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
  }
}

.flex-auto {
  -webkit-box-flex: 1 !important;
      -ms-flex: auto !important;
          flex: auto !important;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.font-family-headline {
  font-family: Calibri, sans-serif !important;
}

.font-family-headline-small {
  font-family: Calibri, sans-serif !important;
}

.font-family-text {
  font-family: Calibri, sans-serif !important;
}

.font-size-super,
.font-size-display1 {
  font-size: 1.88889rem !important;
}

@media only screen and (min-width: 48em) {
  .font-size-super,
  .font-size-display1 {
    font-size: 2.22222rem !important;
  }
}

@media only screen and (min-width: 75em) {
  .font-size-super,
  .font-size-display1 {
    font-size: 2.77778rem !important;
  }
}

.font-size-display2 {
  font-size: 1.77778rem !important;
}

@media only screen and (min-width: 48em) {
  .font-size-display2 {
    font-size: 2.05556rem !important;
  }
}

@media only screen and (min-width: 75em) {
  .font-size-display2 {
    font-size: 2.5rem !important;
  }
}

.font-size-xlarge {
  font-size: 1.33333rem !important;
  line-height: 1.94444rem !important;
}

.font-size-large {
  font-size: 1.22222rem !important;
  line-height: 1.94444rem !important;
}

.font-size-medium {
  font-size: 1rem !important;
  line-height: 1.66667rem !important;
}

.font-size-small {
  font-size: 0.8889rem !important;
  line-height: 1.38889rem !important;
}

.font-size-xsmall {
  font-size: 0.77778rem !important;
  line-height: 1.11111rem !important;
}

.font-style-normal {
  font-style: normal !important;
}

.font-style-italic {
  font-style: italic !important;
}

.font-style-oblique {
  font-style: oblique !important;
}

/* .text-decoration-none {
  text-decoration: none !important;
} */

/* .text-decoration-underline {
  text-decoration: underline !important;
} */

/* .text-decoration-line-through {
  text-decoration: line-through !important;
} */

.text-muted {
  opacity: .7 !important;
}

.font-weight-light {
  font-weight: 100 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 800 !important;
}

.image-replacement {
  background-color: transparent;
  border: 0;
  overflow: hidden;
  display: block;
  text-indent: -100%;
  white-space: nowrap;
}

.image-replacement:before {
  content: "";
  display: block;
  width: 0;
  height: 110%;
}

@media only screen and (min-width: 0em) {
  .image-size-small {
    width: 76px;
  }
}

@media only screen and (min-width: 36em) {
  .image-size-small {
    width: 76px;
  }
}

@media only screen and (min-width: 48em) {
  .image-size-small {
    width: 76px;
  }
}

@media only screen and (min-width: 62em) {
  .image-size-small {
    width: 76px;
  }
}

@media only screen and (min-width: 75em) {
  .image-size-small {
    width: 76px;
  }
}

@media only screen and (min-width: 0em) {
  .image-size-medium {
    width: 140px;
  }
}

@media only screen and (min-width: 36em) {
  .image-size-medium {
    width: 140px;
  }
}

@media only screen and (min-width: 48em) {
  .image-size-medium {
    width: 110px;
  }
}

@media only screen and (min-width: 62em) {
  .image-size-medium {
    width: 110px;
  }
}

@media only screen and (min-width: 75em) {
  .image-size-medium {
    width: 140px;
  }
}

@media only screen and (min-width: 0em) {
  .image-size-large {
    width: 76px;
  }
}

@media only screen and (min-width: 36em) {
  .image-size-large {
    width: 76px;
  }
}

@media only screen and (min-width: 48em) {
  .image-size-large {
    width: 180px;
  }
}

@media only screen and (min-width: 62em) {
  .image-size-large {
    width: 180px;
  }
}

@media only screen and (min-width: 75em) {
  .image-size-large {
    width: 180px;
  }
}

@media only screen and (min-width: 0em) {
  .image-size-expand {
    width: 100%;
  }
}

@media only screen and (min-width: 36em) {
  .image-size-expand {
    width: 100%;
  }
}

@media only screen and (min-width: 48em) {
  .image-size-expand {
    width: 100%;
  }
}

@media only screen and (min-width: 62em) {
  .image-size-expand {
    width: 100%;
  }
}

@media only screen and (min-width: 75em) {
  .image-size-expand {
    width: 100%;
  }
}

.margin-auto {
  margin: auto !important;
}

.margin-0 {
  margin: 0rem !important;
}

.margin-1 {
  margin: 1.66667rem !important;
}

.margin-2 {
  margin: 3.33333rem !important;
}

.margin-3 {
  margin: 5.0rem !important;
}

.margin-4 {
  margin: 6.66667rem !important;
}

.margin-top-auto {
  margin-top: auto !important;
}

.margin-top-0 {
  margin-top: 0rem !important;
}

.margin-top-1 {
  margin-top: 1.66667rem !important;
}

.margin-top-2 {
  margin-top: 3.33333rem !important;
}

.margin-top-3 {
  margin-top: 5.0rem !important;
}

.margin-top-4 {
  margin-top: 6.66667rem !important;
}

.margin-right-auto {
  margin-right: auto !important;
}

.margin-right-0 {
  margin-right: 0rem !important;
}

.margin-right-1 {
  margin-right: 1.66667rem !important;
}

.margin-right-2 {
  margin-right: 3.33333rem !important;
}

.margin-right-3 {
  margin-right: 5.0rem !important;
}

.margin-right-4 {
  margin-right: 6.66667rem !important;
}

.margin-bottom-auto {
  margin-bottom: auto !important;
}

.margin-bottom-0 {
  margin-bottom: 0rem !important;
}

.margin-bottom-1 {
  margin-bottom: 1.66667rem !important;
}

.margin-bottom-2 {
  margin-bottom: 3.33333rem !important;
}

.margin-bottom-3 {
  margin-bottom: 5.0rem !important;
}

.margin-bottom-4 {
  margin-bottom: 6.66667rem !important;
}

.margin-left-auto {
  margin-left: auto !important;
}

.margin-left-0 {
  margin-left: 0rem !important;
}

.margin-left-1 {
  margin-left: 1.66667rem !important;
}

.margin-left-2 {
  margin-left: 3.33333rem !important;
}

.margin-left-3 {
  margin-left: 5.0rem !important;
}

.margin-left-4 {
  margin-left: 6.66667rem !important;
}

.padding-auto {
  padding: auto !important;
}

.padding-0 {
  padding: 0rem !important;
}

.padding-1 {
  padding: 1.66667rem !important;
}

.padding-2 {
  padding: 3.33333rem !important;
}

.padding-3 {
  padding: 5.0rem !important;
}

.padding-4 {
  padding: 6.66667rem !important;
}

.padding-top-auto {
  padding-top: auto !important;
}

.padding-top-0 {
  padding-top: 0rem !important;
}

.padding-top-1 {
  padding-top: 1.66667rem !important;
}

.padding-top-2 {
  padding-top: 3.33333rem !important;
}

.padding-top-3 {
  padding-top: 5.0rem !important;
}

.padding-top-4 {
  padding-top: 6.66667rem !important;
}

.padding-right-auto {
  padding-right: auto !important;
}

.padding-right-0 {
  padding-right: 0rem !important;
}

.padding-right-1 {
  padding-right: 1.66667rem !important;
}

.padding-right-2 {
  padding-right: 3.33333rem !important;
}

.padding-right-3 {
  padding-right: 5.0rem !important;
}

.padding-right-4 {
  padding-right: 6.66667rem !important;
}

.padding-bottom-auto {
  padding-bottom: auto !important;
}

.padding-bottom-0 {
  padding-bottom: 0rem !important;
}

.padding-bottom-1 {
  padding-bottom: 1.66667rem !important;
}

.padding-bottom-2 {
  padding-bottom: 3.33333rem !important;
}

.padding-bottom-3 {
  padding-bottom: 5.0rem !important;
}

.padding-bottom-4 {
  padding-bottom: 6.66667rem !important;
}

.padding-left-auto {
  padding-left: auto !important;
}

.padding-left-0 {
  padding-left: 0rem !important;
}

.padding-left-1 {
  padding-left: 1.66667rem !important;
}

.padding-left-2 {
  padding-left: 3.33333rem !important;
}

.padding-left-3 {
  padding-left: 5.0rem !important;
}

.padding-left-4 {
  padding-left: 6.66667rem !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.top-0 {
  position: relative;
  top: 0rem !important;
}

.top-1 {
  position: relative;
  top: 1.75rem !important;
}

.top-2 {
  position: relative;
  top: 3.5rem !important;
}

.top-3 {
  position: relative;
  top: 5.25rem !important;
}

.top-4 {
  position: relative;
  top: 7rem !important;
}

.right-0 {
  position: relative;
  right: 0rem !important;
}

.right-1 {
  position: relative;
  right: 1.75rem !important;
}

.right-2 {
  position: relative;
  right: 3.5rem !important;
}

.right-3 {
  position: relative;
  right: 5.25rem !important;
}

.right-4 {
  position: relative;
  right: 7rem !important;
}

.bottom-0 {
  position: relative;
  bottom: 0rem !important;
}

.bottom-1 {
  position: relative;
  bottom: 1.75rem !important;
}

.bottom-2 {
  position: relative;
  bottom: 3.5rem !important;
}

.bottom-3 {
  position: relative;
  bottom: 5.25rem !important;
}

.bottom-4 {
  position: relative;
  bottom: 7rem !important;
}

.left-0 {
  position: relative;
  left: 0rem !important;
}

.left-1 {
  position: relative;
  left: 1.75rem !important;
}

.left-2 {
  position: relative;
  left: 3.5rem !important;
}

.left-3 {
  position: relative;
  left: 5.25rem !important;
}

.left-4 {
  position: relative;
  left: 7rem !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

/*
  * Hide from both screenreaders and browsers: h5bp.com/u
  */
.hidden {
  display: none !important;
  visibility: hidden;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

/*
  * Hide only visually, but have it available for screenreaders: h5bp.com/v
  */
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/*
  * Extends the .visuallyhidden class to allow the element to be focusable
  * when navigated to via the keyboard: h5bp.com/p
  */
.visually-hidden.focusable:active,
.visually-hidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

/*
  * Hide visually and from screenreaders, but maintain layout
  */
.invisible {
  visibility: hidden;
}

.hidden-xs-up {
  display: none !important;
}

@media (max-width: 35.94444em) {
  .hidden-xs-down {
    display: none !important;
  }
}

@media (min-width: 36em) {
  .hidden-sm-up {
    display: none !important;
  }
}

@media (max-width: 47.94444em) {
  .hidden-sm-down {
    display: none !important;
  }
}

@media (min-width: 48em) {
  .hidden-md-up {
    display: none !important;
  }
}

@media (max-width: 61.94444em) {
  .hidden-md-down {
    display: none !important;
  }
}

@media (min-width: 62em) {
  .hidden-lg-up {
    display: none !important;
  }
}

@media (max-width: 74.94444em) {
  .hidden-lg-down {
    display: none !important;
  }
}

@media (min-width: 75em) {
  .hidden-xl-up {
    display: none !important;
  }
}

.hidden-xl-down {
  display: none !important;
}

@media screen {
  .hidden-for-screen {
    display: none;
  }
}

@media print {
  .hidden-for-print {
    display: none;
  }
}

.button-group > uimc-button {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  margin: 0 10px 20px 0;
}

/* .ag-row {
  font-size: 0.7rem !important;
} */

/* .ag-theme-alpine{
 font-size: 0.65rem !important;
} */

/* css for group header
.ag-theme-balham .ag-header-cell, .ag-theme-balham .ag-header-group-cell{
   background-color: #e0e0e0; 
}
 */


/* common css styles in all pages */
.buttonStyle{
  float: right;
  margin-right: 5px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.button{
  margin-left: 10px;
  color: #ffffff;
  background-color: #cf4b00;
  border-color: #cf4b00;
  border-style: solid;
  border-radius: 100px;
  border-width: 1px;
  display: inline-block;
  font-family: Calibri, sans-serif !important;
  font-size:0.7rem;
  font-weight: 600;
  line-height: 1;
  min-width: 6.22222rem;
  padding: 0.55556rem 1.11111rem 0.55556rem;
  position: relative;
  text-decoration: none;
  text-align: center;
  -webkit-transition: background-color 0.16s linear;
  transition: background-color 0.16s linear;
  -webkit-appearance: none;
}
.ok-button{
  color: #ffffff;
  background-color: #cf4b00;
  border-color: #cf4b00;
  border-style: solid;
  border-radius: 100px;
  border-width: 1px;
  display: inline-block;
  font-family: Calibri,sans-serif;
  font-size:0.7rem;
  font-weight: 600;
  line-height: 1;
  margin: 0;
  min-width: 6.22222rem;
  padding: 0.55556rem 1.11111rem 0.55556rem;
  position: relative;
  text-decoration: none;
  text-align: center;
  -webkit-transition: background-color 0.16s linear;
  transition: background-color 0.16s linear;
  -webkit-appearance: none;
}
.cancel-button{
  color: #cf4b00;
  background-color: ffffff;
  border-color: #cf4b00;
  border-style: solid;
  border-radius: 100px;
  border-width: 1px;
  display: inline-block;
  font-family: Calibri,sans-serif;
  font-size:0.7rem;
  font-weight: 600;
  line-height: 1;
  margin: 0;
  min-width: 6.22222rem;
  padding: 0.55556rem 1.11111rem 0.55556rem;
  position: relative;
  text-decoration: none;
  text-align: center;
  -webkit-transition: background-color 0.16s linear;
  transition: background-color 0.16s linear;
  -webkit-appearance: none;
}

.error{
  font-size: 14px;
  font-family: Calibri,sans-serif;
}
.label-header{
  background-color: #CF4B00 !important;
  padding-left: 5px;
  margin-top:5px;
  /* padding-top: 5px;
  padding-bottom: 5px; */
}
.label-headercolor{
  color: #ffffff;
  padding-top: 5px;
  padding-bottom: 5px;
}
.label-preheader{
  background-color: #f8f8f8;
  padding-left: 5px;
  margin-top:5px;
}
.label-preheadercolor{
  color:black
}
.label-prewrap{
  color: #1a1a1a;
  font-family: "Siemens Sans", "SH-Bree-Text", "Open Sans", "Roboto", "Droid Sans", "Segoe UI", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 600;
  font-size: 0.65rem;
  border-radius: 3px;
  display: inline-block;
  line-height: 1;
  margin: 0;
  padding: 0.22222rem 0.33333rem;
  vertical-align: text-bottom;
  white-space: pre-wrap;
}
.icon-add{
  color:white; 
  /* top:3px;  */
  position: relative;
  padding-bottom: 4px;
}
.colorTotal {
  font-weight: bold;
   background-color:#cccccc !important;
}
.colorSubTotal{
  font-weight: bold;
  background-color:#ededed !important;
}
.box{
  font-weight: bold;
  font-size: 14px;
  margin-left:  10px;
  width: 98%;
  border :0px solid;
  background-color: #ededed;
}
.textfield {
    background-color: #ededed;
    display: inline-block;
    position: relative;
    height: 1.8rem;
    width: 37.22222rem;
    margin-bottom: 1.66667rem;
    border-bottom: 1px solid #666666;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.row-height{
    margin-top: 5px;
    height: 30px !important;
}
label{
  
  color: #1a1a1a;
  font-family: Calibri, sans-serif !important;
  font-weight: 600;
  /* font-size: 0.8rem; */
  font-size: 0.7rem  !important;
  border-radius: 3px;
  display: inline-block;
  line-height: 1;
  margin: 0;
  
  vertical-align: text-bottom;
  white-space: nowrap;
}

/* label{
  display: inline-block;
  font-size: 0.7rem  !important;
  } */


/* ag-grid css styles */
.ag-header-cell-text {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: normal !important;
}


.ag-header-cell {
    font-size:0.60rem;
    padding-left: 2px !important;
    padding-right: 0px !important;
}
.ag-cell-wrapper{
    font-size:0.60rem;
}
.ag-row{
    font-size:0.60rem !important;
}
.ag-cell{
    font-size:0.60rem !important;
    padding-left: 2px !important;
    padding-right: 0px !important;
}

.ag-theme-alpine .ag-large-text-input {
    height: auto;
    padding: 0px !important;
}
.ag-theme-alpine .ag-cell, .ag-theme-alpine .ag-cell-wrapper.ag-row-group {
  line-height: 20px;
}
.ag-header-cell-label .ag-header-cell-text {
    white-space: normal !important;
  }
  .ag-header-group-cell-label{
    justify-content: center;
  }
  .ag-theme-balham .ag-row-selected{
    background-color: #4abbff;
  }
  
  .ag-theme-balham .ag-ltr .ag-cell-focus {
    border: 1px solid red;
  }

  /* style to be removed from here */
  
  /* this class doesn't found in any files */

  /* .ag-theme-alpine .ng-star-inserted{
      z-index: 1000;
      overflow: visible;
  }
  .ag-rich-select{
    z-index: 1000;
    overflow: visible;
  }
  .ag-popup-editor .ag-ltr .ag-popup-child{
    overflow: visible;
    z-index: 1000;
  } */

  /* this will give problem to scrolling in all the grid */
  /* .ag-root,
  .ag-body-viewport,
  .ag-body-viewport-wrapper {
      overflow: visible !important;
  } */


  /* .ag-virtual-list-container .ag-rich-select-virtual-list-container{
    z-index: 5000;
    overflow: visible;
  } */

  /* Found in NonPlcSummary */
  /*For line height of grid cell text*/
/* .line-height .ag-theme-alpine .ag-cell, .ag-theme-alpine .ag-cell-wrapper.ag-row-group {
  line-height: 0.8rem !important;
} */

/* Found in NonPlcSummary */
/*For auto resize of the total grid height based on rows*/
/* .grid-height .ag-theme-alpine .ag-layout-auto-height .ag-center-cols-clipper{
min-height: 100% !important;
} */

/* Found in NonPlcSummary */
/*To fit the editor inside the cell*/
/* .grid-editor-height .ag-theme-alpine .ag-cell-inline-editing {
height: 100% !important;
} */


.ag-theme-alpine{
  font-family: Calibri, sans-serif !important;
}

/* editing cell height */
.ag-theme-alpine .ag-cell-inline-editing{
  height:100% !important
}

/* found in NonPlc/Complexity/optionA/B/C/Target/Refernce , Plc/complexity/target/reference/OptionA/B/C */
/* .ag-theme-alpine .ag-layout-auto-height .ag-center-cols-clipper, .ag-theme-alpine .ag-layout-auto-height .ag-center-cols-container, .ag-theme-alpine .ag-layout-print .ag-center-cols-clipper, .ag-theme-alpine .ag-layout-print .ag-center-cols-container {
  min-height: 120px !important;
} */

/* For hovering over disabled element*/
input:disabled,
input[disabled]{
cursor:not-allowed !important;
}

select:disabled,
select[disabled]
{
cursor:not-allowed !important;
}
textarea:disabled,
textarea[disabled]
{
cursor:not-allowed !important;
}
.mat-drawer.mat-drawer-side {
  z-index: 1!important;
}

/* PLC summary analysis border*/
.plcAnalysisBorder1{
  padding-left: 10px !important;
}
.plcAnalysisBorder2{
  padding-left: 15px !important;
}
.plcAnalysisBorder3{
  padding-left: 20px !important;
}
.plcAnalysisBorder4{
  padding-left: 25px !important;
}
.plcAnalysisBorder5{
  padding-left: 30px !important;
}

.ag-theme-alpine .ag-rich-select-list {
  height:100px !important;
  
}

th.mat-header-cell:first-of-type, td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type {
    padding-left: 2px;
}

/* To not allow the click and cursor event if the page is not editable*/
.click-not-allowed{
  cursor: not-allowed;
}

.no-underline.mat-form-field-appearance-legacy .mat-form-field-underline {
  display: none;
}

.cdk-overlay-pane  { 
  position:absolute;
  z-index:1000;
  display:flex;  
  transform:none !important; 
  margin-top:5px;
} 

/* .mat-select-panel{
  min-width: 100% !important;
} */

.positive-num-color{
  /* color:green; */
  color:#007C00;
}

.negative-num-color{
  /* color:red; */
  color:#d70000;
}

